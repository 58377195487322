import React, { useState } from 'react';
import clipboardCopy from 'clipboard-copy';
import Chip from '@mui/material/Chip';
import LinkIcon from '@mui/icons-material/Link';
import Tooltip from '@mui/material/Tooltip';
import Alert from "@mui/material/Alert";
import { useEffect } from 'react';

const CopyButton = ({webLink}) => {
    
  const [alert,setAlert] = useState(false)
    const webUrl = webLink


  const handleClick = () => {
    const textToCopy = webUrl; // Replace with your text to copy
  
    clipboardCopy(textToCopy)
      .then(() => {
        setAlert(true)
        setTimeout(() => {
          setAlert(false)
        }, 4000);
      })
      .catch((error) => {
        console.error('Failed to copy text:', error);
      });
  };

  return (
    <>
    {
        alert && (
        <Alert
          sx={{ width: 349, position: "fixed", top: -60, right: 14 }}
          onClose={() => {setAlert(false)}}
        >
          This is a success alert — check it out!
        </Alert>
        )
      }
      <Tooltip title="Click to Copy">
    <Chip  icon={<LinkIcon style={{marginLeft:'17px'}} />}  onClick={handleClick}/>
    </Tooltip>
    </>
    
  );
};

export default CopyButton;
