import React, { Component } from "react";
import MUIDataTable, {
  TableHead,
  TableHeadCell,
  TableHeadRow,
} from "mui-datatables";
import { Chip, TableCell } from "@material-ui/core";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "../../Styles/style.css";
import "./Ticket.css";
import { makeStyles } from "@material-ui/core/styles";
import AdminApiModule from "../../components/AdminApi/Api/api";
import { Box, Button, Typography } from "@mui/material";
import TicketEditPincodePopup from "../../components/AdminApi/ticketEditPincodePopup";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import BasicDateRangePicker from "../../components/common/datepicker";
import { withRouter } from "react-router";
import moment from "moment";
import { connect } from "react-redux";
import CenterApi from "../../components/centerComponent/api/api";
import StarIcon from "@mui/icons-material/Star";
import CloseTicketRemarksModal from "../../components/Components/CloseTicketRemark";
import AssignTechnicianPopup from "../../components/AdminApi/Api/assignTechnicianPopup";
import RemarksPopup from "../../components/AdminApi/Api/RemarksPopup";
import DeleteAlertDialogModal from "../../components/Components/DeleteAlert";
import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import FileUploadErrors from "../../components/FileUploadErrors";
import axios from "axios";
import { BASE_URL } from "../../components/config/config";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import filterBg from "../../utility/filterBg.jpg";
import RebookModal from "../../components/Components/RebookMoadl";


const CustomFilter = withStyles({
  filter: {
    background: "red",
    // Add other CSS styles if needed
  },
})(InputBase);

const Input = styled("input")({
  display: "none",
});

const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "10px",
  },
};

const options = {
  // Other options...
  filterType: "dropdown",
  filter: true,
  download: true,
  downloadOptions: {
    filterOptions: {
      useDisplayedRowsOnly: true, // Download only the currently displayed (filtered) rows
      useDisplayedColumnsOnly: true, // Download only the currently displayed (filtered) columns
    },
  },
  customFilterMenuRender: (filterValue, onFilterUpdate, column) => {
    return (
      <CustomFilter
        value={filterValue || ""}
        onChange={(event) => onFilterUpdate(event.target.value)}
        placeholder={`Filter ${column.label}`}
      />
    );
  },
  downloadProps: { filterOptions: { useDisplayedRowsOnly: true, useDisplayedColumnsOnly: true } },
};


class Tickets extends Component {
  constructor(props) {
    super(props);
        this.state = {
      ticketsList: [],
      ticketObj:[],
     centerlist:[],
      columns: [
        {
          name: "Created_at",
          label: "Created on",
          options: {
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Created On
              </TableCell>
            ),
            filter: false,
            sort: true,
          },
        },
        {
          name: "daysDifference",
          label:<b>Aging</b> ,
          options: {
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Aging
              </TableCell>
            ),
            filter: false,
            sort: true,
          }
        },
        {
          name: "Image",
          label: "On-Site Image",
          options: {
            filter: false,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                On-Site Image
              </TableCell>
            ),
            customBodyRender: (value,id) => {

              return (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto auto auto",
                    gridGap: "-10px !important",
                  }}
                >
                  {value && value.length > 0 && (
                    <Button
                      className="text-transform-none"
                      variant="contained"
                      onClick={() => this.handleViewwTicket(value,value?._id)}
                    >
                      Views
                    </Button>
                  )}
                </div>
              );
            },
          },
        },
        {
          name: "ticket_id",
          label: "Ticket Id",
          options: {
            filter: false,
            sort: true,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Ticket Id
              </TableCell>
            ),
          },
        },
        {
          name: "tstatus",
          label: "Ticket status",
          options: {
            filter: true,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Ticket status
              </TableCell>
            ),
            customBodyRender: (value) => {
              //console.log("Technician Assigned", value);
              return value == "Technician Assigned" ? (
                <Chip label={value} style={{ background: "#8FD1AD" }} />
              ) : (
                <Chip
                  label={value}
                  style={{
                    background:
                      value == "PENDING" ? "#FF9933" : value ==  "Technician Not Assigned"
                        ? "#E76161" : value == "VERIFIED" ? "#00CC00" : value == "CLOSED" ? "#FF9999"
                        : "red"
                  }}
                />
              );
            },
          },
        },

        {
          name: "bstatus",
          label: "Broadcast status",
          options: {
            filter: true,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Broadcast status
              </TableCell>
            ),
          },
        },
        {
          name: "paidbypublic",
          label: "Paid By Public",
          options: {
            filter: true,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Paid By Public
              </TableCell>
            ),
          },
        },
        {
          name: "paidbytechnician",
          label: "Paid By technician",
          options: {
            filter: true,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Paid By Technician
              </TableCell>
            ),
          },
        },

        {
          name: "center_id",
          label: "Assigned Center",
          options: {
            filter: false,
            sort: true,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Assigned Center
              </TableCell>
            ),
          },
        },
        // {
        //   name: "client_id",
        //   label: "Client Id",
        //   options: {
        //     filter: false,
        //     sort: true,
        //     customHeadRender: () => (
        //       <TableCell
        //         style={{ fontSize: "17px", fontWeight: "600" }}
        //         align="center"
        //       >
        //         Client Id
        //       </TableCell>
        //     ),
        //   },
        // },
        // {
        //   name: "client_name",
        //   label: "Client Name",
        //   options: {
        //     filter: true,
        //     sort: true,
        //     customHeadRender: () => (
        //       <TableCell
        //         style={{ fontSize: "17px", fontWeight: "600" }}
        //         align="center"
        //       >
        //         Client Name
        //       </TableCell>
        //     ),
        //   },
        // },
        // {
        //   name: "technician_id",
        //   label: "Assigned technician",
        //   options: {
        //     filter: false,
        //     sort: true,
        //     customHeadRender: () => (
        //       <TableCell
        //         style={{ fontSize: "17px", fontWeight: "600" }}
        //         align="center"
        //       >
        //         Assigned technician
        //       </TableCell>
        //     ),
        //   },
        // },
        {
          name: "Qr_Id",
          label: "Qr_Id ",
          options: {
            filter: false,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Qr_Id
              </TableCell>
            ),
          },
        },
        // {
        //   name: "offer_code",
        //   label: "offer_code ",
        //   options: {
        //     filter: false,
        //     sort: false,
        //     customHeadRender: () => (
        //       <TableCell
        //         style={{ fontSize: "17px", fontWeight: "600" }}
        //         align="center"
        //       >
        //         Offer Code
        //       </TableCell>
        //     ),
        //   },
        // },
        // {
        //   name: "service_required",
        //   label: "Services Required",
        //   options: {
        //     filter: true,
        //     sort: false,
        //     customHeadRender: () => (
        //       <TableCell
        //         style={{ fontSize: "17px", fontWeight: "600" }}
        //         align="center"
        //       >
        //         Service Required
        //       </TableCell>
        //     ),
        //   },
        // },
        {
          name: "specific_requirements",
          label: "Specific  Requirements",
          options: {
            filter: true,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Specific Requirements
              </TableCell>
            ),
          },
        },
        {
          name: "name",
          label: "Name",
          options: {
            filter: false,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Name
              </TableCell>
            ),
          },
        },
        {
          name: "phone",
          label: "Phone",
          options: {
            filter: false, // Hide the filter icon
            sort: false,   // Disable sorting
             display: 'none', // Hide the column
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600", width: '0', padding: '0', display: 'none' }}
                align="center"
              >
                Phone
              </TableCell>
            ),
          },
        },
        // {
        //   name: "address",
        //   label: "Address",
        //   options: {
        //     filter: false,
        //     sort: false,
        //     customHeadRender: () => (
        //       <TableCell
        //         style={{ fontSize: "17px", fontWeight: "600" }}
        //         align="center"
        //       >
        //         Address
        //       </TableCell>
        //     ),
        //     customBodyRender: (value) => {
        //       return <Typography sx={{ width: "150px" }}>{value}</Typography>;
        //     },
        //   },
        // },
        {
          name: "pincode",
          label: "Pincode",
          options: {
            filter: true,
            sort: false,
            filterType: "multiselect",
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Pincode
              </TableCell>
            ),
          },
        },
        // {
        //   name: "createdAt",
        //   label: "Created At",
        //   options: {
        //     filter: false,
        //     sort: false,
        //     customHeadRender: () => (
        //       <TableCell
        //         style={{ fontSize: "17px", fontWeight: "600" }}
        //         align="center"
        //       >
        //         Created At
        //       </TableCell>
        //     ),
        //   },
        // },

        {
          name: "closingPrice",
          label: "Closing Price",
          options: {
            filter: false,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Closing Price
              </TableCell>
            ),
          },
        },
        
        {
          name: "tcreatedby",
          label: "Ticket Created By",
          options: {
            filter: true,
            sort: true,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Ticket Created By
              </TableCell>
            ),
          },
        },
        {
          name: "Center_QR",
          label: "Center QR Id ",
          options: {
            filter: false,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Center_QR
              </TableCell>
            ),
          },
        },
        {
          name: "timeprefrence",
          label: "Ticket Created By",
          options: {
            filter: false,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
               timeprefrence
              </TableCell>
            ),
          },
        },
        
        // {
        //   name: "Rating",
        //   label: "Rating",
        //   options: {
        //     customHeadRender: () => (
        //       <TableCell
        //         style={{ fontSize: "17px", fontWeight: "600" }}
        //         align="center"
        //       >
        //         Rating
        //       </TableCell>
        //     ),
        //     customBodyRender: (value, tableMeta, updateValue) => {
        //       return (
        //         <div
        //           style={{
        //             display: "flex",
        //             justifyContent: "center",
        //             alignItems: "center",
        //           }}
        //         >
        //           {value} <StarIcon style={{ color: "yellow" }} />
        //         </div>
        //       );
        //     },
        //     filter: true,
        //     sort: false,
        //   },
        // },
        {
          name: "action",
          label: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
             // console.log("value", value?.service_available);

              return (
               
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "900px",
                    }}
                  >
                    {/* <Button
                      sx={{
                        fontSize: "12px !important",
                        marginRight: "15px !important",
                      }}
                      onClick={() => this.handleOpenEditPincodePopup(value)}
                      disabled={!value?.service_available}
                      variant="contained"
                    >
                      Edit Pincode
                    </Button> */}
                    <Button
                      sx={{
                        fontSize: "12px !important",
                        marginRight: "15px !important",
                      }}
                      variant="contained"
                      onClick={() => this.handleViewTicket(value?._id)}
                      // disabled={!value?.service_available}
                    >
                      View Ticket
                    </Button>
                    <Button
                      sx={{
                        fontSize: "12px !important",
                        marginRight: "15px !important",
                      }}
                      variant="contained"
                      onClick={() => this.handleEditTicket(value?._id)}
                      // disabled={!value?.service_available}
                    >
                      Edit Ticket
                    </Button>

                    {value?.is_technician_assigned &&
                    value?.ticket_status !== "CLOSED" ? (
                      <>
                        {/* <Button
                          sx={{
                            fontSize: "10px !important",
                            marginRight: "15px !important",
                          }}
                          variant="contained"
                          onClick={() => {
                            this.handleOpenTechnicianPopup(value, true);
                          }}
                          disabled={!value?.service_available}
                        >
                          Assign new Technician
                        </Button> */}
                        {/* {console.log(
                          "center ID",
                          value?.assigned_ids?.assigned_center_id?._id
                        )} */}
                        <Button
                          sx={{
                            fontSize: "10px !important",
                            marginRight: "15px !important",
                          }}
                          //  assigned_ids?._id
                          onClick={() =>
                            this.clickCloseTicket(
                              value?._id,
                              value?.assigned_ids?.assigned_center_id?._id,
                              value?.ticket_id
                            )
                          }
                          variant="contained"
                          color="primary"
                          disabled={
                            !value?.service_available ||
                            !value?.assigned_ids?.assigned_center_id?._id 
                          }
                        >
                          Close Ticket
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                    {!value?.is_technician_assigned ? (
                      <>
                        {/* <Button
                          sx={{
                            fontSize: "10px !important",
                            marginRight: "15px !important",
                          }}
                          variant="contained"
                          onClick={() => {
                           
                            this.sameINA(value, (centerlist) => {
                              this.handleOpenTechnicianPopup(value, false, centerlist);
                            });
                          }}
                        >
                          Assign Technician
                        </Button> */}
                        <Button
                        sx={{
                          fontSize: "10px !important",
                          marginRight: "15px !important",
                        }}
                        variant="contained"
                        onClick={() => {
                          this.handleBroadcastButtonClick(value?._id);
                        }}
                      >
                        Broadcast
                      </Button>
                      </>
                    ) : (
                      ""
                    )}
                     <Tooltip title="Delete">
                     <Button
                      style={{ background: "#FCE9F1" }}
                      onClick={() => this.clickDeleteTicket(value?._id)}
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </Button>
                     </Tooltip>
                   

                    {value?.ticket_status == "CLOSED" && (
                      <>
                        <Tooltip title="Rebook">
                          <Button
                            style={{
                              background: "#FCE9F1",
                              marginLeft: "10px",
                            }}
                            onClick={() => this.handleRebookOpen(value?._id)}
                          >
                            <UpdateIcon style={{ color: "green" }} />
                            {/* Rebook */}
                          </Button>
                        </Tooltip>
                      </>
                    )}
 
<Button
  sx={{
    fontSize: "12px !important",
    marginLeft: "10px !important",
    marginRight: "15px !important",
    backgroundColor: value?.remarks?.onboarding_team_remarks ? "green !important" : "FCE9F1 !important",
  }}
  onClick={() => this.handleRemarks(value?._id, this.props.reduxState.adminDetails.user_name, value?.remarks?.onboarding_team_remarks, value?.remarks?.onboarding_team_name)}
  variant="contained"
>
  Remarks
</Button>
 
 {value?.revisit_status == true && (
<Button style={{
  backgroundColor:"purple"
}}
onClick={()=>this.reopen(value?._id)}
>
  Reopen-Log
</Button>
 )}

<RemarksPopup
    open={this.state.isRemarksPopupOpen}
    handleClose={this.handleCloseRemarksPopup}
    onSaveRemarks={this.handleSaveRemarks} // Updated prop name
    selectedTicketId={this.state.selectedTicketId}
    username={this.state.username}
    initialRemarks={this.state.initialRemarks} // Pass initial remarks
    processedname={this.state.processedname}
/>
                  </div>
                </>
              );
            },
          },
        },
      ],
      isEditOpenAddressPincode: false,
      isEditOpenTicketDetails: {},
      isPincodeUpdating: false,
      getSingleTicketData : [],
      isLoading: false,
      selectedTicketIds: [], 
      isAllRowsSelected: false,
      doesFileUploadErrorExists: false,
      errorsList: {},
 

      selectedRowsClosingPrice: {}, // Object to store the closing prices of selected rows
      totalClosingPrice: 0,


      selectedTicketIds: [], // Array to store selected ticket IDs
      isApiButtonVisible: false, 
      isAssignTechnicianPopupOpen: false,
      isRemarksPopupOpen: false,
      isDeleteAlertPopupOpen: false, // delete alert
      deleteHeading: "Ticket",
      ticketDetailsForAssignTechnician: {},
      isTechnicianAssigning: false,
      technicianList: [],
      isCloseTicketRemarksModalOpen: false,
      closingTicketId: "",
      deleteTicketId: "",
      rebookTicketId: "",
      centerAssignId: "",
      isForChange: false,
      openRebook: false,
      // Pagination
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
      
    };
  }

reopen=(id)=>{
  this.props.history.push(`/reopen-log/${id}`);
}


  handleRemarks = (ticketId, username,initialRemarks, processedname) => {

    console.log("=========,", ticketId)
    this.setState({
      isRemarksPopupOpen: true,
      selectedTicketId: ticketId,
      username: username,
      initialRemarks: initialRemarks,
      processedname:processedname,
    });
  };

  handleCloseRemarksPopup = () => {
    this.setState({
      isRemarksPopupOpen: false,
      selectedTicketId: null,
    });
  };

  handleSaveRemarks = (ticketId, remarks, username) => {
    // Handle saving remarks here using ticketId, remarks, and username data
    console.log('Ticket ID:', ticketId);
    console.log('Remarks:', remarks);
    console.log('Username:', username);
    // Close the popup
    this.handleCloseRemarksPopup();
};


  handleBroadcastButtonClick=(id)=>{
    const apiUrl = `${BASE_URL}technicianApp/ticket-broadcast`;
console.log("===",apiUrl)
    // Prepare the data to be sent in the request body
    const requestData = {
      ticket_obj_id: id
    };
  
    // Make the HTTP POST request
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        // You may need to include additional headers such as authorization token
      },
      body: JSON.stringify(requestData)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Handle successful response (optional)
      console.log('Ticket broadcasted successfully');
    })
    .catch(error => {
      // Handle error
      console.error('There was a problem with the fetch operation:', error);
    });
  };

  handleEditTicket = (id) => {
    this.props.history.push("/admin-ticket?edit=" + id);
  // console.log("id ========"+id);
  };

  handleViewTicket = (id) => {
    this.props.history.push(`/view-ticket?ticketId=${id}`);
    
  };
  
  handleViewwTicket = (images,id) => {
    this.props.history.push({
      pathname: "/ticketDocument",
      query:{ticketId:id?.rowData && id?.rowData[20] },
      state: { imageUrls: images ,
        ticketId: id},
    });
  };
  
  fetchMultipleImages = (data) => {
    const allImages = data.reduce((images, item) => {
      return images.concat(item.onsite_pictures || []);
    }, []);
  
    this.props.history.push({
      pathname: "/ticketDocument",
      state: { imageUrls: allImages },
    });
  };
  clickCloseTicket = (ticket_obj_id, centerAssignId, ticketId) => {
   
    // Get One ticket data
    const ticket_Id = localStorage.getItem('tickeClose_Id');
    const ticketID = localStorage.getItem('ticketId');
    const payload={
      ticket_obj_id:ticket_obj_id
    }
  //  console.log(payload, '<------This is the data😊😊😊😊😊😊😊😊😊😊😊😊')
    
    if(ticket_Id){
      new AdminApiModule().getSingleTicket(payload).then((res)=>{
        console.log(res?.data, '👌data')
        this.setState({getSingleTicketData : res?.data})
      })
    }else{
  //    console.log("error", '<>')
    }
 //   console.log("data", centerAssignId);
    this.setState({
      closingTicketId: ticket_obj_id,
      centerAssignId: centerAssignId,
    });
    this.handleOpenCloseTicketRemarksModal(ticket_obj_id, centerAssignId);
    localStorage.setItem("tickeClosetId", ticketId);
    localStorage.setItem("tickeClose_Id", ticket_obj_id);
  };

  clickDeleteTicket = (ticketId) => {
    this.setState({ deleteTicketId: ticketId });
    this.handleOpenCloseTicketDeleteModal(ticketId);
    localStorage.setItem("ticketId", ticketId);
  };
  sameINA = (ticketDetails, callback) => {
    const payload = {
      ticket_obj_id: ticketDetails._id
    };
  
    const apiUrl = `${BASE_URL}admin/get-center-for-ticket`;
  
    // Make the HTTP POST request
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        // Add any additional headers if required
      },
      body: JSON.stringify(payload)
    })
    .then(response => {
      // Check if the response is OK
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Parse the JSON response
      return response.json();
    })
    .then(res => {
      // Log the response data
      console.log('Response data:', res.data);
      // Call the callback function and pass centerlist
      callback(res.data);
    })
    .catch(error => {
      // Handle any errors
      console.error('There was a problem with the fetch operation:', error);
    });
  };
  

  
  handleOpenTechnicianPopup = (ticketDetails, isForChangeValue,centerlist) => {
    this.setState({
      isAssignTechnicianPopupOpen: true,
      ticketDetailsForAssignTechnician: ticketDetails,
      isForChange: isForChangeValue,
     centerlist:centerlist,
    });
  };

  handleCloseAssignTechnicianPopup = () => {
    console.log("modal close");
    this.setState({
      isAssignTechnicianPopupOpen: false,
      ticketDetailsForAssignTechnician: {},
    });
  };

  handleOpenCloseTicketRemarksModal = () => {
    this.setState({
      isCloseTicketRemarksModalOpen: true,
    });
  };
  handleOpenCloseTicketDeleteModal = () => {
    this.setState({
      isDeleteAlertPopupOpen: true,
    });
  };

  onClickFilter = (value) => {
    this.startLoading();

    let payload = {
      start_date: value[0],
      end_date: value[1],
    };
    new AdminApiModule()
      .getAllTickets(payload)
      .then((res) => {
        let parsedData = [];
        if (res?.data?.length) {
          parsedData = this.getParsedData(res.data);
        }
        this.setState({
          ticketsList: parsedData,
        });

        this.stopLoading();
      })
      .catch((err) => {
        this.stopLoading();

        console.log(err);
      });
  };
  refreshData = () => {
    this.startLoading();
  
    new AdminApiModule()
      .getAllTickets()
      .then((res) => {
        let parsedData = [];
        if (res?.data?.length) {
          parsedData = this.getParsedData(res.data);
        }
        this.setState({
          ticketsList: parsedData,
        });
  
        this.stopLoading();
      })
      .catch((err) => {
        this.stopLoading();
  
        console.log(err);
      });
  };

 
  startLoading = () => {
    this.setState({ isLoading: true });
  };

  stopLoading = () => {
    this.setState({ isLoading: false });
  };

  handleBroadCastTicket = (value) => {
    let payload = {
      ticket_obj_id: value?._id,
    };
    new AdminApiModule()
      .adminBroadCast(payload)
      .then((res) => {
        this.getAllTickets();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleCloseTicket = (
    closingTicketRemarks,
    closingPrice,
    closingCode,
    reasonToClose
  ) => {
    if (!(closingPrice == 0 || closingPrice)) {
      return;
    }

    if (this.state.closingTicketId && this.state.closingTicketId.length > 0) {
      let payload = {
        center_id: this.state.centerAssignId,
        ticket_obj_id: this.state.closingTicketId,
        remarks: {
          close_ticket_remarks: reasonToClose,
          technician_remarks: closingTicketRemarks,
        },
        amount: parseInt(closingPrice),
        code: closingCode,
      };

     // console.log("payload is", payload);

      new AdminApiModule()
        .closeTicket(payload)
        .then((res) => {
          this.setState({ closingTicketId: "" });
          this.handleCloseCloseTicketRemarksModal();
          this.showDialog(true, "success", "Ticket closed successfully");

          this.getAllTickets();
        })
        .catch((err) => {
          console.log(err);
          this.showDialog(true, "error", err?.response?.data?.message);
        });
    }
  };

  // Handle Revisit Ticket Close
  handleRevisitCloseTicket = (
    closingTicketRemarks,
    closingPrice,
    closingCode,
    reasonToClose,
    revisitRemark
  ) => {
    if (!(closingPrice == 0 || closingPrice)) {
      return;
    }

    if (this.state.closingTicketId && this.state.closingTicketId.length > 0) {
      let payload = {
        center_id: this.state.centerAssignId,
        ticket_obj_id: this.state.closingTicketId,
        remarks: {
          close_ticket_remarks: reasonToClose,
          technician_remarks: closingTicketRemarks,
          revisit_remarks: revisitRemark
        },
        amount: parseInt(closingPrice),
        code: closingCode,
      };

     // console.log("payload is", payload);

      new AdminApiModule()
        .closeTicket(payload)
        .then((res) => {
          this.setState({ closingTicketId: "" });
          this.handleCloseCloseTicketRemarksModal();
          this.showDialog(true, "success", "Ticket closed successfully");

          this.getAllTickets();
        })
        .catch((err) => {
          console.log(err);
          this.showDialog(true, "error", err?.response?.data?.message);
        });
    }
  };

  handleAssignTechnician = (technician_obj_id) => {
    if (!technician_obj_id) {
      return;
    }
    this.setState({ isTechnicianAssigning: true });
    let payload = {
      ticket_obj_id: this.state.ticketDetailsForAssignTechnician._id,
      technician_obj_id: technician_obj_id,
    };

    new AdminApiModule()
      .assignTechnician(payload)
      .then((res) => {
        this.handleCloseAssignTechnicianPopup();
        this.setState({ isTechnicianAssigning: false });
        this.getAllTickets();
      })
      .catch((err) => {
        this.setState({ isTechnicianAssigning: false });

        console.log(err);
      });
  };

  handleChangeTechnician = (technician_obj_id) => {
    if (!technician_obj_id) {
      return;
    }

    this.setState({ isTechnicianAssigning: true });
    let payload = {
      ticket_obj_id: this.state.ticketDetailsForAssignTechnician._id,
      technician_obj_id: technician_obj_id,
    };

    new AdminApiModule()
      .assignTechnician(payload)
      .then((res) => {
        this.handleCloseAssignTechnicianPopup();
        this.setState({ isTechnicianAssigning: false });
        this.getAllTickets();
      })
      .catch((err) => {
        this.setState({ isTechnicianAssigning: false });

        console.log(err);
      });
  };

  getAllTechnician = () => {
    new AdminApiModule()
      .getAllTechnician()
      .then((res) => {
        this.setState({
          technicianList: res?.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChangeTechnician = (technician_obj_id) => {
    if (!technician_obj_id) {
      return;
    }

    this.setState({ isTechnicianAssigning: true });
    let payload = {
      ticket_obj_id: this.state.ticketDetailsForAssignTechnician._id,
      technician_obj_id: technician_obj_id,
    };
   // console.log("payload tec", payload);
    new AdminApiModule()
      .changeTechnician(payload)
      .then((res) => {
        this.handleCloseAssignTechnicianPopup();
        this.setState({ isTechnicianAssigning: false });
        this.getAllTickets();
      })
      .catch((err) => {
        this.setState({ isTechnicianAssigning: false });

        console.log(err);
      });
  };

  // Code ended here

  handleOpenEditPincodePopup = (value) => {
    this.setState({
      isEditOpenAddressPincode: true,
      isEditOpenTicketDetails: value,
    });
  };
 
  
  
  handleApiCall = () => {
    this.setState({ selectedTicketIds: [] });
  };

  handleCloseEditPincodePopup = () => {
    this.setState({
      isEditOpenAddressPincode: false,
      isEditOpenTicketDetails: {},
    });
  };

  componentDidMount = () => {
    this.getAllTickets();
    this.getAllTechnician();
    
};

  getFormattedAddress = (addressObject) => {
    let addressLine =
      addressObject?.house_number +
      ", " +
      addressObject?.locality +
      ", " +
      addressObject?.city +
      ", " +
      addressObject?.state;
    return addressLine;
  };

  getParsedData = (data) => {
    let parsedData = data?.map((item) => {
      //console.log("item?.onsite_pictures", item?.onsite_pictures);
      let closingPrice = item?.closing_ticket_price;
      if (closingPrice === "-" || closingPrice === undefined) {
        closingPrice = 0;
      } else {
        closingPrice = parseFloat(closingPrice);
      }
  
      return {
        Created_at: 
        new Date(item?.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }),
         daysDifference: moment().diff(moment(item?.createdAt), 'days'),
        Image: item?.onsite_pictures,
        ticket_id: item?.ticket_id || "-",
        Qr_Id: item?.assigned_ids?.assigned_center_id?.qr_details?.qr_id || "-",
        offer_code: item?.offers_applied?.offer_code || "-",
        center_id: item?.assigned_ids?.assigned_center_id?.center_name,
        technician_id:
         item?.assigned_ids?.assigned_technician_id?.personal_details?.name,
        service_required: `${item?.service_provided_for?.service_name.slice
          (0, 30)}...`,
        name: item.personal_details.name,
        phone: item.personal_details.primary_phone.mobile_number,
        address: `${this.getFormattedAddress(item?.address_details).slice(0, 30)}...`,
        pincode: item.address_details.pincode,
        createdAt: new Date(item?.createdAt).toLocaleDateString("en-us", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
        paidbypublic: item?.is_paid_by_public? 'YES':'NO',
        client_id: item?.client_id || "-",
        client_name: item?.client_name || "-",
        specific_requirements: item?.specific_requirement ? item?.specific_requirement : "-",
        bstatus:
          item?.service_available == false
            ? "No Service Available In This Area "
            : item?.broadcast_status == "matched_in_same_center" 
            ? "matched_in_same_center"
            : item?.broadcast_status == "broadcasted" &&
              item?.ticket_created_by == "PUBLIC_WITH_DELAR_QR"
            ? "DEALER"
            : item?.ticket_created_by == "SUPER_ADMIN" &&
            item?.broadcast_status == "accepted after broadcast"
            ? "Admin Created-Accepted"
            : item?.broadcast_status == "accepted after broadcast"
            ? "Public Created-Accepted"
            : item?.broadcast_status == undefined
            ? "Not Paid (Payment Failed)" 
            : item?.broadcast_status == "broadcasted"
            ? `NEW ${
              (item?.is_paid_by_public == true && "99") ||
              (item?.is_paid_by_public == false &&
                item?.ticket_created_by === "PUBLIC_WITH_CENTER_QR" &&
                "NOT PAID")
            }`
          : `${item?.broadcast_status}` ||
            (item?.broadcast_status === undefined && "-"),
        tstatus: item?.ticket_status == "PENDING" && item?.broadcast_status == "matched_in_same_center" && item?.assigned_ids?.assigned_technician_id?.personal_details?.name
          ? "Technician Assigned"
          : item?.ticket_status == "PENDING" && item?.broadcast_status == "matched_in_same_center" && !item?.assigned_ids?.assigned_technician_id?.personal_details?.name
          ? "Technician Not Assigned"
          : item?.ticket_status == "ACCEPTED" && item?.assigned_ids?.assigned_technician_id?.personal_details?.name
          ? "Technician Assigned"
          : item?.ticket_status == "ACCEPTED" && !item?.assigned_ids?.assigned_technician_id?.personal_details?.name
          ? "Technician Not Assigned"
          : item?.ticket_status,

          
          tcreatedby: item?.ticket_created_by === "SUPER_ADMIN" ?  item?.create_name:  item?.ticket_created_by  ,

        Rating: item?.remarks?.over_all_rating,
        action: item,
        closingPrice: closingPrice,
        timeprefrence: item?.time_preference?.specific_date_time ? new Date(item.time_preference.specific_date_time).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })  : item?.time_preference?.time_preference_type ,
        //specific: item?.time_preference?.specific_date_time,
        paidbytechnician: item?.is_paid_by_technician? 'YES':'NO',
        Center_QR: item?.created_by?.qr_details?.qr_id,
      };
    });
 
    return parsedData;
  };
  

  getAllTickets() {
    this.startLoading();

    new AdminApiModule()
      .getAllTickets()
      .then((res) => {
        let parsedData = [];
        if (res?.data?.length) {
          parsedData = this.getParsedData(res.data);
        }
        this.setState({
          ticketsList: parsedData,
        });

        this.stopLoading();
      })
      .catch((err) => {
        this.stopLoading();

        console.log(err);
      });
  }

  handleUpdatePincode = (newPincode) => {
    if (!newPincode) {
      return;
    }
    let payload = {
      address_details: {
        pincode: newPincode,
      },
      ticket_obj_id: this.state.isEditOpenTicketDetails?._id,
    };

    this.setState({ isPincodeUpdating: true });
    new AdminApiModule()
      .updatePincode(payload)
      .then((res) => {
        this.setState({ isPincodeUpdating: false });
        this.getAllTickets();
        this.handleCloseEditPincodePopup();
      })
      .catch((err) => {
        this.setState({ isPincodeUpdating: false });
      });
  };

  onUploadCsvFile = (event, stateValue) => {
    let files = event.target.files;
    let aadharSideName = event.target.name;
    let fileOriginalName = files[0]?.name?.split(".")[0];
    console.log(
      "files",
      files,
      aadharSideName,
      fileOriginalName,
      typeof fileOriginalName
    );

    const formData = new FormData();

    formData.append("ticketsCsvFile", files[0], fileOriginalName);

    new AdminApiModule()
      .uploadTicketsCsv(formData)
      .then((res) => {
        console.log("res is", res);
        if (res && res.data && res.data.doesErrorExists) {
          this.setState({
            doesFileUploadErrorExists: true,
            errorsList: res.data.errorRowWise,
          });
        } else {
          this.getAllTickets();
        }
      
      })
      .catch((err) => {
        console.log(err);
      });
  };
  updateSelectedRowsClosingPrice = () => {
    const { ticketsList, selectedTicketIds } = this.state;
    let totalClosingPrice = 0;
    const selectedRowsClosingPrice = {};
  
    selectedTicketIds.forEach(ticketId => {
      const rowData = ticketsList.find(row => row.ticket_id === ticketId);
      if (rowData && rowData.closingPrice) {
        const closingPrice = parseFloat(rowData.closingPrice);
        if (!isNaN(closingPrice)) {
          totalClosingPrice += closingPrice;
          selectedRowsClosingPrice[ticketId] = closingPrice;
        }
      }
    });
  
    console.log("Selected Rows Closing Price:", selectedRowsClosingPrice);
    console.log("Total Closing Price:", totalClosingPrice);


    // Update the state after all calculations
    this.setState({
      selectedRowsClosingPrice,
      totalClosingPrice,
    });
  };
  
 reportpage =()=>{
  this.props.history.push({
    pathname: "/report-page",
  });
  }
  

  
  handleCloseFileUploadErrors = () => {
    this.setState({ doesFileUploadErrorExists: false, errorsList: {} });
  };

  handleCloseCloseTicketRemarksModal = () => {
    this.setState({
      isCloseTicketRemarksModalOpen: false,
    });
  };

  // Delete row code
  handleDeleteCloseModal = () => {
    this.setState({
      isDeleteAlertPopupOpen: false,
    });
  };
  handleDeleteRows = async () => {

    let accessToken = localStorage.getItem("access_token");
    let ticket_id = localStorage.getItem("ticketId");
console.log("----------",localStorage)
    try {
      await axios.delete(`${BASE_URL}admin/deleteTickets/${ticket_id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      });
      this.handleDeleteCloseModal();
      this.getAllTickets();
    } catch (error) {
      console.log(error);
    }
  };
  handlePageChange = async (event, page) => {
    this.setState({ currentPage: page });
    let showNo = this.state.pageSize;
    let currentsPage = this.state.currentPage;
    let total = 5 + this.state.currentPage;
    await this.getAllTicket(total, currentsPage);
  };
  
  handleDeleteSelectedRows = () => {
    const { selectedTicketIds } = this.state;
    console.log("=========",selectedTicketIds)
    // Loop through selected ticket IDs and delete them one by one
    selectedTicketIds.forEach(ticketId => {
      this.clickDeleteTicket(ticketId);
    });
  
    // Clear selectedTicketIds state after deletion
    this.setState({ selectedTicketIds: [] });
  };
  
  handleRebookOpen = (rebookTicketId) => {
    this.setState({ rebookTicketId });
    this.setState({ openRebook: true });
  };
  handleRebookClose = () => this.setState({ openRebook: false });
  
  render() {
        const { selectedTicketIds } = this.state; 
    return (
      <div>
        {console.log("this.state.openRebook", this.state.openRebook)}
        <RebookModal
          isOpen={this.state.openRebook}
          handleRebookOpen={this.handleRebookOpen}
          handleRebookClose={this.handleRebookClose}
          Ticket_id={this.state.rebookTicketId}
        />
  
        {console.table("👌ticket",this.state.getSingleTicketData)}
        {this.state.isCloseTicketRemarksModalOpen && (
          <CloseTicketRemarksModal
            open={this.state.isCloseTicketRemarksModalOpen}
            handleClose={this.handleCloseCloseTicketRemarksModal}
            handleCloseTicket={this.handleCloseTicket}
            handleRevisitCloseTicket={this.handleRevisitCloseTicket}
            getSingleTicketData={this.state.getSingleTicketData?.results}
          />
        )}
  
        {this.state.isEditOpenAddressPincode && (
          <TicketEditPincodePopup
            open={this.state.isEditOpenAddressPincode}
            isPincodeUpdating={this.state.isPincodeUpdating}
            handleUpdatePincode={this.handleUpdatePincode}
            handleClose={this.handleCloseEditPincodePopup}
            isEditOpenTicketDetails={this.state.isEditOpenTicketDetails}
          />
        )}
  
        {this.state.isDeleteAlertPopupOpen && (
          <DeleteAlertDialogModal
            openAlert={this.state.isDeleteAlertPopupOpen}
            handleAlertClose={this.handleDeleteCloseModal}
            handleDeleteTicket={this.handleDeleteRows}
            Heading={this.state.deleteHeading}
          />
        )}
  
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.isLoading}
          onClick={this.stopLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        
        {this.props.reduxState?.adminDetails?.role_id?.permissions?.view_ticket
          ?.full_access ? (
          <>
        
        <Grid container>
          <Grid item style={stylesCss.paddingInnerGrids} md={6} >
            <BasicDateRangePicker
              onClickFilter={this.onClickFilter}
              refreshData={this.refreshData}
            />
          </Grid>
          <Grid style={stylesCss.paddingInnerGrids} item md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div className="aadhar-upload-button">
              {/* <div>
                <label htmlFor="contained-button-file">
                  <Input
                    name="ticketsCsvFile"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => this.onUploadCsvFile(e)}
                  />
                  <Button variant="contained" component="span">
                    Upload Tickets File
                  </Button>
                </label>
                
                <div>
                  <div>Upload csv file for tickets</div>
                </div>
              </div> */}
              {/* Gives total of closing price of tickets which are selected */}
              <Button
  variant="contained"
  style={{
    backgroundColor: 'green',
    color: 'white',
    fontSize: '0.3rem',
    padding: '8px 10px', // Adjusted padding for smaller size
    borderRadius: '0',
    marginBottom: '10px', // Remove rounded corners
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height:'38px',
    width:'70px',
  }}
  onClick={this.handleProcessSelectedTickets}
>
  <h1>PAID</h1>
  <div><h1>{this.state.totalClosingPrice}</h1></div>
</Button>

<Button onClick={this.reportpage}  variant="contained"
                    component="span"
                    style={{ height: "37px", width:"120px", fontWeight:"bold"}}>
  Analysis
</Button>
            </div>
          </Grid>
        </Grid>
  
        {console.error("technician List", this.state.technicianList)}
        {this.state.isAssignTechnicianPopupOpen && (
          <AssignTechnicianPopup
            open={this.state.isAssignTechnicianPopupOpen}
            handleClose={this.handleCloseAssignTechnicianPopup}
            technicianList={this.state.technicianList}
            isForChange={this.state.isForChange}
            // change here
            handleAssignTechnician={this.handleAssignTechnician}
            handleChangeTechnician={this.handleChangeTechnician}
            isTechnicianAssigning={this.state.isTechnicianAssigning}
            centerlist={this.state.centerlist}
          />
        )}
  
        <MUIDataTable
          title={"Tickets"}
          data={this.state.ticketsList}
          columns={this.state.columns}
      
          options={{
            ...options,
            selectableRows: 'multiple',
            onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
              const selectedIds = allRowsSelected.map(row => this.state.ticketsList[row.dataIndex].ticket_id);
              this.setState({ selectedTicketIds: selectedIds }, () => {
                this.updateSelectedRowsClosingPrice();
              });
            },
           
          }}
        />
 
        {this.state.doesFileUploadErrorExists && (
          <FileUploadErrors
            doesFileUploadErrorExists={this.state.doesFileUploadErrorExists}
            errorsList={this.state.errorsList}
            handleCloseFileUploadErrors={this.handleCloseFileUploadErrors}
          />
          )}
          </>
        ) : (
          "No permission , contact admin"
)}
      </div>
    );
    
  }
  
}
const mapStateToProps = (state) => ({
  reduxState: state.userReducer,
});

export default connect(mapStateToProps, null)(withRouter(Tickets));