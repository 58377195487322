import "./Navbar.css";
import onitLogo from './onitLogo.png'
export const NavBar =()=>{
    return(
        <>
        <nav className='nav-bar'>
        {/* <img src='./onitLogo.png' className='top-logo' alt="logo"/> */}
        <img src={onitLogo} alt="logo"  className='top-logo' />
       
       
          {/* <span className="main_heading">
        
          </span>  */}
       
</nav>

        </>
    )
}