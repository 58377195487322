import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import "../../Styles/style.css";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';

import {
  Alert,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import CenterRegistrationApi from "../../components/centerRegistration/Api/api";
import { IndianStates } from "../../assets/IndianStates";
import th from "date-fns/esm/locale/th/index.js";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "10px",
  },
};

class CenterRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary_services: this.props.initialValue || [], // Initialize with an empty array if props.initialValue is not provided
      selectedServiceName: "",
      center_name: "",
      personal_details: {
        phone: {
          country_code: "+91",
          mobile_number: "",
        },
        email: "",
        name: "",
        user_name: "",
        about: "",
      },
      remark: '',
  remarkRequired: false,
  ticket_remarks: '',
      services: {
        primary_services: [
          {
            // Initialize primary_services as an empty array
            _id: "", // Assuming _id is the identifier for the service
            service_name: "", // Initialize service_name as an empty string
          },
        ],
        pincodes:'',
        secondary_services: [
          // secondary_services_id: "",
          // priority: "",
        ],
      
      },

      checkedSecondaryServices:[],

      address_details: {
        address_line: "",
        city: "",
        state: "",
        pincode: "",
        // additional_pincode: "",
        country: "INDIA",
      },
      no_of_technicians: "",
      automate_qr_code: false,
      qr_code: "",
      payment_details: {
        upi_id: "",
      },
      toast: {
        open: false,
        msg: "",
        duration: 5000,
        type: "",
      },
      isFormValid: false,
      servicesList: [],
      sendingOtp: false,
      isOtpSentSuccess: false,
      otpEntered: "",
      isVerifyingOtp: false,
      isOtpVerfied: false,
      isRegistering: false,
      allClientsList: [],
      clients_ids_list: [],
      login_into_application: false,
      accepting_broadcast_ticket: false,
      doesCenterExists: false,
      isEditDetailsExists: false,
    };
    this.handleChangePincodes = this.handleChangePincodes.bind(this);
  }

  componentDidMount() {
    this.getAllServicesExists();
    this.getAllClients();
    this.fetchServicesList();

    let path = window.location.hash.split("?");
    console.log(
      path,
      "path",
      new URLSearchParams(this.props.location.search).get("edit")
    );
    let editCenterId = new URLSearchParams(this.props.location.search).get(
      "edit"
    );

    if (editCenterId != null) {
      this.getCenterDetails(editCenterId);
    }
  }
  fetchServicesList() {
    // Fetch servicesList
    // Once servicesList is fetched, set initial value if necessary
    if (!this.state.primary_services && this.props.initialValue) {
      const selectedService = this.state.servicesList.find(
        (service) => service._id === this.props.initialValue
      );
      if (selectedService) {
        this.setState({
          primary_services: selectedService._id,
          selectedServiceName: selectedService.service_name,
        });
      }
    }
  }
  getCenterIdForEdit() {
    return new URLSearchParams(this.props.location.search).get("edit");
  }

  getCenterDetails(id) {
    let payload = {
      center_obj_id: id,
    };

    this.setState({
      isEditDetailsExists: true,
    });

    new CenterRegistrationApi()
      .getCenterDetails(payload)
      .then((res) => {
        if (res?.data?.length) {
          this.setState({
            doesCenterExists: true,
          });
          this.updateStateOfEditData(res?.data?.[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

    updateStateOfEditData(centerDetails) {
      this.setState({
        center_name: centerDetails?.center_name,
        personal_details: {
          phone: {
            country_code: "+91",
            mobile_number: centerDetails?.personal_details?.phone?.mobile_number,
          },
          email: centerDetails?.personal_details?.email,
          name: centerDetails?.personal_details?.name,
          user_name: centerDetails?.personal_details?.user_name,
          about: centerDetails?.personal_details?.about,
        },
        primary_services: centerDetails?.services?.primary_services?.[0]?._id,
        pincodes: centerDetails.services.pincodes.join(','),
        secondary_services: centerDetails?.services?.secondary_services?.map(service => service?.secondary_services_id?._id) ,

        address_details: {
          address_line: centerDetails?.address_details?.address_line,
          city: centerDetails?.address_details?.city,
          state: centerDetails?.address_details?.state,
          pincode: centerDetails?.address_details?.pincode,
          // additional_pincode: centerDetails?.address_details?.additional_pincode,
          country: "INDIA",
        },
        upi_id: centerDetails?.payment_details?.upi_id,
        qr_id: centerDetails?.qr_details?.qr_id,
        no_of_technicians: centerDetails?.no_of_technicians,
        clients_ids_list: centerDetails?.clients_ids_list?.map((item) => item._id),
        login_into_application: centerDetails?.disabled_for?.login_into_application,
        accepting_broadcast_ticket: centerDetails?.disabled_for?.accepting_broadcast_ticket,
        checkedSecondaryServices: centerDetails?.services?.secondary_services?.map(service => service?.secondary_services_id?._id) ,
      });
    }
    

  getAllClients() {
    new CenterRegistrationApi()
      .getAllClients()
      .then((res) => {
        this.setState({
          allClientsList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getAllServicesExists() {
    new CenterRegistrationApi()
      .getAllActiveServices()
      .then((res) => {
        this.setState({
          servicesList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  showDialog = (open, type, msg) => {
    this.setState({
      toast: {
        open: open,
        msg: msg,
        duration: 5000,
        type: type,
      },
    });
  };

  closeDialog = (open = false) => {
    this.setState({
      toast: {
        open: open,
        msg: "",
        duration: 5000,
        type: "",
      },
    });
  };

  handleInputChangeClients = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);

    this.setState({
      clients_ids_list: typeof value === "string" ? value.split(",") : value,
    });
  };

   handleWhatsAppClick = (name,phoneNumber) => {
    // Format the phone number to remove special characters
    const formattedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');

    // Define the message with dynamic variables
    const message = `
    नमस्ते ${name}
    It's pleasure to hv you on OniT. 
We wanted to inform you that your free trial period is over. 
I understand you hv not yet completed KYC/ shared QR to get FREE leads/ not completed 15 request of your customers to enable you get new customers from our portal. 
We have extended the FREE period further to give you more time to experience the benefits of our services.

Download the Onit Partners app through the link below. Share your QR code from profile
Create Request, Assign and Complete with pictures and price charged for service provided to customer  https://play.google.com/store/apps/details?id=com.onit.partners
   
    वॉलेट में मिनिमम 99/– होना चाहिए
`;

    // Encode the message to be included in the URL
    const encodedMessage = encodeURIComponent(message);

    // Open WhatsApp web with the formatted phone number and predefined message
    window.open(`https://web.whatsapp.com/send?phone=${formattedPhoneNumber}&text=${encodedMessage}`, '_blank');
};

 
handleChangePincodes = (e) => {
  const { value } = e.target;
  this.setState({ pincodes: value });
};

  
  getFormattedServices = (services) => {
    let secondary_services_list = [];
    services &&
      services.length > 0 &&
      services.forEach((item) => {
        // Split the service name by '-'
        const serviceName = item?.secondary_services_id?.[0]?.service_name;
        if (serviceName) {
          const parts = serviceName.split("-");
          // Take the first part before '-'
          const serviceNameFirstPart = parts[0]?.trim();
          if (serviceNameFirstPart) {
            secondary_services_list.push(serviceNameFirstPart);
          }
        }
      });
    return secondary_services_list.join(", ");
  };

  handleChangePersonalDetails = (e) => {
    let { name, value } = e.target;

    this.setState(
      {
        personal_details: {
          phone: {
            ...this.state.personal_details.phone,
            [name]: value,
          },
        },
      },
      () => {
        console.log(this.state);
      }
    );
  };

  handleResetStateValues = () => {
    this.setState({
      center_name: "",
      personal_details: {
        phone: {
          country_code: "+91",
          mobile_number: "",
        },
        email: "",
        name: "",
        user_name: "",
        about: "",
      },
      primary_services: "",
      secondary_services: {
        secondary_services_id: "",
        priority: "",
      },
      address_details: {
        address_line: "",
        city: "",
        state: "",
        pincode: "",
        // additional_pincode: "",
        country: "INDIA",
      },
      no_of_technicians: "",
      qr_code: "",
      payment_details: {
        upi_id: "",
      },
      checkedSecondaryServices:[],
      isFormValid: false,

      sendingOtp: false,
      isOtpSentSuccess: false,
      otpEntered: "",

      isVerifyingOtp: false,
      isOtpVerfied: false,

      isRegistering: false,
      clients_ids_list: [],

      remark: '', // Ensure this is reset
      ticket_remarks: '',
    });
  };

  handleChangeAddressDetails = (e) => {
    let { name, value } = e.target;

    if (name === "pincode") {
      if (value.length <= 6) {
        this.setState(
          {
            address_details: {
              ...this.state.address_details,
              // additional_pincode: value,
            },
          },
          () => {
            this.setState({
              address_details: {
                ...this.state.address_details,
                [name]: value,
              },
            });
          }
        );
      }
      return;
    }

    this.setState({
      address_details: {
        ...this.state.address_details,
        [name]: value,
      },
    });
  };


  handleChangeSecondaryServiceDetails = (event) => {
    const { value } = event.target;
    this.setState({
      checkedSecondaryServices: value,
    });
  };

  handleChangeInputOuter = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };


  // handleToggleSecondaryService = (serviceId) => () => {
  //   const { checkedSecondaryServices } = this.state;
  //   const currentIndex = checkedSecondaryServices.indexOf(serviceId);
  //   const newChecked = [...checkedSecondaryServices];
  
  //   if (currentIndex === -1) {
  //     newChecked.push(serviceId);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }
  
  //   this.setState({
  //     checkedSecondaryServices: newChecked,
  //   });
  // };

  
  handleRegister = () => {
    const { checkedSecondaryServices, remark, remarkRequired } = this.state;

    // Filter out null values
    const filteredServices = checkedSecondaryServices.filter(service => service !== null);
    const pincodesArray = this.state.pincodes.split(',')
    .map(pincode => pincode.trim())
    .filter(pincode => pincode.length > 0);

    if (remarkRequired && !remark) {
      this.showDialog(true, "error", "Remark is required when disabling login or accepting tickets.");
      return;
    }

    let payload = {
      center_name: this.state.center_name,
      personal_details: this.state.personal_details,
      primary_services: this.state.primary_services,
      pincodes:pincodesArray,
      checkedSecondaryServices: this.state.checkedSecondaryServices,    
      address_details: this.state.address_details,
      no_of_technicians: this.state.no_of_technicians,
      clients_ids_list: this.state.clients_ids_list,
     
    };

    this.setState({
      isRegistering: true,
    });

    if (this.state.isEditDetailsExists) {
      payload.center_obj_id = this.getCenterIdForEdit();
      payload = {
        ...payload,
        login_into_application: this.state.login_into_application,
        accepting_broadcast_ticket: this.state.accepting_broadcast_ticket,
        upi_id: this.state.upi_id,
        remark: this.state.remark,
        ticket_remarks: this.state.ticket_remarks,
        update_name: this.props.reduxState?.adminDetails?.user_name,
      };
      new CenterRegistrationApi()
        .updateCenterAdmin(payload)
        .then((res) => {
          this.showDialog(true, "success", "Center Updated ");

          this.setState({
            isRegistering: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isRegistering: false,
          });
          this.showDialog(true, "error", err?.response?.data?.message);
        });

      return;
    }

    payload = {
      ...payload,
      qr_code: this.state.qr_code,
      automate_qr_code: this.state.automate_qr_code,
    };

    new CenterRegistrationApi()
      .adminCreateCenter(payload)
      .then((res) => {
        this.showDialog(true, "success", "Center added ! Add more centers ");

        this.setState({
          isRegistering: false,
        });

        this.handleResetStateValues();
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isRegistering: false,
        });
        this.showDialog(true, "error", err?.response?.data?.message);
      });
  };

  handleChangeCheckBox1 = (event, name) => {
    this.setState({
      [name]: event.target.checked,
      // Set the flag indicating a change
    });
  };
  handleChangeCheckBox = (event, name) => {
    this.setState({
      [name]: event.target.checked,
      remarkRequired: true,  // Set the flag indicating a change
    });
  };
  render() {
   
    const { checkedSecondaryServices, servicesList } = this.state;
    console.log("full data", this.state);
    const { toast: snackBarValues, isFormValid } = this.state;

    if (this.state.isEditDetailsExists && !this.state.doesCenterExists) {
      return <>"Page not found !!!"</>;
    }

    if (this.state.isEditDetailsExists) {
      if (
        !this.props.reduxState?.adminDetails?.role_id?.permissions
          ?.update_center?.full_access
      ) {
        return <>No permission to update center , contact admin</>;
      }
    } else {
      if (
        !this.props.reduxState?.adminDetails?.role_id?.permissions?.add_center
      ) {
        return <>No permission to add center , contact admin</>;
      }
    }
    return (
      <div>
        {snackBarValues.open && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBarValues.open}
            autoHideDuration={snackBarValues.duration}
            onClose={this.closeDialog}
          >
            <Alert
              onClose={this.closeDialog}
              severity={snackBarValues.type}
              sx={{ width: "100%" }}
            >
              {snackBarValues.msg}
            </Alert>
          </Snackbar>
        )}
        <h2 className="text-align-center">
          {this.state.isEditDetailsExists ? "Update center" : "Add Center"}
        </h2>
        <Item>
          <Grid container>
            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <TextField
                helperText="Please provide your Shop / Center (Name)"
                id="demo-helper-text-misaligned"
                label="Shop / Center (Name)"
                name="center_name"
                onChange={this.handleChangeInputOuter}
                style={stylesCss.inputBox}
                error={isFormValid && !this.state.center_name}
                value={this.state.center_name}
              />
            </Grid>

            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <TextField
                helperText="Please provide your contact number"
                id="demo-helper-text-misaligned"
                label="Contact Number"
                name="mobile_number"
                disabled={this.state.isOtpSentSuccess}
                onChange={this.handleChangePersonalDetails}
                style={stylesCss.inputBox}
                error={
                  isFormValid &&
                  !this.state.personal_details.phone.mobile_number
                }
                value={this.state.personal_details.phone.mobile_number}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>+91</Typography>
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
         
            <Grid item md={6} style={stylesCss.paddingInnerGrids}>
              <FormControl
                md={6}
                style={stylesCss.inputBox}
                sx={{ minWidth: 120 }}
              >
                <InputLabel
                  style={{ background: "white" }}
                  id="demo-simple-select-helper-label"
                >
                  Main Service You Offer / Need
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Main Service You Offer / Need"
                  name="primary_services"
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  onChange={this.handleChangeInputOuter}
                  error={isFormValid && !this.state.primary_services}
                  value={this.state.primary_services} // Set the value to the selected service
                >
                  {this.state.servicesList?.map((service) => (
                    <MenuItem key={service._id} value={service._id}>
                      <Checkbox
                        checked={this.state.primary_services === service._id}
                      />
                      <ListItemText primary={service.service_name} />
                    </MenuItem>
                  ))}
                </Select>
                {/* Display the selected service name */}
                {this.state.primary_services && (
                  <Typography variant="body2">
                    {this.state.primary_services.service_name}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item md={6} style={stylesCss.paddingInnerGrids}>
        <FormControl md={6} style={stylesCss.inputBox} sx={{ minWidth: 120 }}>
          <InputLabel id="secondary-services-label">
            Other Services You Offer / Need
          </InputLabel>
         <Select
            labelId="secondary-services-label"
            id="secondary-services-select"
            label="Other Services You Offer / Need"
            name="secondary_services"
            onChange={this.handleChangeSecondaryServiceDetails}
            value={this.state.checkedSecondaryServices} // Ensure value is always an array
            multiple
            renderValue={(selected) => {
              const selectedServices = servicesList.filter((service) =>
                selected.includes(service._id)
              );
              return selectedServices
                .map((service) => service.service_name)
                .join(", ");
            }}
            >
            {servicesList.map((service) => (
              <MenuItem key={service._id} value={service._id}>
                <Checkbox
                  checked={checkedSecondaryServices.includes(service._id)}
                />
                <ListItemText primary={service.service_name} />
              </MenuItem>
            ))}
            </Select>

        </FormControl>
      </Grid>


            {/* change herer */}
            <Grid item md={6} style={stylesCss.paddingInnerGrids}>
              <FormControl
                md={6}
                style={stylesCss.inputBox}
                sx={{ minWidth: 120 }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Choose clients
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Choose clients"
                  name="clients_ids_list"
                  multiple
                  onChange={this.handleInputChangeClients}
                  error={isFormValid && !this.state.clients_ids_list.length}
                  value={this.state.clients_ids_list}
                >
                  {this.state.allClientsList?.map((ite) => {
                    return (
                      <MenuItem value={ite._id}>{ite.client_name}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              {console.log(
                "address",
                this.state?.address_details?.additional_pincode
              )}
              <TextField
                helperText="Please provide your address"
                id="demo-helper-text-misaligned"
                label="Address"
                name="address_line"
                onChange={this.handleChangeAddressDetails}
                style={stylesCss.inputBox}
                error={
                  isFormValid && !this.state?.address_details?.address_line
                }
                value={
                  this.state?.address_details?.address_line 
                  // ||
                  // (this.state?.address_details?.address_line == undefined &&
                  //   "No Details Found")
                }
              />
            </Grid>

            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <TextField
                helperText="Please enter your city"
                id="demo-helper-text-misaligned"
                label="City"
                name="city"
                onChange={this.handleChangeAddressDetails}
                error={isFormValid && !this.state.address_details.city}
                // value={this.state.address_details.city}
                value={
                  this.state?.address_details?.city 
                  // ||
                  // (this.state?.address_details?.city == undefined &&
                  //   "No Details Found")
                }
                style={stylesCss.inputBox}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <FormControl
                md={6}
                style={stylesCss.inputBox}
                sx={{ minWidth: 120 }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  State
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="State"
                  name="state"
                  onChange={this.handleChangeAddressDetails}
                  value={
                    this?.state?.address_details?.state == undefined
                      ? "No Data Found"
                      : this?.state?.address_details?.state
                  }
                  // placeholder={this.state.address_details.state || this.state.address_details.state == undefined && "No Data Found" }
                  error={isFormValid && !this.state.address_details.state}
                >
                  {IndianStates?.map((ite) => {
                    return <MenuItem value={ite.value}>{ite.value}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Country"
                name="country"
                onChange={this.handleChangeAddressDetails}
                error={isFormValid && !this.state.address_details.country}
                value={this.state.address_details.country}
                style={stylesCss.inputBox}
                disabled
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            {this.state.isEditDetailsExists && (
              <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.login_into_application}
                        onChange={(e) =>
                          this.handleChangeCheckBox(e, "login_into_application")
                        }
                      />
                    }
                    label="Disable login"
                  />
                   <IconButton
                   onClick={() => this.handleWhatsAppClick(this.state.center_name,this.state.personal_details.phone.mobile_number
                    
                 )} 
                   sx={{ width: '50px', height: '50px' }}>
            <WhatsAppIcon sx={{ fontSize: '2rem', color:"green" }} />
        </IconButton>
                </FormGroup>
              </Grid>
            )}

            {this.state.isEditDetailsExists && (
              <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.accepting_broadcast_ticket}
                        onChange={(e) =>
                          this.handleChangeCheckBox(
                            e,
                            "accepting_broadcast_ticket"
                          )
                        }
                      />
                    }
                    label="Disable accepting ticket"
                  />
                </FormGroup>
              </Grid>
            )}


          {this.state.remarkRequired && (
            <Grid item md={6} style={stylesCss.paddingInnerGrids}>
                <TextField
                    label="Remark"
                    variant="outlined"
                    style={stylesCss.inputBox}
                    name="remark"
                    value={this.state.remark}
                    onChange={(e) =>
                      this.setState({
                        remark: e.target.value,
                      })
                    }
                  />
            </Grid>
          )}

            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <TextField
                helperText="Please enter your pincode"
                id="demo-helper-text-misaligned"
                label="Address Area Pincode (main)"
                name="pincode"
                type="number"
                onChange={this.handleChangeAddressDetails}
                error={isFormValid && !this.state.address_details.pincode}
                value={this.state.address_details.pincode}
                style={stylesCss.inputBox}
                inputProps={{ maxLength: 6 }}
              />
            </Grid>

            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
        <TextField
          id="main-service-area-pincodes"
          label="Main Service Area Pincodes"
          name="pincodes"
          type="text" // Use text to handle comma-separated values
          onChange={this.handleChangePincodes}
          value={this.state.pincodes}
          fullWidth
          variant="outlined"
          helperText="Please enter multiple pincodes separated by commas"
          style={stylesCss.inputBox}
          inputProps={{ maxLength: 200 }} // Adjust maxLength as needed
        />
      </Grid>


            <Grid item md={6} style={stylesCss.paddingInnerGrids}>
              <FormControl style={stylesCss.inputBox} sx={{ minWidth: 120 }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Technician Associate
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Technician Associate"
                  name="no_of_technicians"
                  onChange={this.handleChangeInputOuter}
                  error={isFormValid && this.state.no_of_technicians === ""}
                  value={this.state.no_of_technicians}
                >
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ite) => {
                    return <MenuItem value={ite}>{ite}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>

            {this.state.isEditDetailsExists ? (
              ""
            ) : (
              <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.automate_qr_code}
                        onChange={(e) =>
                          this.handleChangeCheckBox1(e, "automate_qr_code")
                        }
                      />
                    }
                    label="Automate qr code"
                  />
                </FormGroup>
              </Grid>
            )}
            {!this.state.isEditDetailsExists ? (
              !this.state.automate_qr_code ? (
                <>
                  <Grid item md={6} style={stylesCss.paddingInnerGrids}>
                    <TextField
                      helperText="Please enter your additional pincode"
                      id="demo-helper-text-misaligned"
                      label="Enter qr code"
                      name="qr_code"
                      onChange={(e) => {
                        this.setState({ qr_code: e.target.value });
                      }}
                      value={this.state.qr_code}
                      style={stylesCss.inputBox}
                      inputProps={{ maxLength: 10 }}
                    />
                  </Grid>
                </>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {this.state.isEditDetailsExists ? (
              <>
                <Grid item md={6} style={stylesCss.paddingInnerGrids}>
                  <TextField
                    helperText="Your UPI Id"
                    id="demo-helper-text-misaligned"
                    label="Enter upi id"
                    name="upi_id"
                    onChange={(e) => {
                      this.setState({ upi_id: e.target.value });
                    }}
                    value={
                      this.state?.payment_details?.upi_id == undefined
                        ? "NAN"
                        : this.state?.payment_details?.upi_id
                    }
                    style={stylesCss.inputBox}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}

            {this.state.isOtpSentSuccess && !this.state.isOtpVerfied ? (
              <Grid item style={stylesCss.paddingInnerGrids} md={6}>
                <TextField
                  id="demo-helper-text-misaligned"
                  label="Enter otp"
                  name="otpEntered"
                  onChange={this.handleChangeInputOuter}
                  value={this.state.otpEntered}
                  style={stylesCss.inputBox}
                />
              </Grid>
            ) : (
              ""
            )}
            {console.log("this.state.qr_code", this.state.qr_id)}
            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <TextField
                helperText="Your QR Id"
                id="demo-helper-text-misaligned"
                label="Your QR Id"
                name="qr_id"
                disabled={true}
                value={this.state.qr_id}
                style={stylesCss.inputBox}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            {this.state.isEditDetailsExists && (
            <Grid item md={6} style={stylesCss.paddingInnerGrids}>
  <TextField
    label="Ticket Remarks" // Add this field
    variant="outlined"
    style={stylesCss.inputBox}
    name="ticket_remarks"
    value={this.state.ticket_remarks}
    onChange={(e) =>
      this.setState({
        ticket_remarks: e.target.value,
      })
    }
  />
</Grid>
            )}

            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              {
                <Button
                  onClick={() => this.handleRegister()}
                  variant="contained"
                  disabled={this.state.isRegistering}
                >
                  {this.state.isRegistering
                    ? this.state.isEditDetailsExists
                      ? "Updating...."
                      : "Adding ...."
                    : this.state.isEditDetailsExists
                    ? "Update center"
                    : "Add center"}
                </Button>
              }
            </Grid>
          </Grid>
        </Item>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reduxState: state.userReducer,
});
export default connect(mapStateToProps, null)(withRouter(CenterRegistration));
