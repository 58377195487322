import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../components/config/config';
import { useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

function Payments() {
  const globalState = useSelector((state) => state.AdminReducer);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const { centerDetailsPopulated } = globalState; // Destructure centerDetailsPopulated from global state
    const { phone } = centerDetailsPopulated?.personal_details; // Destructure phone from personal_details

    const fetchData = async () => {
      try {
        const response = await axios.post(`${BASE_URL}center/center-all-transaction`, {
          mobile_number: phone?.mobile_number, // Pass mobile_number in the request body
        });
        console.log(response.data); // Log response data to console
        setTransactions(response.data.data); // Set transactions state with fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error, show error message, etc.
      }
    };

    if (phone?.mobile_number) {
      fetchData(); // Call fetchData when mobile_number is available
    }

  }, [globalState]);

  return (
    <div>
      <h2>Transactions</h2>
      <TableContainer component={Paper}>
        <Table aria-label="transactions table">
          <TableHead>
            <TableRow>
            <TableCell>Date</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow key={transaction._id}>
              
              <TableCell>{new Date(transaction.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}</TableCell>
                <TableCell>{transaction.transactionId}</TableCell>
                <TableCell>{transaction.amount}</TableCell>
                <TableCell>{transaction.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Payments;
