import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom"
import { setLoginDetails, setLogoutDetails } from '../../store/CenterOnboardStore/action'
import { useSelector, useDispatch } from 'react-redux'
import Onit from "../ServicesStatus/onitLogo.png"
const drawerWidth = 240;

let drawerItemsResponsive = [
    {
        name: 'View Centers',
        path: '/center-onboard-view-centers'
    }
]

function ResponsiveDrawer(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const globalState = useSelector((state) => state.centerOnboardReducer)

    const history = useHistory()
    const dispatch = useDispatch()


    const drawer = (
        <div style={{backgroundColor:"#A3E4D7"}}>
                  <img src={Onit} alt="Onit Logo" style={{ width: '70%', height: '70px', marginLeft:"15px", marginRight:"15px", marginTop:"10px" }} />
           
            <List>
                {drawerItemsResponsive.map((text, index) => (
                    <ListItem onClick={() => {

                        history.push(text.path)

                    }} button key={text.name}>
                        <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                        </ListItemIcon>
                        <ListItemText primary={text.name} />
                    </ListItem>
                ))}

                <ListItem onClick={() => {

                    onClickLogout()

                }} button>
                    <ListItemIcon>
                        {<InboxIcon />}
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                </ListItem>
            </List>
        </div>
    );

    const onClickLogout = () => {
        dispatch(
            setLoginDetails({
                centerOnboardDetails: {},
                isCenterOnboardLoggedIn: false,
                userTokens: {
                    accessToken: "",
                },
            }),
        )

        history.push("/center-onboard-login")
    }

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex', backgroundColor:'#A3E4D7' }}>
            <CssBaseline />
            <AppBar
    position="fixed"
    sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: "#A3E4D7", // Set background color here
    }}
>
    <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
        >
            <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ color: 'black' }}>
    Welcome {globalState.centerOnboardDetails.name}
</Typography>
    </Toolbar>
</AppBar>

            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } , backgroundColor:"#A3E4D7"}}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        backgroundColor:"#A3E4D7",
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        backgroundColor:"#A3E4D7",
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;
