import * as React from "react";
import Box from "@mui/material/Box";
import { Chip } from "@material-ui/core";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { Button, Grid, Typography } from "@mui/material";
import BasicDateRangePicker from "../../../components/common/datepicker";
import { BASE_URL } from "../../../components/config/config";
import Avatar from "@mui/material/Avatar";
import { Download } from "@mui/icons-material";

// Mui data table
const columns = [
  {
    name: "avatar",
    label: "Profile Image",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        return (value = true ? (
          <Avatar alt="Remy Sharp" src={value?.url} />
        ) : (
          <Avatar alt="Remy Sharp" src={value} />
        ));
      },
    },
  },
  {
    name: "createdAt",
    label: "Registered Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => moment(value).format("Do MMM, hh:ss A"),
    },
  },
  {
    name: "personal_details",
    label: "Name",
    search: "true",
    options: {
      filter: false,
      sort: false,
      // Search not working when i use html code
      customBodyRender: (value) => value?.name,
    },
  },
  {
    name: "personal_details",
    label: "Email",
    options: {
      filter: false,
      sort: false,
      // Search not working when i use html code
      customBodyRender: (value) => value?.email,
    },
  },
  {
    name: "referral_given",
    label: "Referral Given",
    options: {
      filter: true,
      sort: false,
      // Search not working when i use html code
      customBodyRender: (value) => value.length>0?value.length:"0",
    },
  },
  {
    name: "referral_received",
    label: "Referral From",
    options: {
      filter: true,
      sort: false,
      // Search not working when i use html code
      customBodyRender:(value)=>value?"Yes":"No One",
    },
  },
  {
    name: "device_id",
    label: "Registered From",
    options: {
      filter: true,
      sort: false,
      // Search not working when i use html code
      customBodyRender:(value)=>value?"App":"Web",
    },
  },
  // {
  //   name: "personal_details",
  //   label: "Mobile Number",
  //   options: {
  //     filter: false,
  //     sort: false,
  //     // Search not working when i use html code
  //     customBodyRender:(value)=>value?.phone?.mobile_number,
  //   },
  // },

  {
    name: "address_details_permanent",
    label: "Address",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => {
        // Render only the defined fields
        const renderedFields = [
          value?.address_line,
          value?.city,
          value?.state,
          value?.pincode
        ].filter(field => field !== undefined).join(" ");
      
        return renderedFields;
      },
    },
  },
];

const options = {
  search: true,
  filter: true, // Hide filter icon
  Download:false,
  
};

const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "2px",
  },
};

export default function UserDetailsTable() {
  const globalState = useSelector((state) => state.AdminReducer);

  let accessToken = globalState?.userTokens?.accessToken;
  console.log(globalState, "global state");

  const [value, setValue] = React.useState(0);

  //  Date filter code start
  const onClickFilter = async (value) => {
    let payload = {
      start_date: value[0],
      end_date: value[1],
    };

    try {
      const response = await axios.get(`${BASE_URL}admin/get-all-consumer`, {
        headers: {
          Authorization: accessToken,
        },
        params: payload,
      });
      setRows(response.data?.data);
    } catch (error) {
      console.log("Error fetching data:", error);
      return [];
    }
  };

  //  Date filter code End

  // Table

  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    // Fetch data from the API and update the rows state
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}admin/get-all-consumer`, {
        headers: {
          Authorization: accessToken,
        },
      });
      setRows(response.data?.data);
    } catch (error) {
      console.log("Error fetching data:", error);
      return [];
    }
  };

  console.log("rows", rows);

  return (
    globalState?.adminDetails?.role_id?.permissions?.view_Wallet ?(
    <>
      <Typography variant="h4" mb={2} textAlign="center">
        Consumer Profile
      </Typography>

      <Box style={stylesCss.paddingInnerGrids}>
        <BasicDateRangePicker onClickFilter={onClickFilter} />
      </Box>
      {/* END here */}
      <MUIDataTable options={options} data={rows} columns={columns} />
    </>
    ):(
      "No permission , contact admin"
    )
  );
}
