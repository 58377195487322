import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../components/config/config';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function Reopen() {
  const { id } = useParams();
  const [reopenData, setReopenData] = useState([]);

  useEffect(() => {
    const fetchReopenData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}admin/get-reopen-log/${id}`);
        setReopenData(response.data.data);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.error("Permission error:", error.response.data.message);
          // Handle permission error, e.g., redirect to a permission denied page
        } else {
          console.error("Error fetching history data:", error);
          // Handle other errors
        }
      }
    };
    fetchReopenData();
  }, [id]);

  return (
    <div>
     <h1>Reopen Log</h1>
     <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Created At</TableCell>
                    <TableCell>Assigned At</TableCell>
                    <TableCell>Closed At</TableCell>
                    <TableCell>Reopened At</TableCell>
                    <TableCell>Old Requirement</TableCell>
                    <TableCell>Manual Remark by Technician</TableCell>
                    <TableCell>Ticket Remark</TableCell>
                    <TableCell>Happy/Unhappy</TableCell>
                    <TableCell>Remark</TableCell>
                    <TableCell>Center Name</TableCell>
                    <TableCell>Center INA</TableCell>
                    <TableCell>Center Contact</TableCell>
                    <TableCell>OnSite Image</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {reopenData.map((item)=>(
                    <TableRow key={item._id}>
                        <TableCell> {new Date(item?.ticketCreatedAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</TableCell>
                        <TableCell> {new Date(item?.assignedAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</TableCell>
                        <TableCell>{new Date(item?.closedAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</TableCell>
                        <TableCell> {new Date(item?.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</TableCell>
                        <TableCell>{item.specific_requirement}</TableCell>
                        <TableCell>{item?.messages?.customer_message}</TableCell>
                        <TableCell>{item?.remarks?.close_ticket_remarks}</TableCell>
                        <TableCell>{item?.remarks?.message}</TableCell>
                        <TableCell>{item?.remarks?.technician_remarks}</TableCell>
                        <TableCell>{item?.center_obj_id?.center_name}</TableCell>
                        <TableCell>{item?.center_obj_id?.qr_details?.qr_id}</TableCell>
                        <TableCell>{item?.center_obj_id?.personal_details?.phone?.mobile_number}</TableCell>
                        <TableCell>
                  {item?.onsite_pictures?.map((picture, index) => (
                    <img
                      key={index}
                      src={picture}
                      alt={`OnSite ${index}`}
                      style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px' }}
                    />
                  ))}
                </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
     </TableContainer>
    </div>
  );
}

export default Reopen;
