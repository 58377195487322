import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@material-ui/core/styles";
import "./ViewVerify.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import technician from "../../pages/Admin/technician";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { Alert, InputAdornment, Snackbar } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import AdminApi from "./Api/api";
import axios, { Axios } from "axios";
import { BASE_URL } from "../../components/config/config";
const CustomSwitch = withStyles({
  switchBase: {
    color: "#303F9F",
    "&$checked": {
      color: "#303F9F",
    },
    "&$checked + $track": {
      backgroundColor: "#303F9F",
    },
  },
  checked: {},
  track: {},
})(Switch);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const useStyles = makeStyles({
  root: {
    maxWidth: 500,

    transition: "transform 1s ease-in-out",
    "&:hover": { transform: "scale3d(2, 2, 1)" },
  },
  cardHovered: {
    transform: "scale3d(1.05, 1.05, 1)",
  },
});

const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "10px",
  },
};

export default function ViewVerify({
  isVerificationPopupOpen,
  toggleVerificationDetailsPopup,
  selectedCenterData,
  handleSwitchToggle,
  handleAdharFront,
  handleAdharBack,
  aadharBack,
  panSwitch,
  aadharFront
}) {
 
  const center_Data = selectedCenterData?.document_details;
  const centerId = selectedCenterData?._id;
  const classes = useStyles();
  const [state, setState] = useState({
    raised: false,
    shadow: 1,
  });
  const [aadharNumber, setAadharNumber] = useState("");
  const [error, setError] = useState("");
  const switchToggleHandler = () => {
    handleSwitchToggle(); // Invoke the parent's handleSwitchToggle function
  };

  const switchAdharFrontHandler =()=>{
    handleAdharFront();
  }
  const handleChange = (event) => {
    console.log("=====", center_Data)
    setAadharNumber(event.target.value); // Update the state with the entered value
    setError(""); // Clear any previous error messages when input changes
  };

  const handleSubmit = async () => {
    if (aadharNumber.length !== 12) {
      setError("No. must be 12 characters"); // Set error message if Aadhar number length is not 12
      return; // Exit the function if Aadhar number length is not 12
    }

    try {
      // Make an HTTP POST request to the backend with the entered Aadhar number
      const response = await axios.post(`${BASE_URL}admin/center-documents-verify`, {
        aadhar_number: aadharNumber,
        center_obj_id: centerId,
      });
      console.log(response.data); // Handle the response from the backend as needed
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <Modal
        open={isVerificationPopupOpen}
        onClose={toggleVerificationDetailsPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        {center_Data?.aadhar_card_document?.aadhar_back_verified ? (
  <div style={{ color: "green", fontWeight: "bold", textAlign: "center", fontSize: "20px" }}>
    VERIFIED
  </div>
) : (
  <div style={{ color: "red", fontWeight: "bold", textAlign: "center", fontSize: "20px" }}>
    NOT VERIFIED
  </div>
)}
        {/* Pan Card */}
        <Typography
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
            id="modal-modal-title"
            variant="h8"
            component="h4"
          >
            Pan Card
             {/* Switch code start */}
             {/* <label class="switch">
              <input  
              type="checkbox"
                    // checked={isSwitchOn}
                    // checked={panVerified}
                    checked={panSwitch}
                    onChange={switchToggleHandler} 
                    // onChange={handleSwitchToggle}
                     />
              <div class="slider"></div>
              <div class="slider-card">
                <div class="slider-card-face slider-card-front"></div>
                <div class="slider-card-face slider-card-back"></div>
              </div>
            </label> */}
            {/* Switch code end */}
          </Typography>

          <Card
            className={classes.root}
            onMouseOver={() => setState({ raised: true, shadow: 3 })}
            onMouseOut={() => setState({ raised: false, shadow: 1 })}
            raised={state.raised}
            zDepth={state.shadow}
          >
            <CardMedia
              component="img"
              height="140"
              image={center_Data?.pan_card_document}
              alt="Image Not Available"
            />
          </Card>


          <Typography
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "15px",
              marginTop: "15px",
            }}
            id="modal-modal-title"
            variant="h8"
            component="h4"
          >
            Aadhar Card Front Side
           
            {/* Switch code start */}
            {/* <label class="switch">
              <input  
              type="checkbox"
              checked={aadharFront}
              onChange={switchAdharFrontHandler} 
                     />
              <div class="slider"></div>
              <div class="slider-card">
                <div class="slider-card-face slider-card-front"></div>
                <div class="slider-card-face slider-card-back"></div>
              </div>
            </label> */}
            {/* Switch code end */}
    
          </Typography>

          <Card
            sx={{ maxWidth: 345 }}
            style={{ marginBottom: "10px" }}
            className={classes.root}
            onMouseOver={() => setState({ raised: true, shadow: 3 })}
            onMouseOut={() => setState({ raised: false, shadow: 1 })}
            raised={state.raised}
            zDepth={state.shadow}
          >
            <CardMedia
              component="img"
              height="140"
              image={center_Data?.aadhar_card_document?.front_side}
              alt="Image Not Available"
            />{" "}
          </Card>
          {/* )} */}
          {!center_Data?.aadhar_card_document?.aadhar_back_verified && (
  <TextField
    placeholder="Enter Aadhar Number"
    label="Enter Aadhar No."
    value={aadharNumber}
    onChange={handleChange}
    error={Boolean(error)}
    helperText={error}
    sx={{
      width: "70%",
      marginTop: "10px",
      marginBottom: "10px",
      backgroundColor: "lightgray",
      borderRadius: "5px",
    }}
  />
)}

{error && (
  <Typography variant="body2" color="error">
    {error}
  </Typography>
)}
{!center_Data?.aadhar_card_document?.aadhar_back_verified && (
<Button
  onClick={handleSubmit}
  sx={{
    display: "block",
    fontWeight: "bold",
    width: "30%",
    backgroundColor: "blue",
    margin: "0 auto",
  }}
>
  Submit
</Button>
)}



          <Typography
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "15px",
              marginTop:"15px",
            }}
            id="modal-modal-title"
            variant="h8"
            component="h4"
          >
            Aadhar Card Back Side
            {/* Switch code start */}
            {/* <label class="switch">
              <input  
              type="checkbox"
              checked={aadharBack}
              onChange={handleAdharBack} 
              />
              <div class="slider"></div>
              <div class="slider-card">
                <div class="slider-card-face slider-card-front"></div>
                <div class="slider-card-face slider-card-back"></div>
              </div>
            </label> */}
            {/* Switch code end */}
          </Typography>

          <Card
            sx={{ maxWidth: 345 }}
            style={{ marginBottom: "10px" }}
            className={classes.root}
            onMouseOver={() => setState({ raised: true, shadow: 3 })}
            onMouseOut={() => setState({ raised: false, shadow: 1 })}
            raised={state.raised}
            zDepth={state.shadow}
          >
            <CardMedia
              component="img"
              height="140"
              image={center_Data?.aadhar_card_document?.back_side}
              alt="Image Not Available"
            />
          </Card>

         
          <Grid style={stylesCss.paddingInnerGrids} item></Grid>
        </Box>
      </Modal>
    </div>
  );
}
