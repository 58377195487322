export const IndianStates = [
    { label: 'AN', value: 'Andaman and Nicobar Islands' },
    { label: 'AP', value: 'Andhra Pradesh' },
    { label: 'AR', value: 'Arunachal Pradesh' },
    { label: 'AS', value: 'Assam' },
    { label: 'BR', value: 'Bihar' },
    { label: 'CG', value: 'Chandigarh' },
    { label: 'CH', value: 'Chhattisgarh' },
    { label: 'DN', value: 'Dadra and Nagar Haveli' },
    { label: 'DD', value: 'Daman and Diu' },
    { label: 'DL', value: 'Delhi' },
    { label: 'GA', value: 'Goa' },
    { label: 'GJ', value: 'Gujarat' },
    { label: 'HR', value: 'Haryana' },
    { label: 'HP', value: 'Himachal Pradesh' },
    { label: 'JK', value: 'Jammu and Kashmir' },
    { label: 'JH', value: 'Jharkhand' },
    { label: 'KA', value: 'Karnataka' },
    { label: 'KL', value: 'Kerala' },
    { label: 'LA', value: 'Ladakh' },
    { label: 'LD', value: 'Lakshadweep' },
    { label: 'MP', value: 'Madhya Pradesh' },
    { label: 'MH', value: 'Maharashtra' },
    { label: 'MN', value: 'Manipur' },
    { label: 'ML', value: 'Meghalaya' },
    { label: 'MZ', value: 'Mizoram' },
    { label: 'NL', value: 'Nagaland' },
    { label: 'OR', value: 'Odisha' },
    { label: 'PY', value: 'Puducherry' },
    { label: 'PB', value: 'Punjab' },
    { label: 'RJ', value: 'Rajasthan' },
    { label: 'SK', value: 'Sikkim' },
    { label: 'TN', value: 'Tamil Nadu' },
    { label: 'TS', value: 'Telangana' },
    { label: 'TR', value: 'Tripura' },
    { label: 'UP', value: 'Uttar Pradesh' },
    { label: 'UK', value: 'Uttarakhand' },
    { label: 'WB', value: 'West Bengal' }
];