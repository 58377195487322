import React from 'react'

function Wallet_client() {
  return (
    <div>
      WALLEt
    </div>
  )
}

export default Wallet_client
