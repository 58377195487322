import React from 'react'

function Dashboard() {
  return (
    <div>
      DASHBOARD
    </div>
  )
}

export default Dashboard
