import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Chip } from "@material-ui/core";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import BasicDateRangePicker from "../../../components/common/datepicker";
import ConsumerWalletTable from "./Table/ConsumerWallet";
import { BASE_URL } from "../../../components/config/config";

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          backgroundColor: "red",
        },
        indicator: {
          backgroundColor: "red", // Replace with your desired color
        },
      },
    },
  },
});

const CustomTab = withStyles({
  root: {
    textTransform: "none",
    color: "white",
    fontWeight: "500",
    fontSize: "18px",
    textTransform: "capitalize",
    letterSpacing: "5px",
  },
})(Tab);

// Mui data table
const columns = [
  {
    name: "createdAt",
    label: "Transaction Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => moment(value).format("Do MMM, hh:ss A"),
    },
  },
  {
    name: "userId",
    label: "User ID",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "center_INA",
    label: "Center INA",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "center_name",
    label: "Center Name",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "center_mobile",
    label: "Center Number",
    options: {
      filter: true,
      sort: false,
     display: 'none',
    },
  },
  
  // {
  //   name: "transactionId",
  //   label: "Transaction ID",
  //   options: {
  //     filter: false,
  //     sort: false,
  //   },
  // },
  {
    name: "amount",
    label: "Total Amount",
    options: {
      filter: false,
      sort: false,
    },
  },
  // {
  //   name: "status",
  //   label: "Status",
  //   options: {
  //     filter: false,
  //     sort: false,
  //   },
  // },
  {
    name: "totalTransactions",
    label: "Total Transactions",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "wallet_remain",
    label: "Balance",
    options: {
      filter: false,
      sort: true,
    },
  },
];
const userTransactionColumns = [
  {
    name: "createdAt",
    label: "Transaction Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => moment(value).format("Do MMM, hh:ss A"),
    },
  },
  {
    name: "center_INA",
    label: "Center INA",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "center_name",
    label: "Center Name",
    options: {
      filter: true,
      sort: false,
    },
  },
  // {
  //   name: "center_mobile",
  //   label: "Center Number",
  //   options: {
  //     filter: true,
  //     sort: false,
  //   },
  // },
  {
    name: "transactionId",
    label: "Transaction ID",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "amount",
    label: "Amount",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "wallet_remain",
    label: "Remaining Balance",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: false,
      sort: false,
    },
  },
  // Add more columns specific to  table if needed
];


// Custom filter style
const options = {
  selectableRows: "none",
  responsive: "standard",
  filterType: "checkbox",
  filter: true,
  textLabels: {
    filter: "Filter",
    reset: "Reset",
    close: "Close",
  },
  customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
    <div style={{ padding: "20px" }}>
      <Button variant="contained" onClick={() => applyNewFilters()}>
        Apply
      </Button>
    </div>
  ),
  customFilterDialogFooterStyle: {
    borderTop: "1px solid #ccc",
  },
  customFilterDialogFooterPosition: "right",
  customFilterDialogWidth: "500px",
};


const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "2px",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function WalletHistory() {
  const globalState = useSelector((state) => state.AdminReducer);
  const [loader,setLoader]= React.useState(false)
  const [selectedUserId, setSelectedUserId] = React.useState(null);
  const [userTransactions, setUserTransactions] = React.useState([]);
  const [accessToken, setAccessToken] = React.useState("");

const [rows, setRows] = useState([]);

  console.log(globalState, "global state");

  const [value, setValue] = React.useState(0);

  const refreshData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}admin/wallet-transactions`, {
        headers: {
          Authorization: accessToken,
        },
      });
      setRows(response.data?.data);
    } catch (error) {
      console.log("Error fetching data:", error);
      return [];
    }
  };

  //  Date filter code start
  const onClickFilter = async (value) => {
    // this.startLoading();

    let payload = {
      start_date: value[0],
      end_date: value[1],
    };

    try {
      const response = await axios.get(
        `${BASE_URL}admin/wallet-transactions`,
        {
          headers: {
            Authorization: accessToken,
          },
          params: payload,
        }
      );
      setRows(response.data?.data);
    } catch (error) {
      console.log("Error fetching data:", error);
      return [];
    }
  };

  //  Date filter code End

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Table


  React.useEffect(() => {
    setAccessToken(globalState?.userTokens?.accessToken);
    fetchData();
  }, [globalState]);

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await axios.get(`${BASE_URL}admin/wallet-transactions`, {
        headers: {
          Authorization: accessToken,
        },
      });
  
      // Sort the data by createdAt field in descending order
      const sortedData = response.data?.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
  
      const groupedRows = sortedData.reduce((acc, transaction) => {
        const { userId, amount } = transaction;
        if (!acc[userId]) {
          acc[userId] = { ...transaction, amount: 0, totalTransactions: 0 };
        }
        acc[userId].amount += amount;
        acc[userId].totalTransactions++;
        return acc;
      }, {});
  
      const finalRows = Object.values(groupedRows);
      setRows(finalRows);
    } catch (error) {
      console.log("Error fetching data:", error);
      setRows([]);
    } finally {
      setLoader(false);
    }
  };
  
  // const handleRowClick = (userId) => {
  //   setSelectedUserId(userId); // Assuming userId is in the second column
  //   fetchUserTransactions(userId);
  // };

  const handleRowClick = (userId) => {
    setSelectedUserId(userId);
    fetchUserTransactions(userId);
  
    // Scroll to the bottom table
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth"
    });
  };
  

  const fetchUserTransactions = async (userId) => {
    try {
      const response = await axios.get(`${BASE_URL}admin/wallet-transactions-byId`, {
        headers: {
          Authorization: accessToken,
        },
        params: {
          userId: userId,
        },
      });
  
      // Sort the user transactions by createdAt field in descending order
      const sortedTransactions = response.data?.data.sort((a, b) => {
        return  new Date(b?.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }), -  new Date(a?.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
      });
  
      setUserTransactions(response.data?.data);
    } catch (error) {
      console.log("Error fetching user transactions:", error);
      setUserTransactions([]);
    }
  };
  const handleCloseDetailView = () => {
    setSelectedUserId(null);
    setUserTransactions([]);
  };
  

  return (
   
  globalState?.adminDetails?.role_id?.permissions?.view_Wallet ?(
    
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ThemeProvider theme={theme}>
        <Typography variant="h4" mb={2} textAlign="center">
          Wallet History
        </Typography>

        <Box sx={{ border: 1, borderColor: "divider", width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="white"
              aria-label="secondary tabs example"
              sx={{
                "& .MuiTabs-flexContainer": {
                  justifyContent: "space-around",
                  background: "#9DB2BF",
                },
                "& .MuiTabs-scroller": {
                  "&::before": {
                    content: '""',
                    display: "block",
                    flexShrink: 0,
                  },
                },
              }}
            >
              <CustomTab label="CENTER" {...a11yProps(0)} />
              <CustomTab label="CONSUMER" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Box style={stylesCss.paddingInnerGrids}>
              <BasicDateRangePicker onClickFilter={onClickFilter} refreshData={refreshData} />
            </Box>
            <MUIDataTable
              data={rows}
              columns={columns}
              options={{ ...options, onRowClick: handleRowClick }}
            />
            {selectedUserId && (
                 <div>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "right" }}>
          <Typography variant="h6"> {userTransactions.length > 0 ? userTransactions[0].center_name : ""}</Typography>
          
          <Button variant="contained" onClick={handleCloseDetailView}>    Close</Button>

          {/* Display user transactions here */}
          </div>
          <MUIDataTable data={userTransactions} columns={userTransactionColumns} options={options} id={`user-table-${selectedUserId}`}/>
      
        </div>
      )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ConsumerWalletTable />
          </TabPanel>
          
        </Box>
      </ThemeProvider>
      {/* Detail View */}
  
    </>
    ):(
      "No permission , contact admin"
    )

  );
}