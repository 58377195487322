import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from '@mui/icons-material/Notifications';
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useHistory, useLocation, NavLink } from "react-router-dom"; // Add NavLink
import BuildIcon from '@mui/icons-material/Build';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import EngineeringIcon from '@mui/icons-material/Engineering';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import FeedbackIcon from '@mui/icons-material/Feedback';
import PeopleIcon from '@mui/icons-material/People';
import CellTowerIcon from '@mui/icons-material/CellTower';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaymentIcon from '@mui/icons-material/Payment';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  setLoginDetails,
  setLogoutDetails,
} from "../../store/AdminStore/action";
import { useSelector, useDispatch } from "react-redux";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HomeIcon from '@mui/icons-material/Home';
import Onit from "../ServicesStatus/onitLogo.png"
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  Menu,
  MenuItem
} from "@mui/material";
import AddHomeIcon from '@mui/icons-material/AddHome';
import ElectricRickshawIcon from '@mui/icons-material/ElectricRickshaw';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
const drawerWidth = 240;

let drawerClientItemsResponsive = [
  {
    name:"Dashboard",
    path:"/client-dashboard",
    icon: <HomeIcon/>,
  },
  {
    name:"Wallet",
    path:"/wallet-client",
    icon: <AccountBalanceWalletIcon/>,
  },
  {
    name: "Tickets",
    path: "/b2b-ticket",
    icon: <PersonAddIcon/>,
  },
  {
    name: "Add Ticket",
    path: "/add-client-ticket",
    icon: <RequestPageIcon />,
  },
]

let drawerItemsResponsive = [
  {
    name: "Home",
    path: "/Home",
    icon: <HomeIcon />,
  },
  {
    name: "Service-Category",
    path: "admin-dashboard",
    icon: <BuildIcon />,
  },
  {
    name: "Notifications",
    path:"/notifications",
    icon: <NotificationsIcon/>
  },
  {
    name: "Centers",
    path: "/admin-centers",
    icon: <SupervisorAccountIcon />,
    children: [
      {
        name: "All Centers",
        path: "/admin-centers",
        icon: <SupervisorAccountIcon />,
      },
      {
        name: "Add center",
        path: "/add-center",
        icon: <PersonAddIcon />,
      }
    ]
  },
  {
    name: "Tickets",
    icon: <PersonAddIcon />,
  children:[
  {
    name: "All Tickets",
    path: "/Tickets",
    icon: <PersonAddIcon />,

  },
  {
    name: "Pending Tickets",
    path: "/pending-ticket",
    icon: <AccessibilityIcon />,
  },
  {
    name: "Closed Tickets",
    path: "/close-ticket",
    icon: <DoneAllIcon />,
  },
  {
    name: "No Service Available",
    path: "/noService-ticket",
    icon: <LocationOffIcon />,
  },
  {
    name: "Center Tickets",
    path: "/center-ticket",
    icon: <AddHomeIcon />,
  },
  {
    name: "E-Rickshaw Tickets",
    path: "/erickshaw-ticket",
    icon: <ElectricRickshawIcon />,
  },
  
]
  },
  // {
  //   name: "Broadcast List",
  //   path: "/broad-casted-list",
  //   icon: <FeedbackIcon />,
  // },
  {
    name: "Add Ticket",
    path: "/admin-ticket",
    icon: <RequestPageIcon />,
  },
  {
    name: "Technicians",
    path: "/admin-technician",
    icon: <EditIcon />,
  },
  // {
  //   name: "Add Technician",
  //   path: "/Add_technician",
  //   icon: <RequestPageIcon />,
  // },
  // {
  //   name: "FeedBack",
  //   path: "/admin-feedBack",
  //   icon: <EngineeringIcon />,
  // },
  {
    name: "B2B DETAILS",
    icon: <PeopleAltIcon />,
  children:[
    {
      name: "Clients",
      path: "/admin-clients",
      icon: <NoteAddIcon />,
    },
  {
    name: "All Client Tickets",
    path: "/all-client-ticket",
    icon: <PersonAddIcon/>,
  },
],
  },
 
  {
    name: "Roles",
    path: "/roles",
    icon: <PeopleIcon />,
  },
  {
    name: "Users",
    path: "/users",
    icon: <CellTowerIcon />,
  },
  {
    name: "Users Data",
    path: "/usersdetails",
    icon: <AdminPanelSettingsIcon />,
  },
  {
    name: "Center Onboarders",
    path: "/admin-center-onboarders",
    icon: <FolderSharedIcon />,
  },
  {
    name: "Wallet Transaction",
    path: "/wallet",
    icon: <AccountBalanceWalletIcon />,
  },
  // {
  //   name: "Payment Transaction",
  //   path: "/payment",
  //   icon: <PaymentIcon />,
  // },
];

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  // dropdown code
  const [openItem, setOpenItem] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [userName, setUserName] = useState("");
  const { adminDetails } = useSelector((state) => state.userReducer);
  const location = useLocation(); 
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedPage, setSelectedPage] = React.useState(""); // Define selectedPage
  const [anchorEl, setAnchorEl] = useState(null);


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(setLoginDetails({}));
    history.push("/admin-login");
    handleMenuClose();
  };

  useEffect(() => {
    const path = location.pathname;
    setSelectedPage(path);
  }, [location.pathname]);

  const handleToggle = (itemName) => {
    setOpenItem(openItem === itemName ? "" : itemName);
  };

  // const handleToggle = () => {
  //   setOpen(!open);
  // };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawerItems = adminDetails?.role_id?.permissions?.view_admin_bar
  ? drawerItemsResponsive
  : drawerClientItemsResponsive;

  const drawer = (
    <div style={{backgroundColor:"#A3E4D7"}}>
      <img src={Onit} alt="Onit Logo" style={{ width: '70%', height: '70px', marginLeft:"15px", marginRight:"15px", marginTop:"10px" }} />
      <List sx={{backgroundColor:"#A3E4D7"}}>
        {drawerItems.map((item, index) => (
          <React.Fragment key={index}>
            {item.children ? (
              <>
                <ListItem
                  button
                  onClick={() => handleToggle(item.name)}
                  selected={selectedPage === item.path} // Compare path instead of name
                  sx={{
                    height: 45,
                    width: '100%',
                    // backgroundColor:"#A3E4D7",
                     backgroundColor: selectedPage === item.path ? '#003366' : '#A3E4D7', // Darker blue when selected
                    '&:hover': {
                      backgroundColor: '#0055b3', // Darker shade on hover
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '35px', marginLeft: '9px' }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                  {openItem === item.name  ? <ExpandLess style={{ marginRight: '10px' }} /> : <ExpandMore style={{ marginRight: '10px' }} />}
                </ListItem>
                <Collapse in={openItem === item.name} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.children.map((childItem, childIndex) => (
                      <ListItem
                        key={childIndex}
                        button
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(childItem.path);
                          setSelectedPage(childItem.path);
                        }}
                        sx={{
                          height: 45,
                          width: '100%',
                          backgroundColor: '#A3E4D7', // Blue background color for all child items
                          '&.Mui-selected': {
                            backgroundColor: '#0055b3', // Darker blue for selected item
                          },
                          '&:hover': {
                            backgroundColor: '#0055b3', // Darker shade on hover
                          },
                        }}
                        selected={selectedPage === childItem.path} 
                      >
                        <ListItemIcon sx={{ minWidth: '35px', marginLeft: '9px' }}>
                          {childItem.icon}
                        </ListItemIcon>
                        <ListItemText primary={childItem.name} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <ListItem 
                button
                component={NavLink}
                to={item.path}
                onClick={() => setSelectedPage(item.path)}
                selected={selectedPage === item.path} 
                sx={{
                  height: 45,
                  width: '100%',
                  // backgroundColor:"#A3E4D7",
                  backgroundColor: selectedPage === item.path ? '#003366' : '#A3E4D7', 
                  '&:hover': {
                    backgroundColor: '#0055b3', 
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: '35px', marginLeft: '9px' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            )}
          </React.Fragment>
        ))}
        <Divider />
        <ListItem
          button
          onClick={() => {
            localStorage.removeItem("token");
            dispatch(setLoginDetails({}));
            history.push("/admin-login");
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"Logout"} />
          <Toolbar/>
        </ListItem>
      </List>
    </div>
  );
  
const container = window !== undefined ? () => window().document.body : undefined;
return (
  <Box sx={{ display: "flex" }}>
    <CssBaseline />
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar sx={{backgroundColor:"#A3E4D7"}}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2 }}
        >
          <MenuIcon sx={{ color: 'black' }} />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, color:"black"}}>
          ONIT-SERVICES (अब हर काम बहुत आसान)
        </Typography>
        <Typography variant="subtitle1" sx={{ marginRight: '10px' }}>
          <b>{userName}</b>
        </Typography>
        <IconButton
          color="inherit"
          aria-label="open menu"
          edge="end"
          onClick={handleMenuOpen}
        >
           <div style={{marginRight:"20px", color:"black"}}>{adminDetails?.user_name}</div>
          <AccountCircleIcon fontSize="large" color="black"/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{ 
              style: {
                width: '200px', 
              },
            }}
          >
            <MenuItem 
            onClick={handleLogout}
            sx={{
              width: '100%',
              height:"100%",
              display: 'flex',
              alignItems: 'center'
            }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </MenuItem>
          </Menu>
      </Toolbar>
    </AppBar>
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
  variant="permanent"
  sx={{
    display: { xs: "none", sm: "block" },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: drawerWidth,
    },
  }}
        open
>
  {drawer}
</Drawer>
    </Box>
  </Box>
);
}

ResponsiveDrawer.propTypes = {
window: PropTypes.func,
};

export default ResponsiveDrawer;
