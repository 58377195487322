import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import { Chip } from "@material-ui/core";
import "../Styles/style.css";
import CenterApi from "../components/centerComponent/api/api";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import AssignTechnicianPopup from "../components/centerComponent/assignTechnicianPopup";
import CloseTicketRemarksModal from "../components/Components/CloseTicketRemark";
import AddRemarksPopup from "../components/Components/AddRemarksPopup";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import { Alert, Snackbar } from "@mui/material";
import AdminApiModule from "../components/AdminApi/Api/api";
import { Box } from "@mui/material";
import DeleteAlertDialogModal from "../components/Components/DeleteAlert";
import axios from "axios";
import { BASE_URL } from "../components/config/config";
import RebookModal from "../components/Components/RebookMoadl";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useSelector } from "react-redux";
const options = {
  filterType: "dropdown",
  download: false,
  print: false,
};

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

class Tickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticketsList: [],
      columns: [
        {
          name: "center_id",
          label: "Center Id",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "service_required",
          label: "Service Required",
          options: {
            filter: true,
            sort: false,
            customBodyRender: (value) => {
                console.log("value service required",value)
                return (
//   <p> {value}</p>
<Box  sx={{ width: 400 }} >
<NoMaxWidthTooltip  title={value} arrow>
<Button>{value.slice(0,40)}</Button>
</NoMaxWidthTooltip >
    </Box>

                ) 
            }
          },
        },
        {
          name: "tstatus",
          label: "Ticket status",
          options: {
            filter: true,
            sort: false,
            customBodyRender: (value) => {
              // console.log("Technician Assigned", value);
              return value == "Technician Assigned" ? (
                <Chip label={value} style={{ background: "#8FD1AD" }} />
              ) : (
                <Chip
                  label={value}
                  style={{
                    background:
                      value == "PENDING" ? "orange" : value == "Technician Not Assigned"
                       
                        ? "red" : "red",
                  }}
                />
              );
            },
          },
        },
        {
          name: "name",
          label: "Name",
          options: {
            filter: true,
            sort: false,
          },
        },
        //
        {
          name: "qrstatus",
          label: "Qr Status",
          options: {
            filter: true,
            sort: false,
          },
        },
        //
        {
          name: "phone",
          label: "Phone",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "address",
          label: "Address",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "pincode",
          label: "Pin-code",
          options: {
            filter: true,
            sort: false,
          },
        },

        {
          name: "createdAt",
          label: "Created At",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "remarks",
          label: "Remarks",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              
              return (
                <>
                  {value?.ticket_status !== "CLOSED" ? (
                    <>
                      <Button
                        onClick={() => this.openAddRemarksPopup(value)}
                        variant="contained"
                        color="primary"
                      >
                        Add Remarks
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                </>
              );
            },
          },
        },
        {
          name: "action",
          label: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
           
              return (
                <>
                  <Box display="flex" sx={{ width: 500 }}>
                    <Tooltip title="Delete">
                      <Button
                        ml={2}
                        style={{ background: "#FCE9F1", marginLeft: "10px" }}
                        onClick={() => this.clickDeleteTicket(value?._id)}
                      >
                        <DeleteIcon style={{ color: "red" }} />
                      </Button>
                    </Tooltip>
                    {value?.is_technician_assigned &&
                    value?.ticket_status !== "CLOSED" ? (
                      <>
                        <Button
                          style={{ marginLeft: "10px" }}
                          onClick={() =>
                            this.clickCloseTicket(value?._id, value?.ticket_id)
                          }
                          variant="contained"
                          color="primary"
                        >
                          Close Ticket
                        </Button>
                        <Button
                          style={{ marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => {
                            this.handleOpenTechnicianPopup(value, true);
                          }}
                        >
                          Assign new Technician
                        </Button>
                      </>
                    ) : (
                      ""
                    )}

                    {value?.ticket_status == "CLOSED" && (
                      <Tooltip title="Rebook">
                        <Button
                          style={{
                            background: "#FCE9F1",
                            marginLeft: "10px",
                          }}
                          onClick={() => this.handleRebookOpen(value?._id)}
                        >
                          <UpdateIcon style={{ color: "green" }} />
                          {/* Rebook */}
                        </Button>
                      </Tooltip>
                    )}

                    {!value?.is_technician_assigned ? (
                      <>
                        <Button
                          variant="contained"
                          onClick={() => {
                            this.handleOpenTechnicianPopup(value, false);
                          }}
                        >
                          Assign Technician
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>

                  {/* <Button onClick={() => this.openAddRemarksPopup(value?._id)} variant="contained" color="primary" >Add Remarks</Button> */}
                </>
              );
            },
          },
        },
      ],
      isAssignTechnicianPopupOpen: false,
      ticketDetailsForAssignTechnician: {},
      isTechnicianAssigning: false,
      technicianList: [],
      isCloseTicketRemarksModalOpen: false,
      closingTicketId: "",
      deleteTicketId: "",
      isDeleteAlertPopupOpen: false, // delete alert
      rebookTicketId: "",
      openRebook: false,
      isForChange: false,
      getSingleTicketData: [], //Single data Ticket
      ticketObj: {},
      toast: {
        open: false,
        msg: "",
        duration: 5000,
        type: "",
      },
    };
  }

  showDialog = (open, type, msg) => {
    this.setState({
      toast: {
        open: open,
        msg: msg,
        duration: 5000,
        type: type,
      },
    });
  };

  closeDialog = (open = false) => {
    this.setState({
      toast: {
        open: open,
        msg: "",
        duration: 5000,
        type: "",
      },
    });
  };

  handleOpenTechnicianPopup = (ticketDetails, isForChangeValue) => {
    this.setState({
      isAssignTechnicianPopupOpen: true,
      ticketDetailsForAssignTechnician: ticketDetails,
      isForChange: isForChangeValue,
    });
  };

  handleOpenCloseTicketDeleteModal = () => {
    this.setState({
      isDeleteAlertPopupOpen: true,
    });
  };

  clickDeleteTicket = (ticketId) => {
    this.setState({ deleteTicketId: ticketId });
    this.handleOpenCloseTicketDeleteModal(ticketId);
    localStorage.setItem("ticketId", ticketId);
  };

  handleCloseAssignTechnicianPopup = () => {
    this.setState({
      isAssignTechnicianPopupOpen: false,
      ticketDetailsForAssignTechnician: {},
    });
  };

  handleOpenCloseTicketRemarksModal = () => {
    this.setState({
      isCloseTicketRemarksModalOpen: true,
    });
  };

  handleCloseCloseTicketRemarksModal = () => {
    this.setState({
      isCloseTicketRemarksModalOpen: false,
    });
  };

  handleCloseTicket = (
    closingTicketRemarks,
    closingPrice,
    closingCode,
    reasonToClose
  ) => {
    if (!(closingPrice == 0 || closingPrice)) {
      return;
    }

    if (this.state.closingTicketId && this.state.closingTicketId.length > 0) {
      let payload = {
        ticket_obj_id: this.state.closingTicketId,
        remarks: {
          close_ticket_remarks: reasonToClose,
          technician_remarks: closingTicketRemarks,
        },
        amount: parseInt(closingPrice),
        code: closingCode,
      };

      // console.log('payload is', payload);

      new CenterApi()
        .closeTicket(payload)
        .then((res) => {
          this.setState({ closingTicketId: "" });
          this.handleCloseCloseTicketRemarksModal();
          this.showDialog(true, "success", "Ticket closed successfully");

          this.getAllTickets();
        })
        .catch((err) => {
          console.log(err);
          this.showDialog(true, "error", err?.response?.data?.message);
        });
    }
  };

  // Handle Revisit Ticket Close
  handleRevisitCloseTicket = (
    closingTicketRemarks,
    closingPrice,
    closingCode,
    reasonToClose,
    revisitRemark
  ) => {
    if (!(closingPrice == 0 || closingPrice)) {
      return;
    }

    if (this.state.closingTicketId && this.state.closingTicketId.length > 0) {
      let payload = {
        center_id: this.state.centerAssignId,
        ticket_obj_id: this.state.closingTicketId,
        remarks: {
          close_ticket_remarks: reasonToClose,
          technician_remarks: closingTicketRemarks,
          revisit_remarks: revisitRemark,
        },
        amount: parseInt(closingPrice),
        code: closingCode,
      };

      //   console.log("payload is", payload);

      new CenterApi()
        .closeTicket(payload)
        .then((res) => {
          this.setState({ closingTicketId: "" });
          this.handleCloseCloseTicketRemarksModal();
          this.showDialog(true, "success", "Ticket closed successfully");

          this.getAllTickets();
        })
        .catch((err) => {
          console.log(err);
          this.showDialog(true, "error", err?.response?.data?.message);
        });
    }
  };
  // Handle Center Revisit Ticket Close
  handleCenterRevisitCloseTicket = (
    closingTicketRemarks,
    closingPrice,
    closingCode,
    reasonToClose,
    revisitRemark
  ) => {
    console.log("<------😊handleCenterRevisitCloseTicket function");
    if (!(closingPrice == 0 || closingPrice)) {
      return;
    }

    try {
      let payload = {
        center_id: this.state.centerAssignId,
        ticket_obj_id: this.state.closingTicketId,
        remarks: {
          close_ticket_remarks: reasonToClose,
          technician_remarks: closingTicketRemarks,
          revisit_remarks: revisitRemark,
        },
        amount: parseInt(closingPrice),
        code: closingCode,
      };

      //   console.log("payload is", payload);

      new CenterApi()
        .closeTicket(payload)
        .then((res) => {
          this.setState({ closingTicketId: "" });
          this.handleCloseCloseTicketRemarksModal();
          this.showDialog(true, "success", "Ticket closed successfully");

          this.getAllTickets();
        })
        .catch((err) => {
          console.log(err);
          this.showDialog(true, "error", err?.response?.data?.message);
        });
    } catch (error) {
      console.error(error.message);
    }
  };

  clickCloseTicket = (ticket_obj_id, centerTicketId) => {
    localStorage.setItem("TicketCenterId", ticket_obj_id);
    localStorage.setItem("centerTicketId", centerTicketId);
    this.setState({ closingTicketId: ticket_obj_id });

    // New Code start
    // Get One ticket data
    const ticket_Id = localStorage.getItem("tickeClose_Id");
    const ticketID = localStorage.getItem("ticketId");
    const payload = {
      ticket_obj_id: ticket_obj_id,
    };

    console.log(ticket_Id, "<------This is the data😊😊😊😊😊😊😊😊😊😊😊😊");
    try {
      new CenterApi().getSingleTicket(payload).then((res) => {
        console.log(res?.data, "👌data");
        this.setState({ getSingleTicketData: res?.data });
      });
    } catch (error) {
      console.log(error.message, "<------This Error😊😊😊😊😊😊😊😊😊😊😊😊");
    }

    // New Code end

    this.handleOpenCloseTicketRemarksModal(ticket_obj_id);
  };

  openAddRemarksPopup = (ticketObj) => {
    this.setState({ ticketObj: ticketObj });
    this.setState({ isOpenAddRemarksPopup: true });
  };

  closeAddRemarksPopup = () => {
    this.setState({ isOpenAddRemarksPopup: false });
  };

  componentDidMount() {
    this.getAllTickets();
    this.getAllTechnician();
  }

  getAllTechnician = () => {
    new CenterApi()
      .getAllTechnician()
      .then((res) => {
        this.setState({
          technicianList: res?.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleAssignTechnician = (technician_obj_id) => {
    if (!technician_obj_id) {
      return;
    }

    this.setState({ isTechnicianAssigning: true });
    let payload = {
      ticket_obj_id: this.state.ticketDetailsForAssignTechnician._id,
      technician_obj_id: technician_obj_id,
    };

    new CenterApi()
      .assignTechnician(payload)
      .then((res) => {
        this.handleCloseAssignTechnicianPopup();
        this.setState({ isTechnicianAssigning: false });
        this.getAllTickets();
      })
      .catch((err) => {
        this.setState({ isTechnicianAssigning: false });

        console.log(err);
      });
  };

  // Delete row code
  handleDeleteCloseModal = () => {
    this.setState({
      isDeleteAlertPopupOpen: false,
    });
  };

  handleDeleteRows = async () => {

    let accessToken = this.props.reduxState?.userTokens?.accessToken
    console.log(accessToken, '<------This is the data😊😊😊😊😊😊😊😊😊😊😊😊')
    let ticket_id = localStorage.getItem("ticketId");

    try {
      await axios.delete(`${BASE_URL}center/deleteTickets/${ticket_id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      });
      this.handleDeleteCloseModal();
      this.showDialog(true, "success", "Ticket Delete successfully");
        this.getAllTickets();
    } catch (error) {
      console.log(error);
    }
  };

  //   Rebook Ticket
  handleRebookOpen = (rebookTicketId) => {
    this.setState({ rebookTicketId });
    this.setState({ openRebook: true });
  };
  handleRebookClose = () => this.setState({ openRebook: false });

  handleChangeTechnician = (technician_obj_id) => {
    if (!technician_obj_id) {
      return;
    }

    this.setState({ isTechnicianAssigning: true });
    let payload = {
      ticket_obj_id: this.state.ticketDetailsForAssignTechnician._id,
      technician_obj_id: technician_obj_id,
    };

    new CenterApi()
      .changeTechnician(payload)
      .then((res) => {
        this.handleCloseAssignTechnicianPopup();
        this.setState({ isTechnicianAssigning: false });
        this.getAllTickets();
      })
      .catch((err) => {
        this.setState({ isTechnicianAssigning: false });

        console.log(err);
      });
  };

  getFormattedAddress = (addressObject) => {
    let addressLine =
      addressObject?.house_number +
      ", " +
      addressObject?.locality +
      ", " +
      addressObject?.city +
      ", " +
      addressObject?.state;
    return addressLine;
  };

  getParsedData = (data) => {
    let parsedData = data?.map((item) => {
      return {
        center_id:
          item?.assigned_ids?.assigned_center_id?.qr_details?.qr_id ?? "-",
        service_required: item?.service_provided_for?.service_name,
        name: item?.personal_details?.name,
        qrStatus: item?.qr_details?.qrStatus,
        phone: item?.personal_details?.primary_phone?.mobile_number,
        address: this.getFormattedAddress(item?.address_details),
        pincode: item?.address_details?.pincode,
        createdAt: new Date(item?.createdAt).toLocaleDateString("en-us", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
        tstatus:
          item?.ticket_status == "PENDING" &&
          item?.broadcast_status == "matched_in_same_center" &&
          item?.assigned_ids?.assigned_technician_id
            ? "Technician Assigned"
            : item?.ticket_status == "PENDING" &&
              item?.broadcast_status == "matched_in_same_center" &&
              !item?.assigned_ids?.assigned_technician_id
            ? "Technician Not Assigned"
            : item?.ticket_status == "ACCEPTED" &&
              item?.assigned_ids?.assigned_technician_id
            ? "Technician Assigned"
            : item?.ticket_status == "ACCEPTED" &&
              !item?.assigned_ids?.assigned_technician_id
            ? "Technician Not Assigned"
            : item?.ticket_status,
        action: item,
        remarks: item,
      };
    });

    return parsedData;
  };

  getAllTickets() {
    new CenterApi()
      .getAllTickets()
      .then((res) => {
        let parsedData = [];
        if (res?.data?.length) {
          parsedData = this.getParsedData(res.data);
        }
        // console.log(parsedData, "parsedData")

        this.setState({
          ticketsList: parsedData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { toast: snackBarValues } = this.state;

    return (
      <div>
        <RebookModal
          isOpen={this.state.openRebook}
          handleRebookOpen={this.handleRebookOpen}
          handleRebookClose={this.handleRebookClose}
          Ticket_id={this.state.rebookTicketId}
        />
        {snackBarValues.open && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBarValues.open}
            autoHideDuration={snackBarValues.duration}
            onClose={this.closeDialog}
          >
            <Alert
              onClose={this.closeDialog}
              severity={snackBarValues.type}
              sx={{ width: "100%" }}
            >
              {snackBarValues.msg}
            </Alert>
          </Snackbar>
        )}

        {this.state.isDeleteAlertPopupOpen && (
          <DeleteAlertDialogModal
            openAlert={this.state.isDeleteAlertPopupOpen}
            handleAlertClose={this.handleDeleteCloseModal}
            handleDeleteTicket={this.handleDeleteRows}
            Heading={this.state.deleteHeading}
          />
        )}

        {this.state.isAssignTechnicianPopupOpen && (
          <AssignTechnicianPopup
            open={this.state.isAssignTechnicianPopupOpen}
            handleClose={this.handleCloseAssignTechnicianPopup}
            technicianList={this.state.technicianList}
            isForChange={this.state.isForChange}
            handleAssignTechnician={this.handleAssignTechnician}
            handleChangeTechnician={this.handleChangeTechnician}
            isTechnicianAssigning={this.state.isTechnicianAssigning}
          />
        )}

        <MUIDataTable
          title={"Tickets"}
          data={this.state.ticketsList}
          columns={this.state.columns}
          options={options}
        />

        {this.state.isCloseTicketRemarksModalOpen && (
          <CloseTicketRemarksModal
            open={this.state.isCloseTicketRemarksModalOpen}
            handleClose={this.handleCloseCloseTicketRemarksModal}
            handleCloseTicket={this.handleCloseTicket}
            handleRevisitCloseTicket={this.handleRevisitCloseTicket}
            getSingleCenterTicketData={this.state.getSingleTicketData?.results}
            handleCenterRevisitCloseTicket={this.handleCenterRevisitCloseTicket}
          />
        )}

{console.log("⬇️",this.props.reduxState?.userTokens?.accessToken)}
        {this.state.isOpenAddRemarksPopup && this.state.ticketObj && (
          <AddRemarksPopup
            open={this.state.isOpenAddRemarksPopup}
            ticketObj={this.state.ticketObj}
            handleClose={this.closeAddRemarksPopup}
          />
        )}
      </div>
    );
  }
}

// export default Tickets;

const mapStateToProps = (state) => ({
  reduxState: state.userReducer,
});
export default connect(mapStateToProps, null)(withRouter(Tickets));
