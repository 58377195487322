import React, { Component, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import "../../Styles/style.css";
import { Add as AddIcon } from "@mui/icons-material";
import {
  Alert,
  InputAdornment,
  Snackbar,
  Typography,
  Chip,
  IconButton,
} from "@mui/material";

import CenterRegistrationApi from "../../components/centerRegistration/Api/api";
import CenterComponentApi from "../../components/centerComponent/api/api";
import { IndianStates } from "../../assets/IndianStates";
import AdminApi from "../../components/AdminApi/Api/api";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Input = styled("input")({
  display: "none",
});

const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "10px",
  },
  addButton: {
    position: "absolute",
    top: "75px",
    right: "70px",
  },
};

class AddTechnician extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientName: "",
      productName: "",
      productCategory: "",
      specificRequirement: "",
      price: "",
      officialEmail: "",
      clientPocPersonName: "",
      clientPocPersonDesignation: "",
      clientPocPhone: "",
      addressLine: "",
      city: "",
      state: "",
      pincode: "",
      country: "INDIA",
      gstNumber: "",
      aadhaarNumber: "",
      panNumber: "",
      bankName: "",
      accountNumber: "",
      branch: "",
      ifscCode: "",
      tinNumber: "",
      isFormVisible: false,
      selectedCategories: [],

      toast: {
        open: false,
        msg: "",
        duration: 5000,
        type: "",
      },
      isFormValid: false,
      servicesList: [],

      isRegistering: false,
      allowedBrands: [
        "AC",
        "LED",
        "Refrigerator",
        "Washing_Machine",
        "Microwave",
        "Geyser",
      ],
      allowedRequirement: ["Installation", "Demo", "Repair", "Pms"],
    };
    this.toggleFormVisibility = this.toggleFormVisibility.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangee = this.handleChangee.bind(this);
    this.handleAddCategory = this.handleAddCategory.bind(this);
    this.handleRemoveCategory = this.handleRemoveCategory.bind(this);
  }

  componentDidMount() {}

  showDialog = (open, type, msg) => {
    this.setState({
      toast: {
        open: open,
        msg: msg,
        duration: 5000,
        type: type,
      },
    });
  };

  closeDialog = (open = false) => {
    this.setState({
      toast: {
        open: open,
        msg: "",
        duration: 5000,
        type: "",
      },
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    if (name == "pincode") {
      if (value.length <= 6) {
        this.setState({
          pincode: value,
        });
      }

      return;
    }

    this.setState(
      {
        [name]: value,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  handleChangee = (event) => {
    this.setState({ productCategory: event.target.value });
  };
  handleAddCategory() {
    const { productCategory, selectedCategories } = this.state;
    if (productCategory.trim() !== "") {
      this.setState({
        selectedCategories: [...selectedCategories, productCategory.trim()],
        productCategory: "", // Clear input after adding category
      });
    }
  }

  handleRemoveCategory(index) {
    const { selectedCategories } = this.state;
    const newCategories = [...selectedCategories];
    newCategories.splice(index, 1);
    this.setState({
      selectedCategories: newCategories,
    });
  }

  handleRegister = () => {
    const {
      clientName,
      productName,
      productCategory,
      specificRequirement,
      price,
      officialEmail,
      clientPocPersonName,
      clientPocPersonDesignation,
      clientPocPhone,
      addressLine,
      city,
      state,
      pincode,
      country,
      gstNumber,
      aadhaarNumber,
      panNumber,
      bankName,
      accountNumber,
      ifscCode,
      tinNumber,
      branch,
      selectedCategories,
    } = this.state;

    if (
      !(
        clientName &&
        productName &&
        // productCategory &&
        specificRequirement &&
        price &&
        officialEmail &&
        clientPocPersonName &&
        clientPocPersonDesignation &&
        clientPocPhone &&
        addressLine &&
        city &&
        state &&
        pincode &&
        country &&
        gstNumber &&
        aadhaarNumber &&
        panNumber &&
        bankName &&
        accountNumber &&
        ifscCode &&
        tinNumber &&
        branch
      )
    ) {
      this.setState({
        isFormValid: true,
      });

      this.showDialog(true, "error", "Please fill in all required details");
      return;
    }

    this.setState({
      isRegistering: true,
    });

    let payload = {
      client_name: clientName,
      product_name: productName,
      // product_category: productCategory,
      specific_requirement: specificRequirement,
      price: price,
      official_email: officialEmail,
      client_poc: {
        person_name: clientPocPersonName,
        person_designation: clientPocPersonDesignation,
        phone: {
          country_code: "+91",
          mobile_number: clientPocPhone,
        },
      },
      address_details: {
        address_line: addressLine,
        city: city,
        state: state,
        pincode: pincode,
        country: country,
      },
      gst_number: gstNumber,
      aadhaar_number: aadhaarNumber,
      pan_number: panNumber,
      bank_details:{
        bank_name: bankName,
        bank_account_number: accountNumber,
        ifsc_code: ifscCode,
        branch: branch,
      },
      tin_number: tinNumber,
      category: selectedCategories,
    };

    new AdminApi()
      .addClient(payload)
      .then((res) => {
        this.showDialog(true, "success", "Client Created successfully");

        this.setState({
          isRegistering: false,
        });
        this.componentDidMount();
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isRegistering: false,
        });
        this.showDialog(true, "error", err?.response?.data?.message);
      });
  };

  toggleFormVisibility = () => {
    this.setState(
      (prevState) => ({
        isFormVisible: !prevState.isFormVisible,
      }),
      () => {
        if (this.state.isFormVisible) {
          // Scroll to the form
          const form = document.getElementById("add-client-form");
          form.scrollIntoView({ behavior: "smooth" });
        }
      }
    );
  };

  render() {
    const {
      toast: snackBarValues,
      isFormValid,
      clientName,
      productName,
      productCategory,
      specificRequirement,
      price,
      officialEmail,
      clientPocPersonName,
      clientPocPersonDesignation,
      clientPocPhone,
      addressLine,
      city,
      state,
      pincode,
      country,
      gstNumber,
      isFormVisible,
      aadhaarNumber,
      panNumber,
      bankName,
      accountNumber,
      branch,
      ifscCode,
      tinNumber,
      selectedCategories,
    } = this.state;
    return (
      <div>
        <Button
          variant="contained"
          onClick={this.toggleFormVisibility}
          style={stylesCss.addButton}
        >
          Add Client
        </Button>

        {snackBarValues.open && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBarValues.open}
            autoHideDuration={snackBarValues.duration}
            onClose={this.closeDialog}
          >
            <Alert
              onClose={this.closeDialog}
              severity={snackBarValues.type}
              sx={{ width: "100%" }}
            >
              {snackBarValues.msg}
            </Alert>
          </Snackbar>
        )}

        {isFormVisible && (
          <div id="add-client-form">
            <h2 className="text-align-center">Add a Client</h2>
            <Item>
              <Grid container>
                <Grid item style={stylesCss.paddingInnerGrids} md={13}>
                  <TextField
                    helperText="Please provide client's name"
                    id="demo-helper-text-misaligned"
                    label="Client Name"
                    style={stylesCss.inputBox}
                    name="clientName"
                    value={clientName}
                    onChange={this.handleChange}
                    error={isFormValid && !clientName}
                    required
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid style={stylesCss.paddingInnerGrids} md={12}>
                  <TextField
                    helperText="Please provide product name"
                    id="demo-helper-text-misaligned"
                    label="Product Name"
                    style={stylesCss.inputBox}
                    name="productName"
                    value={productName}
                    onChange={this.handleChange}
                    error={isFormValid && !productName}
                    required
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Box display="flex" sx={{ width: "100%" }} md={12}>
                  <Grid md={6} style={stylesCss.paddingInnerGrids}>
                    <FormControl
                      md={6}
                      style={stylesCss.inputBox}
                      sx={{ minWidth: 120 }}
                    >
                      <InputLabel id="demo-simple-select-helper-label">
                        Please provide product category
                      </InputLabel>
                      <TextField
                        id="product-category-input"
                        type="text"
                        value={productCategory}
                        onChange={this.handleChangee}
                        className={
                          isFormValid && !productCategory ? "error" : ""
                        }
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={this.handleAddCategory}
                                edge="end"
                                color="primary"
                              >
                                <AddIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: {
                            marginTop: "8px",
                            padding: "8px",
                            width: "100%",
                          },
                        }}
                      />
                    </FormControl>
                    {selectedCategories.map((category, index) => (
                      <Chip
                        key={index}
                        label={category}
                        onDelete={() => this.handleRemoveCategory(index)}
                        style={{
                          margin: "4px",
                          backgroundColor: "#5CF1F5",
                          color: "black",
                          borderRadius: "16px",
                        }}
                      />
                    ))}
                  </Grid>

                  <Grid style={stylesCss.paddingInnerGrids} md={6}>
                    <FormControl
                      md={6}
                      style={stylesCss.inputBox}
                      sx={{ minWidth: 120 }}
                    >
                      <InputLabel id="demo-simple-select-helper-label">
                        Please provide specific Requirement
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Specific Requirement"
                        name="specificRequirement"
                        value={specificRequirement}
                        onChange={this.handleChange}
                        error={isFormValid && !specificRequirement}
                        required
                        sx={{ marginTop: "8px !important" }}
                      >
                        {this.state.allowedRequirement?.map((ite, indx) => (
                          <MenuItem
                            key={indx}
                            value={ite}
                            sx={{
                              display: "flex !important",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            {ite}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Box>

                <Grid item style={stylesCss.paddingInnerGrids} md={12}>
                  <TextField
                    helperText="Please provide price"
                    id="demo-helper-text-misaligned"
                    label="Price"
                    style={stylesCss.inputBox}
                    name="price"
                    value={price}
                    onChange={this.handleChange}
                    error={isFormValid && !price}
                    required
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid item style={stylesCss.paddingInnerGrids} md={12}>
                  <TextField
                    helperText="Please provide official email"
                    id="demo-helper-text-misaligned"
                    label="Official Email"
                    style={stylesCss.inputBox}
                    name="officialEmail"
                    value={officialEmail}
                    onChange={this.handleChange}
                    error={isFormValid && !officialEmail}
                    type="email"
                    required
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid item style={stylesCss.paddingInnerGrids} md={12}>
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Point of Contact Person Name"
                    style={stylesCss.inputBox}
                    name="clientPocPersonName"
                    value={clientPocPersonName}
                    onChange={this.handleChange}
                    error={isFormValid && !clientPocPersonName}
                    required
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid item style={stylesCss.paddingInnerGrids} md={12}>
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Point of contact person designation"
                    style={stylesCss.inputBox}
                    name="clientPocPersonDesignation"
                    value={clientPocPersonDesignation}
                    onChange={this.handleChange}
                    error={isFormValid && !clientPocPersonDesignation}
                    required
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid item style={stylesCss.paddingInnerGrids} md={6}>
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Point of contact phone number"
                    style={stylesCss.inputBox}
                    name="clientPocPhone"
                    value={clientPocPhone}
                    onChange={this.handleChange}
                    error={isFormValid && !clientPocPhone}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography>+91</Typography>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: 10 }}
                    required
                  />
                </Grid>

                <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Address"
                    style={stylesCss.inputBox}
                    name="addressLine"
                    value={addressLine}
                    onChange={this.handleChange}
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid
                  style={stylesCss.paddingInnerGrids}
                  item
                  md={6}
                  sx={{ marginTop: "9px" }}
                >
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="City"
                    style={stylesCss.inputBox}
                    name="city"
                    value={city}
                    onChange={this.handleChange}
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid style={stylesCss.paddingInnerGrids} md={6} item>
                  <FormControl
                    md={6}
                    style={stylesCss.inputBox}
                    sx={{ minWidth: 120 }}
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      State
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="State"
                      name="state"
                      onChange={this.handleChange}
                      value={state}
                      sx={{ marginTop: "8px !important" }}
                    >
                      {IndianStates?.map((ite, indx) => (
                        <MenuItem
                          key={indx}
                          value={ite.value}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          {ite.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Service Area Pincode (main)"
                    style={stylesCss.inputBox}
                    name="pincode"
                    type="number"
                    value={pincode}
                    onChange={this.handleChange}
                    error={isFormValid && !pincode}
                    required
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Country"
                    style={stylesCss.inputBox}
                    name="country"
                    value={country}
                    onChange={this.handleChange}
                    disabled
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Aadhar Number"
                    style={stylesCss.inputBox}
                    name="aadhaarNumber"
                    value={aadhaarNumber}
                    onChange={this.handleChange}
                    error={isFormValid && !aadhaarNumber}
                    required
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Pan Number"
                    style={stylesCss.inputBox}
                    name="panNumber"
                    value={panNumber}
                    onChange={this.handleChange}
                    error={isFormValid && !panNumber}
                    required
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="GST Number"
                    style={stylesCss.inputBox}
                    name="gstNumber"
                    value={gstNumber}
                    onChange={this.handleChange}
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Tin Number"
                    style={stylesCss.inputBox}
                    name="tinNumber"
                    value={tinNumber}
                    onChange={this.handleChange}
                    error={isFormValid && !tinNumber}
                    required
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Account Number"
                    style={stylesCss.inputBox}
                    name="accountNumber"
                    value={accountNumber}
                    onChange={this.handleChange}
                    error={isFormValid && !accountNumber}
                    required
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Bank Name"
                    style={stylesCss.inputBox}
                    name="bankName"
                    value={bankName}
                    onChange={this.handleChange}
                    error={isFormValid && !bankName}
                    required
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Branch"
                    style={stylesCss.inputBox}
                    name="branch"
                    value={branch}
                    onChange={this.handleChange}
                    error={isFormValid && !branch}
                    required
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>

                <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="IFSC Code"
                    style={stylesCss.inputBox}
                    name="ifscCode"
                    value={ifscCode}
                    onChange={this.handleChange}
                    error={isFormValid && !ifscCode}
                    required
                    InputProps={{ style: { paddingLeft: "10px" } }}
                  />
                </Grid>
               

                <Grid item md={6} align="center">
                  <Button
                    variant="contained"
                    onClick={this.handleRegister}
                    style={{
                      height: "50px",
                      width: "150px",
                      fontWeight: "bolder",
                      marginTop: "20px",
                      fontSize: "20px",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Item>
          </div>
        )}
      </div>
    );
  }
}

export default AddTechnician;
