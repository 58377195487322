import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios'; // Import Axios for making HTTP requests
import { BASE_URL } from "../../config/config"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const RemarksPopup = ({ open, handleClose, onSaveRemarks, selectedTicketId, username , initialRemarks , processedname}) => {
  const [remarks, setRemarks] = useState('');

  const handleChange = (event) => {
    setRemarks(event.target.value);
  };
  

  const handleSaveRemarks = () => {
    // Make an API call to save remarks to the backend
    axios.post(`${BASE_URL}admin/onboarding-team-remarks`, {
      onboarding_team_remarks: remarks,
      onboarding_team_name: username,
      ticket_obj_id: selectedTicketId,
    })
    .then(response => {
      console.log('Remarks saved successfully:', response.data);
      // Optionally, you may want to clear the input field after successful save
      setRemarks('');
      // Close the popup
      handleClose();
    })
    .catch(error => {
      console.error('Error saving remarks:', error);
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Add Remarks
        </Typography>
        <br />
        <br />
        <textarea value={remarks} onChange={handleChange} rows={4} cols={40} />
        <br />
        <br />
        <Button onClick={handleSaveRemarks} variant="contained">
          Save Remarks
        </Button>
        <Box sx={{ marginTop: '10px', padding: '10px', border: '1px solid black', borderRadius: '5px' }}>
    <Typography variant="body2" component="div">
       {initialRemarks ? initialRemarks : 'No remarks available'} - <span style={{ fontWeight: 'bold', color: 'red' }}>{processedname}</span>
    </Typography>
</Box>
      </Box>
    </Modal>
  );
}

export default RemarksPopup;
