import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import Tooltip from '@material-ui/core/Tooltip';
import Rating from '@mui/material/Rating';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function ChildModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button onClick={handleOpen}>Open Child Modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 200 }}>
          <h2 id="child-modal-title">Text in a child modal</h2>
          <p id="child-modal-description">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          </p>
          <Button onClick={handleClose}>Close Child Modal</Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default function TechnicianInfoModal({techDetails,openTechModal,handleClose,handleOpen}) {
    const techDetail = techDetails?.assigned_ids?.assigned_technician_id
    console.log("techDetails",techDetail) //techDetail?.personal_details?.name
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };



  return (
    <div>
        {/* <Tooltip title="Technician Details">
        <Button onClick={handleOpen}>
         <InfoIcon style={{color:'orange'}} />
      </Button>
        </Tooltip> */}
     
        
      <Modal
        open={openTechModal}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 340 }}>
          <h2 style={{textAlign:'center'}} id="parent-modal-title">Technician Detail</h2>
         
          <div style={{display:'flex',justifyContent:'center'}} >
            <img style={{width:'190px',height:'190px'}} src={techDetail?.personal_details?.profile_picture} alt="Profile Image" />
          </div>
          <h6 style={{textAlign:'center',marginTop:'15px',color:'black'}}>Name :- {techDetail?.personal_details?.name} <VerifiedUserIcon style={{color:'green',fontSize:'25px'}} /> </h6>
          {/* techDetail?.count_details?.technician_rating */}
          <h6 style={{display:'flex',alignItems:'center',justifyContent:'center',color:'black'}}>Rating :- <Rating  name="read-only" value={techDetail?.count_details?.technician_rating} readOnly size="small"  /></h6>
        </Box>
      </Modal>
    </div>
  );
}