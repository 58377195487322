
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import './Home.css';
import Person2Icon from '@mui/icons-material/Person2';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import EngineeringIcon from '@mui/icons-material/Engineering';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import FeedbackIcon from '@mui/icons-material/Feedback';
import PeopleIcon from '@mui/icons-material/People';
import CellTowerIcon from '@mui/icons-material/CellTower';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaymentIcon from '@mui/icons-material/Payment';
import ArticleIcon from '@mui/icons-material/Article';
import { RightCircleFilled } from "@ant-design/icons";
import BuildIcon from '@mui/icons-material/Build';
//import Ticket from "../../pages/Ticket";
const drawerItemsResponsive = [
  // ... (other items)
  {
    name: "Service-Category",
    path: "admin-dashboard",
    icon: <BuildIcon style={{ fontSize: 70, marginRight: 10,marginTop:30 }} />,
    className: "service",
  },
  {
    name: "All Tickets",
    path: "/Tickets",
  icon:  <PersonAddIcon style={{ fontSize: 70, marginRight: 10,marginTop:30 }} />,
    className: "all-tickets",
    
  },
  {
    name: "Technicians",
    path: "/admin-technician",
    icon: <EditIcon style={{ fontSize: 70, marginRight: 10,marginTop:30 }} />,
    className: "technicians",
  },
  // {
  //   name: "Add Ticket",
  //   path: "/admin-ticket",
  //   icon: <RequestPageIcon style={{ fontSize: 70, marginTop:30}}/>,
  //   className: "add-ticket",
  // },
  // {
  //   name: "Add technician",
  //   path: "/add-technician",
  //   icon: <Person2Icon style={{ fontSize: 70, marginRight: 10,marginTop:30 }}/>,
  //   className: "Addtech",
  // },
  // {
  //       name: "FeedBack",
  //       path: "/admin-feedBack",
  //       icon: <EngineeringIcon style={{ fontSize: 70, marginRight: 10,marginTop:30 }}/>,
  //       className: "Feedback",
  //     },
      {
        name: "Clients",
        path: "/admin-clients",
        icon: <NoteAddIcon style={{ fontSize: 70, marginRight: 10 ,marginTop:30}}/>,
        className: "client",
      },
      // {
      //   name: "BroadCasted List",
      //   path: "/broad-casted-list",
      //   icon: <FeedbackIcon style={{ fontSize: 70, marginRight: 10 ,marginTop:30}}/>,
      //   className: "broadcast",
      // },
      // {
      //   name: "Roles",
      //   path: "/roles",
      //   icon: <PeopleIcon style={{ fontSize: 70, marginRight: 10,marginTop:30 }}/>,
      //   className: "role",
      // },
      {
        name: "Users",
        path: "/users",
        icon: <CellTowerIcon style={{ fontSize: 70, marginRight: 10,marginTop:30 }}/>,
        className: "user",
      },
      // {
      //   name: "Users Data",
      //   path: "/usersdetails",
      //   icon: <AdminPanelSettingsIcon style={{ fontSize: 70, marginRight: 10,marginTop:30 }}/>,
      //   className: "userdata",
      // },
      {
        name: "Center Onboarders",
        path: "/admin-center-onboarders",
        icon: <FolderSharedIcon style={{ fontSize: 70, marginRight: 10,marginTop:30 }}/>,
        className: "onboard",
      },
      // {
      //   name: "Wallet Transaction",
      //   path: "/wallet",
      //   icon: <AccountBalanceWalletIcon style={{ fontSize: 70, marginRight: 10,marginTop:30 }}/>,
      //   className: "wallet",
      // },
      // {
      //   name: "Payment Transaction",
      //   path: "/payment",
      //   icon: <PaymentIcon style={{ fontSize: 70, marginRight: 10,marginTop:30 }}/>,
      //   className: "payment",
      // },
  {
    name: 'Centers',
    path: '/admin-centers',
    icon: <SupervisorAccountIcon style={{ fontSize: 70, marginRight: 10,marginTop:30 }}/>,
    className:"Centre",
    children: [
      {
        name: 'All Centers',
        path: '/admin-centers',
        icon: <SupervisorAccountIcon />,
        className: "all-centers",
      },
      {
        name: 'Edit center',
        path: '/edit-center',
        icon: <EditIcon />,
        className: "edit-center",
      },
      {
        name: 'Add center',
        path: '/add-center',
        icon: <PersonAddIcon />,
        className: "add-center",
      }
    ]
  },
];

const Home = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
 // const [ticketCount, setTicketCount] = useState(0); 
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    justifyContent: "space-between",
   
  };

//   useEffect(() => {
//     // Call the getAllTickets method and pass a callback to set the ticket count
//     getAllTickets((count) => {
//       setTicketCount(count);
//     });
//   }, []); 

  const getClassName = (item) => {
    return item.className ? `box ${item.className}` : 'box';
  };
  return (
    <div className="home-container">
      <h1 style={{ color: 'black' , padding:"20px"}}>Welcome to Admin Panel Home</h1>
      <div style={containerStyle}> 
        {drawerItemsResponsive.map((item, index) => (
          <div key={index}>
            {item.children ? (
              <div onClick={toggleDropdown} className={getClassName(item)}>
                <div className="box-content">
                  <h2>{item.name}</h2>
                  {isDropdownOpen && (
                    <div>
                      {item.children.map((childItem, childIndex) => (
                        <Link key={childIndex} to={childItem.path}>
                          <div className={getClassName(childItem)}>
                            <h2>{childItem.name}</h2>
                          </div>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
                {item.icon && <div className="box-icon">{item.icon}</div>} {/* Display the icon if it exists */}
              </div>
            ) : (
              <Link to={item.path}>
                <div className={getClassName(item)}>
                  <div className="box-content">
                    <h2>{item.name}</h2>
                  </div>
                  {item.icon && <div className="box-icon">{item.icon}</div>} {/* Display the icon if it exists */}
                </div>
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;