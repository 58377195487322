import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import AdminApiModule from "./Api/api";
import { BASE_URL } from "../config/config";
import { Field, Formik, useFormik } from "formik";
import * as Yup from "yup"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";


const PUNCH_TICKET_API = `${BASE_URL}admin/update-punch-ticket`;


const TicketDocument = () => {
  const [isSerialNumberExists, setIsSerialNumberExists] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const imageUrls = location.state?.imageUrls || [];
 
  let ticketId=localStorage.getItem("ticketId")
  console.log("------====",ticketId)
  const [textFieldValue, setTextFieldValue] = useState("");
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [textFieldWidth, setTextFieldWidth] = useState("200px"); // Default width
  const [suggestedSerialNumbers, setSuggestedSerialNumbers] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const globalState = useSelector((state) => state.AdminReducer)

  console.log(globalState, "global state")

  const adminApi = new AdminApiModule();

  const handleChange = async (e) => {
    setTextFieldValue(e.target.value);
    fetchSuggestedSerialNumbers(e.target.value);

  };


  const handleAccept = async () => {
    try {
      localStorage.setItem("ticketId", ticketId);
      console.log("Text field value submitted:", textFieldValue);
      const isExists = await checkSerialNumberExists(textFieldValue);
      setIsSerialNumberExists(isExists);

      if (!isExists) {
        setVerificationStatus("verified");
        await updatePunchTicket({ serialNumber: textFieldValue, remarks: remarksValue, status: 'approved' }, ticketId);
      }
    } catch (error) {
      console.error("Error updating punch ticket:", error);
    }
  };

  const checkSerialNumberExists = async (serialNumber) => {
    try {
      const response = await adminApi.checkSerialNumberExists(serialNumber);
      return response.data.exists;
    } catch (error) {
      console.error("Error checking serial number existence:", error);
      return false;
    }
  };

  const [remarksValue, setRemarksValue] = useState("");

  const handleRemarksChange = (e) => {
    setRemarksValue(e.target.value);
  };

  const updatePunchTicket = async (value, ticketId) => {
    debugger
    let ticket_id=localStorage.getItem("ticketId")
    console.log("------====",ticket_id)
    try {
      const response = await axios.post(PUNCH_TICKET_API, {
        ticketId: ticketId,
        value: value,
      
      });
      console.log("Punch Ticket API response:", response.data);
    } catch (error) {
      console.error("Error updating punch ticket:", error);
    }
  };

  const fetchSuggestedSerialNumbers = async (value) => {
    try {
      const response = await adminApi.getSerialNumbers(value, ticketId);
      if (response.status === 200) {
        setSuggestedSerialNumbers(response.data);
        setNoResultsMessage("Found");
      } else if (response.status === 404) {
        setSuggestedSerialNumbers([]);
      } else {
        console.error(`Unexpected status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching suggested serial numbers:", error);
    }
  };

  useEffect(() => {
    const img = new Image();
    img.src = imageUrls[0]; // Assuming you want the width of the first image
    img.onload = () => {
      console.log("Image loaded");
      setTextFieldWidth(`${img.width}px`);
    };
  }, [imageUrls]);

  const handleGoBack = () => {
    history.goBack(); // Go back to the previous page
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    serialNumber: Yup.string().required('Serial Number is required'),
    remark: Yup.string().required('Remark is required'),
    ticket_status: Yup.string().required('Ticket status is required'),
  });

  const initialValues = {
    serialNumber: '',
    remark: '',
    ticket_status: ''
  };

  const onSubmit = async (values,ticketId) => {
  try {
    const data = {
      "ticket_status": values.ticket_status,
      "ticket_obj_id": ticketId,
      "punch_serialnumber": values.serialNumber,
      "admin_remarks": values.remark,
      "approved_by": globalState?.adminDetails?.user_name
    };
console.log("===========",data)
    // Send request to update punch ticket status
    const response = await axios.post(PUNCH_TICKET_API, data);

    // Handle response accordingly
    console.log("Punch Ticket API response:", response.data);

    // Check if the status was updated successfully
    if (response.data.success === 1) {
      // Show success message
      toast.success(response.data.message);
      // Optionally, you can update local state or perform any other actions upon successful update
    } else {
      // Show error message
      toast.error(response.data.message);
    }
  } catch (error) {
    // Handle errors
    console.error("Error updating punch ticket:", error);
    toast.error("Error updating punch ticket. Please try again later.");
  }
};


const formik = useFormik({
  initialValues,
  validationSchema,
  onSubmit: (values) => onSubmit(values, ticketId) // Include ticketId in the onSubmit call
});
  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100vh", textAlign: "center" }}>

      {/* Go Back button with back arrow icon */}
      <Button
        color="primary"
        fontWeight="bold"
        onClick={handleGoBack}
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          padding: "10px",
          background: "#ADD8E6",
          display: "flex",
          alignItems: "center", // Align items vertically in the center
          gap: "8px", // Set gap between icon and text
        }}
      >
        <ArrowBackIcon /> Go Back
      </Button>

      {/* Rest of your component content */}

      <div>
        <h1>Ticket Document</h1>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          {imageUrls.map((url, index) => (
            <div key={index} style={{ marginBottom: "10px", marginRight: "10px" }}>
              <img src={url} alt={`Image ${index}`} style={{ maxWidth: "80%", maxHeight: "300px", height: "auto" }} />
            </div>
          ))}
        </div>
      </div>

      {/* Punch Serial Number Input Field */}
      <TextField
        type="text"
        label="Enter Serial number"
        fullWidth
        style={{
          margin: "10px 0",
          fontSize: "18px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          padding: "6px",
          width: "300px",
          height: "100px" // Adjust the width as needed
        }}
        name="serialNumber"
        id="serialNo"
        value={formik.values.serialNumber}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Punch Serial Number"
      />

      {formik.errors.serialNumber && <div style={{ color: "red" }}>{formik.errors.serialNumber}</div>}
      {isSerialNumberExists && <div style={{ color: "red" }}>Serial Number already exists.</div>}
      <FormControl>

        <select
          // as={Select}
          label="select"
          id="ticket_status"
          name="ticket_status"
          placeholder="Select status"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.ticket_status}
          style={{ width: "300px", marginTop: "10px", marginBottom: "10px", padding: "8px" }}
        >

          <option value="">Select Status</option>
          <option value="VERIFIED">VERIFIED</option>
          <option value="ON_HOLD">ON HOLD</option>
          <option value="REJECT">REJECT</option>
        </select>
      </FormControl>

      {formik.values.ticket_status === "ON_HOLD" || formik.values.ticket_status === "REJECT" ? (
        <TextField
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          label="Remarks"
          placeholder="Enter your remark here"
          style={{
            margin: "20px 0",
            fontSize: "18px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "6px",
            width: "300px",
          }}
          name="remark"
          id="remark"
          value={formik.values.remark}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />

      ) : null}
      {/* Accept and Reject Buttons */}
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem" }}>
        <Button
          color="primary"
          type="submit"
         // onClick={handleAccept}
          style={{
            margin: "20px 0",
            padding: "15px 30px",
            fontSize: "18px",
            borderRadius: "8px",
            background: "blue",
            color: "#fff",
            fontWeight: "bold",
            cursor: "pointer",
            transition: "background 0.3s",
          }}
        >
          Submit
        </Button>
       
      </div>

      {verificationStatus && <p style={{ fontSize: "16px" }}>Verification Result: {verificationStatus}</p>}
    </form>
  );
};

export default TicketDocument;