import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Chip } from "@material-ui/core";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import BasicDateRangePicker from "../../../components/common/datepicker";
import ConsumerPaymentTable from "./Table/ConsumerPayment";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

import { BASE_URL } from "../../../components/config/config";

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          backgroundColor: "red",
        },
        indicator: {
          backgroundColor: "red", // Replace with your desired color
        },
      },
    },
  },
});

const CustomTab = withStyles({
  root: {
    textTransform: "none",
    color: "white",
    fontWeight: "500",
    fontSize: "18px",
    textTransform: "capitalize",
    letterSpacing: "5px",
  },
})(Tab);

// Mui data table
const columns = [
  {
    name: "createdAt",
    label: "Transaction Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => moment(value).format("Do MMM, hh:ss A"),
    },
  },
  {
    name: "order_id",
    label: "Order Id",
    customHeadLabelRender: () => (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography align="center">Order Id</Typography>
      </div>
    ),
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => {
        return value == "Joining Bonus" ? (
          <Chip label={value} style={{ background: "#8FD1AD" }} />
        ) : (
          <Chip label={value} style={{ background: "#F5F0BB" }} />
        );
      },
    },
  },
  {
    name: "payment_for",
    label: "Payment For",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return value || "--"
      }
    },
  },
  {
    name: "payment_status",
    label: "Payment Status",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "receipt",
    label: "Receipt",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "sumPriceToPay",
    label: "Price Paid",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => {
        return value / 100;
      },
    },
  },
  {
    name: "ticket_obj_id",
    label: "ticket_obj_id",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => {
        return (
          <span style={{ display: "flex", justifyContent: "center" }}>
            {value?.ticket_id || "-"}
          </span>
        );
      },
    },
  },
];

// Custom filter style
const options = {
  selectableRows: "none",
  responsive: "standard",
  filterType: "checkbox", // or 'dropdown'
  filter: true,
  textLabels: {
    filter: "Filter",
    reset: "Reset",
    close: "Close",
  },
  customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
    <div style={{ padding: "20px" }}>
      <Button variant="contained" onClick={() => applyNewFilters()}>
        Apply
      </Button>
    </div>
  ),
  customFilterDialogFooterStyle: {
    borderTop: "1px solid #ccc",
  },
  customFilterDialogFooterPosition: "right", // or 'right'
  customFilterDialogWidth: "500px", // Set the desired width here
};

const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "2px",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function WalletHistory() {
  const globalState = useSelector((state) => state.AdminReducer);
  const [loader,setLoader]= React.useState(false)
  let accessToken = globalState?.userTokens?.accessToken;
  console.log(globalState, "global state");

  const [value, setValue] = React.useState(0);

  const refreshData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}admin/payment-transactions`, {
        headers: {
          Authorization: accessToken,
        },
      });
      setRows(response.data?.data);
    } catch (error) {
      console.log("Error fetching data:", error);
      return [];
    }
  };

  //  Date filter code start
  const onClickFilter = async (value) => {
    // this.startLoading();

    let payload = {
      start_date: value[0],
      end_date: value[1],
    };

    try {
      const response = await axios.get(
        `${BASE_URL}admin/payment-transactions`,
        {
          headers: {
            Authorization: accessToken,
          },
          params: payload,
        }
      );
      setRows(response.data?.data);
    } catch (error) {
      console.log("Error fetching data:", error);
      return [];
    }
  };

  // startLoading = () => {
  //   this.setState({ isLoading: true });
  // };

  // stopLoading = () => {
  //   this.setState({ isLoading: false });
  // };
  //  Date filter code End

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Table

  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    // Fetch data from the API and update the rows state
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoader(true); // Show the loader before making the API request
      const response = await axios.get(
        `${BASE_URL}admin/payment-transactions`,
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      
      setRows(response.data?.data);
    } catch (error) {
      console.log("Error fetching data:", error);
      return [];
    } finally{
      setLoader(false);
    }
  };

  return (
    <>

      {/* Backdrop Pre Loader  */}
      <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={loader}
 
>
  <CircularProgress color="inherit" />
</Backdrop>

      <ThemeProvider theme={theme}>
        <Typography variant="h4" mb={2} textAlign="center">
          Payment History
        </Typography>

        <Box sx={{ border: 1, borderColor: "divider", width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="white"
              aria-label="secondary tabs example"
              sx={{
                "& .MuiTabs-flexContainer": {
                  justifyContent: "space-around",
                  background: "#9DB2BF",
                },
                "& .MuiTabs-scroller": {
                  "&::before": {
                    content: '""',
                    display: "block",
                    flexShrink: 0,
                  },
                },
              }}
            >
              <CustomTab label=" Center" {...a11yProps(0)} />
              <CustomTab label=" Consumer" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {/* Table */}
            {/* Date filter code start from here */}

            <Box style={stylesCss.paddingInnerGrids}>
              <BasicDateRangePicker
                onClickFilter={onClickFilter} refreshData={refreshData}
                // refreshData={getAllTickets}
              />
            </Box>
            {/* END here */}
            <MUIDataTable data={rows} columns={columns} options={options} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ConsumerPaymentTable />
          </TabPanel>
        </Box>
      </ThemeProvider>
    </>
  );
}
