import axios from "axios";
import { BASE_URL } from "../../config/config";
import Pagination from '@mui/material/Pagination';
import { useHistory, useLocation, NavLink } from "react-router-dom"; 

class AdminApiModule {
  constructor() {
    this.base_url = BASE_URL;
  }

  getAllBroadCastedList() {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin/get-all-broadCasted-ticket`,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  closeTicket(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/close-ticket`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  changeTechnician(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        data: data,
        url: `${this.base_url}admin/assigning-ticket-technician`,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateTicket(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/update-ticket`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  onSiteImage (data){
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}technicianApp/upload-ticket-onsite-picture/TIN202351710`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createTicket(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}center/public-ticket-by-superadmin`,
        // url: `${this.base_url}admin/create-ticket`,center/public-ticket-by-superadmin
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // Technician list api
  getAllTechnician(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin/get-all-technician`,
        params: payload,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  assignTechnician(data) {
    const access_token = localStorage.getItem("access_token");
    console.log("access_token", access_token)
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        data: data,
        url: `${this.base_url}admin/assigning-ticket-technician`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateCenterVerifyStatus(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/Set-Verification-Status-Of-center-And-Technician`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllFeedBack() {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin/get-all-feedBacks`,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllCenters() {
        return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin/get-all-centers`,
        // params: payload,
      })
        .then((res) => {
                    resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  login(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/login`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllClosedTickets(payload,skip,currentsPage) {
    console.log("currentsPage",currentsPage)
    console.log("skip",skip)
    
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin/get-all-close-ticket`,
        params: payload,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  getAllErickshawTickets(payload,skip,currentsPage) {
    console.log("currentsPage",currentsPage)
    console.log("skip",skip)
    
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}technicianApp/get-all-erikshaw-ticket`,
        params: payload,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  getAllPendingTickets(payload,skip,currentsPage) {
    console.log("currentsPage",currentsPage)
    console.log("skip",skip)
    
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin/get-all-pending-ticket`,
        params: payload,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  getAllCenterTickets(payload,skip,currentsPage) {
    console.log("currentsPage",currentsPage)
    console.log("skip",skip)
    
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin/get-centerCreated-ticket`,
        params: payload,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  getAllTickets(payload,skip,currentsPage) {
    const access_token = localStorage.getItem("access_token");
    
    console.log("currentsPage",currentsPage)
    console.log("skip",skip)
    
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin/get-all-ticket`,
        params: payload,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getNoSerMatchAllTickets(payload,skip,currentsPage) {
    console.log("currentsPage",currentsPage)
    console.log("skip",skip)
    
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin/get-all-NoServices-ticket`,
        params: payload,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  
  getAllTicket(skips,currentsPage) {
    console.log("currentsPage",currentsPage)
    console.log("skip",skip)
    let skip = skips;
    let limit = currentsPage
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin/get-all-ticket?${skip}=1&${limit}=5`,
       
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  getAllClientTicket(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: `${this.base_url}admin/get-client-ticket`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  getEveryClientTicket(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: `${this.base_url}admin/get-all-B2B-ticket`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // Get single ticket from Param
  getSingleTicket(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin/get-single-ticket`,
        params: payload,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  adminBroadCast(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/admin-broadcast`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updatePincode(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/update-ticket`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllTechnician(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin/get-all-technician/`,
        params: payload,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addService(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/create-services`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteService(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/delete-service`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateService(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/update-service`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateServiceStatus(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/update-service-status`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  updateuserStatus(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        url: `${this.base_url}admin/update-user-status`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addClient(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/add-client`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllClients() {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin/get-all-clients/`,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteClient(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/delete-client`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  adminUpdateCenter(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        url: `${this.base_url}admin/update-center`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  adminUpdateTechnician(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/update-technician`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllAvailableRoles(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin/get-all-available-roles`,
        params: payload,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  adminAddRole(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/add-role`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  adminUpdateRole(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        url: `${this.base_url}admin/update-role`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllUsers(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin/get-all-users`,
        params: payload,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  adminCreateUser(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/create-user`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  adminRemoveUser(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/remove-user`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllCenterOnBoarder(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${this.base_url}admin//get-all-onboarder`,
        params: payload,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  adminCreateCenterOnBoarder(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/create-centerOnBoarder`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  adminUpdateCenterOnBoarder(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/update-centerOnBoarder`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  uploadTicketsCsv(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/upload-csv-for-ticket`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  rebookYourTicket(data) {
    return new Promise((resolve, reject) => {
      axios({
        method: "PUT",
        url: `${this.base_url}center/api/tickets/rebook`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  
  
  getSerialNumbers(data) {
    // Example URL: ${this.base_url}admin/update-punch-ticket?startsWith=${startsWith}
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${this.base_url}admin/update-punch-ticket`,
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  revisitYourTicket(data,ticketId) {
    console.log(data, '<------This is the data😊😊😊😊😊😊😊😊😊😊😊😊')
    console.log(ticketId, '<------This is the data😊😊😊😊😊😊😊😊😊😊😊😊')
    return new Promise((resolve, reject) => {
      axios({
        method: "PUT",
        url: `${this.base_url}center/tickets/${ticketId}/revisit`,
        // params: {
        //   ticketId: Ticketid,
        // },
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  
}

export default AdminApiModule;
