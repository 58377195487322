import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import { CardMedia } from '@mui/material';
import { Card } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    transition: "transform 1s ease-in-out",
    "&:hover": { transform: "scale3d(2, 2, 1)" },
  },
  cardHovered: {
    transform: "scale3d(1.05, 1.05, 1)",
  },
});

const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "10px",
  },
};
  

export default function CenterEditView({
  selectData,
  isVerificationPopupOpen,
  toggleVerificationDetailsPopup

}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    raised: false,
    shadow: 1,
  });

  console.log("select",selectData);
 console.log("isVerificationPopupOpen",isVerificationPopupOpen);
  return (
    <div>
      <Modal
            open={isVerificationPopupOpen}
            onClose={toggleVerificationDetailsPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      <Box sx={style}>
          <Typography id="modal-modal-title" variant="h8" component="h4">
            Aadhar Card Front Side
          </Typography>

          <Card
            sx={{ maxWidth: 345 }}
            style={{ marginBottom: "10px" }}
            className={classes.root}
            onMouseOver={() => setState({ raised: true, shadow: 3 })}
            onMouseOut={() => setState({ raised: false, shadow: 1 })}
            raised={state.raised}
            zDepth={state.shadow}
          >
            <CardMedia
              component="img"
              height="140"
              image={selectData?.document_details?.aadhar_card_document?.front_side}
              alt="Image Not Available"
            />{" "}
          </Card>
          {/* )} */}

          <Typography id="modal-modal-title" variant="h8" component="h4">
            Aadhar Card Back Side
          </Typography>

          <Card
            sx={{ maxWidth: 345 }}
            style={{ marginBottom: "10px" }}
            className={classes.root}
            onMouseOver={() => setState({ raised: true, shadow: 3 })}
            onMouseOut={() => setState({ raised: false, shadow: 1 })}
            raised={state.raised}
            zDepth={state.shadow}
          >
            <CardMedia
              component="img"
              height="140"
              image={selectData?.document_details?.aadhar_card_document?.back_side}
              alt="Image Not Available"
            />
          </Card>
          <Typography id="modal-modal-title" variant="h8" component="h4">
            Pan Card
          </Typography>

          <Card
            className={classes.root}
            onMouseOver={() => setState({ raised: true, shadow: 3 })}
            onMouseOut={() => setState({ raised: false, shadow: 1 })}
            raised={state.raised}
            zDepth={state.shadow}
          >
            <CardMedia
              component="img"
              height="140"
              image={selectData?.document_details?.pan_card_document}
              alt="Image Not Available"
            />
          </Card>

          <Grid style={stylesCss.paddingInnerGrids} item></Grid>
        </Box>
      </Modal>
    </div>
  );
}