import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import "../../Styles/style.css";
import "./Ticket.css"
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AdminApiModule from "../../components/AdminApi/Api/api";
import { withRouter } from "react-router";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import VerifiedIcon from '@mui/icons-material/Verified';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';
import {
  Alert,
  Avatar,
  InputAdornment,
  OutlinedInput,
  Snackbar,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core/styles";
import { Scrollbar } from "react-scrollbars-custom";
import CenterRegistrationApi from "../../components/centerRegistration/Api/api";
import CenterComponentApi from "../../components/centerComponent/api/api";
import { IndianStates } from "../../assets/IndianStates";
import { Diversity1, Label } from "@mui/icons-material";
import CopyButton from "../../components/ServicesStatus/ClipbaordButton";
import { BASE_URL } from "../../components/config/config";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const theme = createTheme({
  overrides: {
    CssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "5px",
        },
        "*::-webkit-scrollbar-track": {
          background: "#E4EFEF",
        },
        "*::-webkit-scrollbar-thumb": {
          background: "#1D388F61",
          borderRadius: "2px",
        },
      },
    },
  },
});

// Modal css
const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid gray',
  boxShadow: 44,
  p: 4,
  borderRadius : 4
};

//   List render
const listStyle = {
  width: '100%',
  // maxWidth: 360,
  bgcolor: 'background.paper',
};

const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "10px",
  },
  wrapper: {
    width: "200px",
    padding: "20px",
    height: "150px",
  },
};

const AVAILABLE_TIME_PREFERENCES = [
  "IMMEDIATELY",
  "WITHIN_24_HOURS",
  "SPECIFIC_DATE_AND_TIME",
];



//================== Select MUI ===============

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 16;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      display:'flex'
    },
  },
};

// =================================================================

class AddTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceRequired: "",
      specificRequirement: "",
      name: "",
      phoneNumber: "",
      alternatePhoneNumber: "",
      houseNumber: "",
      locality: "",
      city: "",
      state: "",
      country: "INDIA",
      pincode: "",
      timePreference: "",
      authorizedForClient: "",
      offerCode: "OniT 2024",
      is_paid: true,
      closing_ticket_price: "",
      admin_remarks: "",
      toast: {
        open: false,
        msg: "",
        duration: 5000,
        type: "",
      },
      specific_date_time: new Date(),
      isFormValid: false,
      servicesList: [],
      clientsList: [],

      isRegistering: false,
      isEditDetailsExists: false,
      doesTicketExists: false,
      TicketModalOpen: false,
      TicketModalData : []
    };
  }

  componentDidMount() {
    this.getAllServicesExists();
    this.getAllClients();

    let editTicketId = new URLSearchParams(this.props.location.search).get(
      "edit"
    );

    console.log(editTicketId, "edit ticket id");

    if (editTicketId != null) {
      this.getTicketDetails(editTicketId);
    }
  }

  onChangeSpecificDateAndTime(newValue) {
    this.setState({ specific_date_time: newValue });
  }

  getTicketObjIdForEdit() {
    return new URLSearchParams(this.props.location.search).get("edit");
  }

  getTicketDetails(editTicketId) {
    let payload = {
      ticket_obj_id: editTicketId,
    };
    this.setState({
      isEditDetailsExists: true,
    });

    new AdminApiModule()
      .getAllTickets(payload)
      .then((res) => {
        if (res?.data?.length) {
          this.setState({
            doesTicketExists: true,
          });

          this.updateTicketDetails(res?.data?.[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  updateTicketDetails(ticketDetails) {
    this.setState({
      serviceRequired: ticketDetails?.service_provided_for?._id,
      specificRequirement: ticketDetails?.specific_requirement,
      name: ticketDetails?.personal_details?.name,
      phoneNumber:
        ticketDetails?.personal_details?.primary_phone?.mobile_number,
      alternatePhoneNumber:
        ticketDetails?.personal_details?.alternate_phone?.mobile_number,
      houseNumber: ticketDetails?.address_details?.house_number,
      locality: ticketDetails?.address_details?.locality,
      city: ticketDetails?.address_details?.city,
      state: ticketDetails?.address_details?.state,
      country: "INDIA",
      pincode: ticketDetails?.address_details?.pincode,
      timePreference: ticketDetails?.time_preference?.time_preference_type,
      authorizedForClient: ticketDetails?.authorized_client_id?._id,
      offerCode: "OniT 2024",
      is_paid: ticketDetails?.admin_setting?.is_paid,
      closing_ticket_price: ticketDetails?.closing_ticket_price,
      admin_remarks: ticketDetails?.remarks.admin_remarks,
      specific_date_time: ticketDetails?.time_preference?.specific_date_time,
    });
  }

  getAllServicesExists() {
    new CenterRegistrationApi()
      .getAllActiveServices()
      .then((res) => {
        this.setState({
          servicesList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getAllClients() {
    new AdminApiModule()
      .getAllClients()
      .then((res) => {
        let parsedData = [];
        if (res && res.data && res.data.length > 0) {
          parsedData = res.data;
        }

        this.setState({
          clientsList: parsedData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  showDialog = (open, type, msg) => {
    this.setState({
      toast: {
        open: open,
        msg: msg,
        duration: 5000,
        type: type,
      },
    });
  };

  closeDialog = (open = false) => {
    this.setState({
      toast: {
        open: open,
        msg: "",
        duration: 5000,
        type: "",
      },
    });
  };

 

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  handleChangeCheckBox = (e) => {
    this.setState({ is_paid: e.target.checked });
  };

  handleRegister = () => {
    const {
      serviceRequired,
      specificRequirement,
      name,
      phoneNumber,
      alternatePhoneNumber,
      houseNumber,
      locality,
      city,
      state,
      country,
      pincode,
      timePreference,
      authorizedForClient,
      offerCode,
    } = this.state;
    const { globalState } = this.props;
    if (
      !(
        serviceRequired &&
        name &&
        phoneNumber &&
        houseNumber &&
        pincode &&
        timePreference &&
        offerCode
      )
    ) {
      this.setState({
        isFormValid: true,
      });

      this.showDialog(true, "error", "Please fill in all required details");
      return;
    }

    this.setState({
      isRegistering: true,
    });

    if (this.state.timePreference == "SPECIFIC_DATE_AND_TIME") {
      if (!this.state.specific_date_time) {
        this.showDialog(true, "error", "Please fill in Specific date and time");
        return;
      }
    }

    let payload = {
      personal_details: {
        primary_phone: {
          country_code: "+91",
          mobile_number: phoneNumber,
        },
        alternate_phone: {
          country_code: alternatePhoneNumber.length > 0 ? "+91" : "",
          mobile_number: alternatePhoneNumber,
        },
        name: name,
      },
      specific_requirement: specificRequirement,
      service_provided_for: serviceRequired,
      address_details: {
        house_number: houseNumber,
        locality: locality,
        city: city,
        state: state,
        pincode: pincode,
        country: country,
      },
      time_preference: {
        time_preference_type: timePreference,
        specific_date_time: this.state.specific_date_time,
      },
      offers_applied: {
        offer_code: offerCode,
      },
      create_name:globalState?.adminDetails?.user_name,
      // is_paid,
    };

    if (authorizedForClient) {
      payload["authorized_client_id"] = authorizedForClient;
    }

    if (this.state.isEditDetailsExists) {
      payload.ticket_obj_id = this.getTicketObjIdForEdit();
      payload.closing_ticket_price = this.state.closing_ticket_price || "";
      payload.admin_remarks = this.state.admin_remarks || "";
      delete payload.is_paid;
      delete payload.authorized_client_id;

      new AdminApiModule()
        .updateTicket(payload)
        .then((res) => {
          this.showDialog(true, "success", "Ticket updated successfully");

          this.setState({
           
            isRegistering: false,
          });

          this.props.history.push("/Tickets");
        })
        .catch((err) => {
          console.log(err);
          this.showDialog(true, "error", err?.response?.data?.message);
          this.setState({
            isRegistering: false,
          });
        });

      return;
    }

    new AdminApiModule()
      .createTicket(payload)
      .then((res) => {

        const TicketData = res?.data
        console.warn("TicketData",TicketData)

        this.showDialog(true, "success", "Ticket Created successfully");

        this.setState({
          TicketModalData: TicketData,
        });

        this.handleResetValues();

        setTimeout(() => {
          this.showDialog(true, "info", "Add more ticket");
          this.setState({
            TicketModalOpen: true,
        });
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isRegistering: false,
        });
        this.showDialog(true, "error", err?.response?.data?.message);
      });
  };

  handleResetValues() {
    this.setState({
      isRegistering: false,

      serviceRequired: "",
      specificRequirement: "",
      name: "",
      phoneNumber: "",
      alternatePhoneNumber: "",
      houseNumber: "",
      locality: "",
      city: "",
      state: "",
      country: "INDIA",
      pincode: "",
      timePreference: "",
      offerCode: "",
      authorizedForClient: "",
      isFormValid: false,
      admin_remarks: "",
    });
  }
  openWhatsapp=(number,id, name)=>{
    if (number) {
      const formattedPhoneNumber = number.replace(/[^\d]/g, '');
      const a = `https://app.onit.services/#/ServiceStatus/?${id}`;
      const message = `Dear ${name} request Onit has been created, Please click on the link to check status: ${a} Download the App from playstore and get more Benefits and Offers`;
  
      const encodedMessage = encodeURIComponent(message);
      window.open(`https://web.whatsapp.com/send?phone=${formattedPhoneNumber}&text=${encodedMessage}`, '_blank');
  } else {
      console.error('Phone number is not defined or invalid');
  }
  }
  handleClose = () => {
    this.setState({
      TicketModalOpen: false,
    });
  };

  render() {
    const { globalState } = this.props;
    // { console.log(this.state) }
    // { console.log(" closing_ticket_price", this.state?.closing_ticket_price) }
    const {
      toast: snackBarValues,
      isFormValid,
      serviceRequired,
      specificRequirement,
      name,
      phoneNumber,
      alternatePhoneNumber,
      houseNumber,
      locality,
      city,
      state,
      country,
      pincode,
      timePreference,
      authorizedForClient,
      offerCode,
    } = this.state;

    if (this.state.isEditDetailsExists && !this.state.doesTicketExists) {
      return <>"Page not found !!!"</>;
    }
    if (this.state.isEditDetailsExists) {
      if (
        !this.props.reduxState?.adminDetails?.role_id?.permissions?.edit_ticket
          ?.full_access
      ) {
        return <>No permission to update ticket , contact admin</>;
      }
    } else {
      if (
        !this.props.reduxState?.adminDetails?.role_id?.permissions?.add_ticket
      ) {
        return <>No permission to add ticket , contact admin</>;
      }
    }
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
    />;


    // code pasted
    const { TicketModalData } = this.state;

    return (
      <div>

      {/* Ticket creation modal code */}
               
      <Modal
          keepMounted
          open={this.state.TicketModalOpen}
          onClose={this.handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <Typography id="keep-mounted-modal-title" variant="h6" component="h2" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <VerifiedIcon color='success' fontSize='large'/>
             Hurray! Your Request has been created 
            </Typography>
            <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
             
             {/* List code  */}
             <List sx={listStyle} component="nav" aria-label="mailbox folders">
      <ListItem button>
        <ListItemText primary="Ticket Id :-" /> <Typography> {TicketModalData?.ticket_id} </Typography>
      </ListItem>
      <Divider />
      <ListItem button divider>
        <ListItemText primary="Ticket Status :-" /> <Typography> {TicketModalData?.ticket_status} </Typography>
      </ListItem>
      <ListItem button>
        <ListItemText primary="Created At :-" /> <Typography> {TicketModalData?.createdAt} </Typography>
      </ListItem>
      <Divider light />
      <ListItem button>
        {/* <ListItemText primary="Tracking Link :-" /> <Typography> 
        <Link to={`ServiceStatus/${TicketModalData?._id}`}>
         Click me
      </Link>
 </Typography> */}
  <ListItemText primary="Tracking Link :-" />{" "}
                                    <Typography>
                                        <CopyButton webLink={`https://app.onit.services/#/ServiceStatus/?${TicketModalData?._id}`} />
                                        <Link
                                            style={{ marginLeft: '17px' }}
                                            to={`ServiceStatus/?${TicketModalData?._id}`}
                                        >
                                            Click me
                                        </Link>
                                    </Typography>
      </ListItem>
      <ListItem>
                  <IconButton onClick={()=>this.openWhatsapp(TicketModalData?.personal_details?.primary_phone?.mobile_number, TicketModalData?._id, TicketModalData?.personal_details?.name)}>
                    <WhatsAppIcon sx={{ color: 'green', fontSize: 30, padding: "1px" }}/>

                  </IconButton>
                </ListItem>
    </List>


            </Typography>
          </Box>
        </Modal>

        {snackBarValues.open && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBarValues.open}
            autoHideDuration={snackBarValues.duration}
            onClose={this.closeDialog}
          >
            <Alert
              onClose={this.closeDialog}
              severity={snackBarValues.type}
              sx={{ width: "100%" }}
            >
              {snackBarValues.msg}
            </Alert>
          </Snackbar>
        )}
        <h2 className="text-align-center">
          {this.state.isEditDetailsExists ? "Update ticket" : "Add Ticket"}
        </h2>
        <Item>
          <Grid container>
            <Grid item md={6} style={stylesCss.paddingInnerGrids}>
              <FormControl
                md={6}
                style={stylesCss.inputBox}
                sx={{ minWidth: 120 }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Service You Required
                </InputLabel>

                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Service You Required"
                  name="serviceRequired"
                  onChange={this.handleChange}
                  value={serviceRequired}
                  error={isFormValid && !serviceRequired}
                >
                  {/* <ThemeProvider theme={theme}> */}
                  {/* <div style={{height: "200px", overflowY: "scroll"}}>  */}
                  {this.state.servicesList?.map((ite) => {
                    console.log(ite, '<------This is the data😊😊😊😊😊😊😊😊😊😊😊😊')
                    return (
                      <MenuItem sx={{display:'flex'}} value={ite._id}><Avatar alt={ite?.service_name} src={ite?.image}  sx={{ width: 29, height: 29 ,marginRight:'14px'}} /> <span style={{wordWrap:'break-word'}}>{ite.service_name}</span> </MenuItem>
                    );
                  })}
                  {/* </div>  */}
                  {/* </ThemeProvider> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Specific Requirement - it helps"
                style={stylesCss.inputBox}
                name="specificRequirement"
                value={specificRequirement}
                onChange={this.handleChange}
                error={isFormValid && !specificRequirement}
                inputProps={{ maxLength: 300 }}
              />
            </Grid>

            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Name (Contact Person)"
                style={stylesCss.inputBox}
                name="name"
                value={name}
                disabled={this.state.isEditDetailsExists}
                onChange={this.handleChange}
                error={isFormValid && !name}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <TextField
               type="number"
                id="demo-helper-text-misaligned"
                label="Phone Number"
                style={stylesCss.inputBox}
                disabled={this.state.isEditDetailsExists}
                name="phoneNumber"
                value={phoneNumber}
                onChange={this.handleChange}
                error={isFormValid && !phoneNumber}
                
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>+91</Typography>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 10
                 }}
              />
            </Grid>
                {console.log("alternatePhoneNumber",alternatePhoneNumber == "")}
            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Alternate Phone Number"
                style={stylesCss.inputBox}
                name="alternatePhoneNumber"
                value={alternatePhoneNumber}
                placeholder={alternatePhoneNumber == "" && "Not Provided"}
                disabled={this.state.isEditDetailsExists}
                onChange={this.handleChange}
                // error={isFormValid && !alternatePhoneNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>+91</Typography>
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item style={stylesCss.paddingInnerGrids} md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="House Number / Street"
                style={stylesCss.inputBox}
                name="houseNumber"
                value={houseNumber}
                onChange={this.handleChange}
                error={isFormValid && !houseNumber}
                inputProps={{ maxLength: 200 }}
              />
            </Grid>
            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Locality"
                style={stylesCss.inputBox}
                name="locality"
                value={locality}
                // disabled={this.state.isEditDetailsExists}
                onChange={this.handleChange}
                error={isFormValid && !locality}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="City"
                style={stylesCss.inputBox}
                name="city"
                value={city}
                onChange={this.handleChange}
                error={isFormValid && !city}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>
            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              {console.log("state", state)}
              <FormControl
                md={6}
                style={stylesCss.inputBox}
                sx={{ minWidth: 120 }}
              >
                {/* <InputLabel id="demo-simple-select-helper-label">State</InputLabel> */}

                <span style={{ textAlign: "left" }}>State</span>
                <Select
                  value={state}
                  onChange={this.handleChange}
                  name="state"
                  renderValue={(selected) => {
                    if (selected.includes(state)) {
                      return <em>{state}</em>;
                    }
                    return selected.slice(0, selected.length);
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ textAlign: "left" }}
                >
                  <MenuItem sx={{ textAlign: "center" }} value="">
                    <em>{state}</em>
                  </MenuItem>
                  {IndianStates.map((ite) => (
                    <MenuItem key={ite} value={ite.value}>
                      {ite.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Country"
                style={stylesCss.inputBox}
                name="country"
                value={country}
                onChange={this.handleChange}
                error={isFormValid && !country}
                disabled
                inputProps={{ maxLength: 30 }}
                sx={{ marginTop: 2.7 }}
              />
            </Grid>
            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Pincode"
                style={stylesCss.inputBox}
                name="pincode"
                value={pincode}
                disabled={this.state.isEditDetailsExists}
                onChange={this.handleChange}
                error={isFormValid && !pincode}
                inputProps={{ maxLength: 6 }}
                sx={{ marginTop: 2.5 }}
              />
            </Grid>
            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <FormControl
                md={6}
                style={stylesCss.inputBox}
                sx={{ minWidth: 120 }}
              >
                {/* <InputLabel id="demo-simple-select-helper-label">Time Preference</InputLabel> */}
                <span style={{ textAlign: "left" }}>Time Preference</span>

                <Select
                  value={timePreference || "No data"}
                  onChange={this.handleChange}
                  name="timePreference"
                  renderValue={(selected) => {
                    if (
                      selected.includes(timePreference) ||
                      timePreference == undefined
                    ) {
                      return <em>{timePreference || "Not Available"}</em>;
                    }
                    return (
                      selected.slice(0, selected.length) || "Not Available"
                    );
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ textAlign: "left" }}
                >
                  <MenuItem>
                    <em>{"No data"}</em>
                  </MenuItem>
                  {AVAILABLE_TIME_PREFERENCES.map((ite) => (
                    <MenuItem key={ite} value={ite}>
                      {ite}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {this.state.timePreference == "SPECIFIC_DATE_AND_TIME" ? (
              <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(params) => <TextField {...params} />}
                    label="Select date and time"
                    value={this.state.specific_date_time}
                    onChange={(newValue) => {
                      this.onChangeSpecificDateAndTime(newValue);
                    }}
                    minDate={new Date()}
                    minTime={new Date(0, 0, 0, 8)}
                    maxTime={new Date(0, 0, 0, 20)}
                  />
                </LocalizationProvider>
              </Grid>
            ) : (
              ""
            )}

            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <TextField
                id="demo-helper-text-misaligned"
                label="Offer Code"
                style={stylesCss.inputBox}
                name="offerCode"
                value={offerCode}
                onChange={this.handleChange}
                error={isFormValid && !offerCode}
                inputProps={{ maxLength: 20 }}
                sx={{ marginTop: 2.7 }}
              />
            </Grid>

            {this.state.isEditDetailsExists ? (
              <Grid
                style={stylesCss.paddingInnerGrids}
                sx={{ textAlign: "left" }}
                item
                md={6}
              >
                <span>Closing price</span>
                <TextField
                  id="demo-helper-text-misaligned"
                  style={stylesCss.inputBox}
                  name="closing_ticket_price"
                  type="number"
                  value={
                    this.state.closing_ticket_price === undefined
                      ? "Data not available"
                      : this.state.closing_ticket_price
                  }
                  onChange={this.handleChange}
                  error={isFormValid && !this.state.closing_ticket_price}
                  inputProps={{ maxLength: 10 }}
                  placeholder={
                    this.state.closing_ticket_price === undefined
                      ? "Data not available"
                      : this.state.closing_ticket_price
                  }
                />
              </Grid>
            ) : (
              ""
            )}
            {console.log("this.state.admin_remarks",this.state.admin_remarks) }
            {this.state.isEditDetailsExists ? (
              <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                <span>Add admin comments</span>
                <TextField
                  id="demo-helper-text-misaligned"
                  // label="Add admin comments"
                  style={stylesCss.inputBox}
                  name="admin_remarks"
                  type="text"
                  value={this.state.admin_remarks}
                  placeholder={this.state.admin_remarks == undefined && "No Comments" }
                  onChange={this.handleChange}
                  error={isFormValid && !this.state.admin_remarks}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
            ) : (
              ""
            )}
            {this.state.isEditDetailsExists ? (
              " "
            ) : (
              <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                <FormControl
                  md={6}
                  style={stylesCss.inputBox}
                  sx={{ minWidth: 120 }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Authorize for particular client
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Authorize for particular client"
                    name="authorizedForClient"
                    onChange={this.handleChange}
                    value={authorizedForClient}
                    // error={isFormValid && !authorizedForClient}
                  >
                    {this.state.clientsList?.map((ite) => {
                      return (
                        <MenuItem value={ite._id}>{ite.client_name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {this.state.isEditDetailsExists ? (
              ""
            ) : (
              <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                {/* <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked='true'
                        disabled
                        // onChange={this.handleChangeCheckBox}
                        // value={}
                      />
                    }
                    label="Pay during Acceptance"
                  />
                </FormGroup> */}
              </Grid>
            )}

            <Grid style={stylesCss.paddingInnerGrids} item md={6}>
              <Button
                variant="contained"
                onClick={() => this.handleRegister()}
                disabled={this.state.isRegistering}
              >
                {this.state.isEditDetailsExists ? "Update " : "SUBMIT"}
              </Button>
            </Grid>
          </Grid>
        </Item>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reduxState: state.userReducer,
  globalState: state.AdminReducer,
});
export default connect(mapStateToProps, null)(withRouter(AddTicket));
