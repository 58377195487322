
import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from '@mui/material';
import moment from "moment";
import AdminApiModule from "../../components/AdminApi/Api/api";
import * as XLSX from 'xlsx';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import axios from "axios";
import { BASE_URL } from "../../components/config/config";
import { useSelector } from "react-redux";

function ReportPage() {
  const leftHeaders = ['Today', 'This Week', 'Last Week', 'This Month', 'Last Month'];
  const topHeaders = ['Received',  'Closed', 'Cancelled', 'Accepted'];
  const top2headers = ['Total Tickets','Admin','Web','App','QR','Centers', 'Customers', 'Total'];

  const [data1, setData1] = useState([
    { totalticket: 0,Admin:0, Web: 0, App: 0, QR: 0, center: 0, customer: 0, total: 0 },
    { totalticket: 0,Admin:0, Web: 0, App: 0, QR: 0, center: 0, customer: 0, total: 0 },
    { totalticket: 0,Admin:0, Web: 0, App: 0, QR: 0, center: 0, customer: 0, total: 0 },
    { totalticket: 0,Admin:0, Web: 0, App: 0, QR: 0, center: 0, customer: 0, total: 0 },
    { totalticket: 0,Admin:0, Web: 0, App: 0, QR: 0, center: 0, customer: 0, total: 0 }
  ]);

  const [data, setData] = useState([
    { received: 0, closed: 0, Cancelled: 0, accepted: 0 },
    { received: 0, closed: 0, Cancelled: 0, accepted: 0 },
    { received: 0, closed: 0, Cancelled: 0, accepted: 0 },
    { received: 0, closed: 0, Cancelled: 0, accepted: 0 },
    { received: 0, closed: 0, Cancelled: 0, accepted: 0 }
  ]);

  const [accessToken, setAccessToken] = useState("");
  const globalState = useSelector((state) => state.AdminReducer);

  const [pieChartData, setPieChartData] = useState([]);
  useEffect(() => {
    if (globalState?.userTokens?.accessToken) {
        console.log("Access Token found:", globalState.userTokens.accessToken);
        setAccessToken(globalState.userTokens.accessToken);
        fetchTodayRevenue(globalState.userTokens.accessToken);
        fetchThisWeekRevenue(globalState.userTokens.accessToken);
        fetchLastWeekRevenue(globalState.userTokens.accessToken);
        fetchThisMonthRevenue(globalState.userTokens.accessToken);
        fetchLastMonthRevenue(globalState.userTokens.accessToken);
        fetchTodayRevenueC(globalState.userTokens.accessToken);
        fetchThisWeekRevenueC(globalState.userTokens.accessToken);
        fetchLastWeekRevenueC(globalState.userTokens.accessToken);
        fetchThisMonthRevenueC(globalState.userTokens.accessToken);
        fetchLastMonthRevenueC(globalState.userTokens.accessToken);
      } else {
        console.log("Access Token not found");
      }
    fetchCurrentWeekTickets();
    fetchLastWeekTickets();
    fetchTodayTickets();
    fetchThisMonthTickets();
    fetchLastMonthTickets();
  }, [globalState]);

  const fetchCurrentWeekTickets = async () => {
    try {
      let startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
      let endOfWeek = moment().endOf('week').format('YYYY-MM-DD');
      let payload = { start_date: startOfWeek, end_date: endOfWeek };
      const apiModule = new AdminApiModule();
      const res = await apiModule.getAllTickets(payload);

      if (res?.data?.length) {
        const received = res.data.length;
        const closed = res.data.filter(ticket => ticket.ticket_status === 'CLOSED').length;
        const Cancelled = res.data.filter(ticket => ticket.ticket_status === 'CANCEL').length;
        const accepted = res.data.filter(ticket=> ticket.ticket_status === 'ACCEPTED').length;
        const qr = res.data.filter(ticket => ticket.ticket_created_by === 'PUBLIC_WITH_CENTER_QR'||ticket.ticket_created_by == 'PUBLIC_WITH_DELAR_QR').length;
        const app= res.data.filter(ticket => ticket.ticket_created_by=== 'PUBLIC_APP').length;
        const web= res.data.filter(ticket => ticket.ticket_created_by=== 'PUBLIC').length;
        const admin = res.data.filter(ticket => ticket.ticket_created_by === 'SUPER_ADMIN').length;

        setData(prevData => {
          const updatedData = [...prevData];
          updatedData[1] = { received, closed, Cancelled, accepted }; 
          return updatedData;
        });

        setData1(prevData1 => {
          const updatedData = [...prevData1];
          updatedData[1].QR = qr;
          updatedData[1].totalticket=received;
          updatedData[1].App=app;
          updatedData[1].Web=web;
          updatedData[1].Admin=admin;
          return updatedData;
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchLastWeekTickets = async () => {
    try {
      let startOfLastWeek = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
      let endOfLastWeek = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
      let payload = { start_date: startOfLastWeek, end_date: endOfLastWeek };
      const apiModule = new AdminApiModule();
      const res = await apiModule.getAllTickets(payload);

      if (res?.data?.length) {
        const received = res.data.length;
        const closed = res.data.filter(ticket => ticket.ticket_status === 'CLOSED').length;
        const Cancelled = res.data.filter(ticket => ticket.ticket_status === 'CANCEL').length;
        const accepted = res.data.filter(ticket => ticket.ticket_status === 'ACCEPTED').length;
        const qr = res.data.filter(ticket => ticket.ticket_created_by === 'PUBLIC_WITH_CENTER_QR'||ticket.ticket_created_by == 'PUBLIC_WITH_DELAR_QR').length;
        const app= res.data.filter(ticket => ticket.ticket_created_by=== 'PUBLIC_APP').length;
        const web= res.data.filter(ticket => ticket.ticket_created_by=== 'PUBLI').length; 
        const admin = res.data.filter(ticket => ticket.ticket_created_by === 'SUPER_ADMIN').length;

        setData(prevData => {
          const updatedData = [...prevData];
          updatedData[2] = { received, closed, Cancelled, accepted }; 
          return updatedData;
        });

        setData1(prevData1 => {
          const updatedData = [...prevData1];
          updatedData[2].QR = qr;
          updatedData[2].totalticket=received;
          updatedData[2].App=app;
          updatedData[2].Web=web;
          updatedData[2].Admin=admin;
          return updatedData;
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchTodayTickets = async () => {
    try {
                let startOfToday = moment().subtract(1, 'day').format('YYYY-MM-DD');
                let endOfNextDay = moment().add(1, 'day').endOf('day').format('YYYY-MM-DD');
                let startOfTodayWithTime = moment(startOfToday).format('YYYY-MM-DD') + ' 18:00:00';
                let endOfNextDayWithTime = moment(endOfNextDay).format('YYYY-MM-DD') + ' 00:00:00';
              let payload = {
                start_date: startOfTodayWithTime,
                 end_date: endOfNextDayWithTime,
              };
              const apiModule = new AdminApiModule();
              const res = await apiModule.getAllTickets(payload);
      if (res?.data?.length) {
        const received = res.data.length;
        const closed = res.data.filter(ticket => ticket.ticket_status === 'CLOSED').length;
        const Cancelled = res.data.filter(ticket => ticket.ticket_status === 'CANCEL').length;
        const accepted = res.data.filter(ticket => ticket.ticket_status === 'ACCEPTED').length;
        const pending = res.data.filter(ticket => ticket.ticket_status === 'PENDING').length;
        const qr = res.data.filter(ticket => ticket.ticket_created_by === 'PUBLIC_WITH_CENTER_QR'||ticket.ticket_created_by == 'PUBLIC_WITH_DELAR_QR').length;
        const app= res.data.filter(ticket => ticket.ticket_created_by=== 'PUBLIC_APP').length;
        const web= res.data.filter(ticket => ticket.ticket_created_by=== 'PUBLIC').length;
        const admin = res.data.filter(ticket => ticket.ticket_created_by === 'SUPER_ADMIN').length;

        setData(prevData => {
          const updatedData = [...prevData];
          updatedData[0] = { received, closed, Cancelled, accepted }; 
          return updatedData;
        });

        setData1(prevData1 => {
          const updatedData = [...prevData1];
          updatedData[0].QR = qr;
          updatedData[0].totalticket=received;
          updatedData[0].App=app;
          updatedData[0].Web=web;
          updatedData[0].Admin=admin;
          return updatedData;
        });

        setPieChartData([
          { name: 'Closed', value: closed },
          { name: 'Cancelled', value: Cancelled },
          { name: 'Accepted', value: accepted },
          { name: 'Pending', value: pending }
        ]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchThisMonthTickets = async () => {
    try {
      let startOfThisMonth = moment().startOf('month').format('YYYY-MM-DD');
      let endOfThisMonth = moment().endOf('month').format('YYYY-MM-DD');
      let payload = { start_date: startOfThisMonth, end_date: endOfThisMonth };
      const apiModule = new AdminApiModule();
      const res = await apiModule.getAllTickets(payload);

      if (res?.data?.length) {
        const received = res.data.length;
        const closed = res.data.filter(ticket => ticket.ticket_status === 'CLOSED').length;
        const Cancelled = res.data.filter(ticket => ticket.ticket_status === 'CANCEL').length;
        const accepted = res.data.filter(ticket => ticket.ticket_status === 'ACCEPTED').length;
        const qr = res.data.filter(ticket => ticket.ticket_created_by === 'PUBLIC_WITH_CENTER_QR'||ticket.ticket_created_by == 'PUBLIC_WITH_DELAR_QR').length;
        const app= res.data.filter(ticket => ticket.ticket_created_by=== 'PUBLIC_APP').length;
        const web= res.data.filter(ticket => ticket.ticket_created_by=== 'PUBLIC').length;
        const admin = res.data.filter(ticket => ticket.ticket_created_by === 'SUPER_ADMIN').length;

        setData(prevData => {
          const updatedData = [...prevData];
          updatedData[3] = { received, closed, Cancelled, accepted }; 
          return updatedData;
        });

        setData1(prevData1 => {
          const updatedData = [...prevData1];
          updatedData[3].QR = qr;
          updatedData[3].totalticket=received;
          updatedData[3].App=app;
          updatedData[3].Web=web;
          updatedData[3].Admin=admin;
          return updatedData;
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchLastMonthTickets = async () => {
    try {
      let startOfLastMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      let endOfLastMonth = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
      let payload = { start_date: startOfLastMonth, end_date: endOfLastMonth };
      const apiModule = new AdminApiModule();
      const res = await apiModule.getAllTickets(payload);

      if (res?.data?.length) {
        const received = res.data.length;
        const closed = res.data.filter(ticket => ticket.ticket_status === 'CLOSED').length;
        const Cancelled = res.data.filter(ticket => ticket.ticket_status === 'CANCEL').length;
        const accepted = res.data.filter(ticket => ticket.ticket_status === 'ACCEPTED').length;
        const qr = res.data.filter(ticket => ticket.ticket_created_by === 'PUBLIC_WITH_CENTER_QR'||ticket.ticket_created_by == 'PUBLIC_WITH_DELAR_QR').length;
        const app= res.data.filter(ticket => ticket.ticket_created_by=== 'PUBLIC_APP').length;
        const web= res.data.filter(ticket => ticket.ticket_created_by=== 'PUBLIC').length;
        const admin = res.data.filter(ticket => ticket.ticket_created_by === 'SUPER_ADMIN').length;

        setData(prevData => {
          const updatedData = [...prevData];
          updatedData[4] = { received, closed, Cancelled, accepted }; 
          return updatedData;
        });

        setData1(prevData1 => {
          const updatedData = [...prevData1];
          updatedData[4].QR = qr;
          updatedData[4].totalticket=received;
          updatedData[4].App=app;
          updatedData[4].Web=web;
          updatedData[4].Admin=admin;
          return updatedData;
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchTodayRevenue = async (accessToken) => {
    try {
        let startOfToday = moment().subtract(1, 'day').format('YYYY-MM-DD');
        let endOfNextDay = moment().add(1, 'day').endOf('day').format('YYYY-MM-DD');
        let startOfTodayWithTime = moment(startOfToday).format('YYYY-MM-DD') + ' 18:00:00';
        let endOfNextDayWithTime = moment(endOfNextDay).format('YYYY-MM-DD') + ' 00:00:00';
      let payload = {
        start_date: startOfTodayWithTime,
         end_date: endOfNextDayWithTime,
      };
      const res = await axios.get(`${BASE_URL}admin/wallet-transactions`, {
        headers: {
            Authorization: accessToken,
          },
          params: payload,
      });

      let totalAmount = 0;
        if (res.data?.data?.length) {
            totalAmount = res.data.data.reduce((acc, payment) => {
                if (payment.amount) {
                    return acc + parseFloat(payment.amount);
                }
                return acc;
            }, 0);
            setData1(prevData1 => {
                const updatedData = [...prevData1];
               
                updatedData[0].center=totalAmount;
                return updatedData;
              });
            }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchThisWeekRevenue = async (accessToken) => {
    try {
        let startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
        let endOfWeek = moment().endOf('week').format('YYYY-MM-DD');
        let payload = { start_date: startOfWeek, end_date: endOfWeek };
    
      const res = await axios.get(`${BASE_URL}admin/wallet-transactions`, {
        headers: {
            Authorization: accessToken,
          },
          params: payload,
      });

      let totalAmount = 0;
        if (res.data?.data?.length) {
            totalAmount = res.data.data.reduce((acc, payment) => {
                if (payment.amount) {
                    return acc + parseFloat(payment.amount);
                }
                return acc;
            }, 0);
            setData1(prevData1 => {
                const updatedData = [...prevData1];
               
                updatedData[1].center=totalAmount;
                return updatedData;
              });
            }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchLastWeekRevenue = async (accessToken) => {
    try {
        let startOfLastWeek = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
      let endOfLastWeek = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
      let payload = { start_date: startOfLastWeek, end_date: endOfLastWeek };
    
      const res = await axios.get(`${BASE_URL}admin/wallet-transactions`, {
        headers: {
            Authorization: accessToken,
          },
          params: payload,
      });

      let totalAmount = 0;
        if (res.data?.data?.length) {
            totalAmount = res.data.data.reduce((acc, payment) => {
                if (payment.amount) {
                    return acc + parseFloat(payment.amount);
                }
                return acc;
            }, 0);
            setData1(prevData1 => {
                const updatedData = [...prevData1];
               
                updatedData[2].center=totalAmount;
                return updatedData;
              });
            }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchThisMonthRevenue = async (accessToken) => {
    try {
        let startOfThisMonth = moment().startOf('month').format('YYYY-MM-DD');
      let endOfThisMonth = moment().endOf('month').format('YYYY-MM-DD');
      let payload = { start_date: startOfThisMonth, end_date: endOfThisMonth };
    
      const res = await axios.get(`${BASE_URL}admin/wallet-transactions`, {
        headers: {
            Authorization: accessToken,
          },
          params: payload,
      });

      let totalAmount = 0;
        if (res.data?.data?.length) {
            totalAmount = res.data.data.reduce((acc, payment) => {
                if (payment.amount) {
                    return acc + parseFloat(payment.amount);
                }
                return acc;
            }, 0);
            setData1(prevData1 => {
                const updatedData = [...prevData1];
               
                updatedData[3].center=totalAmount;
                return updatedData;
              });
            }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const fetchLastMonthRevenue = async (accessToken) => {
    try {
        let startOfLastMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
        let endOfLastMonth = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        let payload = { start_date: startOfLastMonth, end_date: endOfLastMonth };
    
      const res = await axios.get(`${BASE_URL}admin/wallet-transactions`, {
        headers: {
            Authorization: accessToken,
          },
          params: payload,
      });

      let totalAmount = 0;
        if (res.data?.data?.length) {
            totalAmount = res.data.data.reduce((acc, payment) => {
                if (payment.amount) {
                    return acc + parseFloat(payment.amount);
                }
                return acc;
            }, 0);
            setData1(prevData1 => {
                const updatedData = [...prevData1];
                updatedData[4].center=totalAmount;
                return updatedData;
              });
            }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchTodayRevenueC = async (accessToken) => {
    try {
        let startOfToday = moment().subtract(1, 'day').format('YYYY-MM-DD');
        let endOfNextDay = moment().add(1, 'day').endOf('day').format('YYYY-MM-DD');
        let startOfTodayWithTime = moment(startOfToday).format('YYYY-MM-DD') + ' 18:00:00';
        let endOfNextDayWithTime = moment(endOfNextDay).format('YYYY-MM-DD') + ' 00:00:00';
        let payload = {
            start_date: startOfTodayWithTime,
            end_date: endOfNextDayWithTime,
        };
        const res = await axios.get(`${BASE_URL}admin/wallet-transactions-consumer`, {
            headers: {
                Authorization: accessToken,
            },
            params: payload,
        });

        let totalAmount = 0;
        if (res.data?.data?.length) {
            totalAmount = res.data.data.reduce((acc, payment) => {
                if (payment.amount) {
                    return acc + parseFloat(payment.amount);
                }
                return acc;
            }, 0);
            setData1(prevData1 => {
                const updatedData = [...prevData1];
                updatedData[0].customer = totalAmount;
                return updatedData;
            });
        }
    } catch (error) {
        console.log("Error fetching data:", error);
    }
};

const fetchThisWeekRevenueC = async (accessToken) => {
    try {
        let startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
        let endOfWeek = moment().endOf('week').format('YYYY-MM-DD');
        let payload = { start_date: startOfWeek, end_date: endOfWeek };

        const res = await axios.get(`${BASE_URL}admin/wallet-transactions-consumer`, {
            headers: {
                Authorization: accessToken,
            },
            params: payload,
        });

        let totalAmount = 0;
        if (res.data?.data?.length) {
            totalAmount = res.data.data.reduce((acc, payment) => {
                if (payment.amount) {
                    return acc + parseFloat(payment.amount);
                }
                return acc;
            }, 0);
            setData1(prevData1 => {
                const updatedData = [...prevData1];
                updatedData[1].customer = totalAmount;
                return updatedData;
            });
        }
    } catch (error) {
        console.log("Error fetching data:", error);
    }
};

const fetchLastWeekRevenueC = async (accessToken) => {
    try {
        let startOfLastWeek = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
        let endOfLastWeek = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
        let payload = { start_date: startOfLastWeek, end_date: endOfLastWeek };

        const res = await axios.get(`${BASE_URL}admin/wallet-transactions-consumer`, {
            headers: {
                Authorization: accessToken,
            },
            params: payload,
        });

        let totalAmount = 0;
        if (res.data?.data?.length) {
            totalAmount = res.data.data.reduce((acc, payment) => {
                if (payment.amount) {
                    return acc + parseFloat(payment.amount);
                }
                return acc;
            }, 0);
            setData1(prevData1 => {
                const updatedData = [...prevData1];
                updatedData[2].customer = totalAmount;
                return updatedData;
            });
        }
    } catch (error) {
        console.log("Error fetching data:", error);
    }
};

const fetchThisMonthRevenueC = async (accessToken) => {
    try {
        let startOfThisMonth = moment().startOf('month').format('YYYY-MM-DD');
        let endOfThisMonth = moment().endOf('month').format('YYYY-MM-DD');
        let payload = { start_date: startOfThisMonth, end_date: endOfThisMonth };

        const res = await axios.get(`${BASE_URL}admin/wallet-transactions-consumer`, {
            headers: {
                Authorization: accessToken,
            },
            params: payload,
        });

        let totalAmount = 0;
        if (res.data?.data?.length) {
            totalAmount = res.data.data.reduce((acc, payment) => {
                if (payment.amount) {
                    return acc + parseFloat(payment.amount);
                }
                return acc;
            }, 0);
            setData1(prevData1 => {
                const updatedData = [...prevData1];
                updatedData[3].customer = totalAmount;
                return updatedData;
            });
        }
    } catch (error) {
        console.log("Error fetching data:", error);
    }
};

const fetchLastMonthRevenueC = async (accessToken) => {
    try {
        let startOfLastMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
        let endOfLastMonth = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        let payload = { start_date: startOfLastMonth, end_date: endOfLastMonth };

        const res = await axios.get(`${BASE_URL}admin/wallet-transactions-consumer`, {
            headers: {
                Authorization: accessToken,
            },
            params: payload,
        });

        let totalAmount = 0;
        if (res.data?.data?.length) {
            totalAmount = res.data.data.reduce((acc, payment) => {
                if (payment.amount) {
                    return acc + parseFloat(payment.amount);
                }
                return acc;
            }, 0);
            setData1(prevData1 => {
                const updatedData = [...prevData1];
                updatedData[4].customer = totalAmount;
                return updatedData;
            });
        }
    } catch (error) {
        console.log("Error fetching data:", error);
    }
};


  const exportToExcel = () => {
    const sheetData = [];
    sheetData.push(['', ...topHeaders]);

    leftHeaders.forEach((rowHeader, rowIndex) => {
      const rowData = [
        rowHeader,
        data[rowIndex]?.received || 0,
        data[rowIndex]?.closed || 0,
        data[rowIndex]?.Cancelled || 0,
        data[rowIndex]?.accepted || 0
      ];
      sheetData.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    XLSX.writeFile(workbook, 'report.xlsx');
  };

  const exportRevenueToExcel = () => {
    const sheetData = [];
    sheetData.push(['', ...top2headers]);
  
    leftHeaders.forEach((rowHeader, rowIndex) => {
      const rowData = [
        rowHeader,
        data1[rowIndex]?.totalticket || 0,
        data1[rowIndex]?.Admin || 0,
        data1[rowIndex]?.Web || 0,
        data1[rowIndex]?.App || 0,
        data1[rowIndex]?.QR || 0,
        data1[rowIndex]?.center || 0,
        data1[rowIndex]?.customer || 0,
        (data1[rowIndex]?.center || 0) + (data1[rowIndex]?.customer || 0)
      ];
      sheetData.push(rowData);
    });
  
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Revenue Report");
    XLSX.writeFile(workbook, 'revenue_report.xlsx');
  };

  
  const COLORS = ['#0088FE', '#FF8042', '#00C49F', '#FFBB28'];

  return (
    <div style={{ backgroundColor: 'white', padding: '20px', minHeight: '100vh' }}>
      <div className='heading' style={{ color: 'black', display: 'flex', justifyContent: 'space-between' }}>
        <h1>Insights of Tickets</h1>
        <Button variant="contained" color="primary" onClick={exportToExcel}>
          Download Report
        </Button>
      </div>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Time Period</TableCell>
              {topHeaders.map((header) => (
                <TableCell key={header} style={{ fontWeight: 'bold' }}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {leftHeaders.map((rowHeader, rowIndex) => (
              <TableRow key={rowHeader}>
                <TableCell style={{ fontWeight: 'bold' }}>{rowHeader}</TableCell>
                <TableCell>{data[rowIndex]?.received || 0}</TableCell>
                <TableCell>{data[rowIndex]?.closed || 0}</TableCell>
                <TableCell>{data[rowIndex]?.Cancelled || 0}</TableCell>
                <TableCell>{data[rowIndex]?.accepted || 0}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div>
        <PieChart width={400} height={400}>
          <Pie
            data={pieChartData}
            cx={200}
            cy={200}
            innerRadius={60}
            outerRadius={120}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {pieChartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </div>
      <div className='revenue' style={{marginTop:"40px", justifyContent:"space-between", display:"flex"}} >
    <h1> Revenue</h1>
    <Button variant="contained" color="primary" onClick={exportRevenueToExcel}>
  Download Revenue Report
</Button>

    </div>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Time Period</TableCell>
              {top2headers.map((header) => (
                <TableCell key={header} style={{ fontWeight: 'bold' }}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {leftHeaders.map((rowHeader, rowIndex) => (
              <TableRow key={rowHeader}>
                <TableCell style={{ fontWeight: 'bold' }}>{rowHeader}</TableCell>
                <TableCell>{data1[rowIndex]?.totalticket || 0}</TableCell>
                <TableCell>{data1[rowIndex]?.Admin || 0}</TableCell>
                <TableCell>{data1[rowIndex]?.Web || 0}</TableCell>
                <TableCell>{data1[rowIndex]?.App || 0}</TableCell>
                <TableCell>{data1[rowIndex]?.QR || 0}</TableCell>
                <TableCell>{data1[rowIndex]?.center || 0}</TableCell>
                <TableCell>{data1[rowIndex]?.customer || 0}</TableCell>
                <TableCell>{(data1[rowIndex]?.center || 0) + (data1[rowIndex]?.customer || 0)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ReportPage;
