import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const stylesCss = {
    inputBox: {
        width: '100%',
        marginBottom: "10px"
    },
   
}
export default function AssignTechnician({
    open,
    handleClose,
    technicianList,
    isForChange,
    handleAssignTechnician,
    handleChangeTechnician,
    isTechnicianAssigning
}) {

    const [technicianSelected, setTechnicianSelected] = useState("")

    const handleChange = (e) => {
        console.log(e.target.value, "llll")
        setTechnicianSelected(e.target.value)
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {isForChange ? "Change Technician" : "Assign Technician"}
                </Typography>
                <br />

                <br />

                <Box >
                    <FormControl style={{ ...stylesCss.inputBox, display: 'block', width: '100%' }}>
                        <InputLabel id="demo-simple-select-helper-label">Choose Technician</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={technicianSelected}
                            label="Choose Technician"
                            name="technicianSelected"
                            onChange={handleChange}
                            style={{ minWidth: 120, display: 'block', width: '100%' }}
                            //sx={{ flexDirection: 'column' }}
                        >
                            {technicianList?.map(ite => {
                                return <MenuItem  
                              //  sx={{ display: 'block' }}
                                //sx={{ flexDirection: 'column' }} 
                                value={ite?._id}>{ite?.personal_details?.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <br />
                <br />


                <Button
                    onClick={() => {
                        if (isForChange) {
                            handleChangeTechnician(technicianSelected)
                        } else {
                            handleAssignTechnician(technicianSelected)
                        }
                    }}
                    variant="contained"
                    disabled={isTechnicianAssigning}
                >
                    {isTechnicianAssigning ? "Processing " : isForChange ? "Change Techician" : "Assign Techician"}
                </Button>

            </Box>

        </Modal>

    )
}
