import * as React from "react";
import Box from "@mui/material/Box";
import { Chip } from "@material-ui/core";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { Button, Grid, Typography } from "@mui/material";
import BasicDateRangePicker from "../../../../components/common/datepicker";
import { BASE_URL } from "../../../../components/config/config";
// Mui data table
const columns = [
  {
    name: "createdAt",
    label: "Transaction Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => moment(value).format("Do MMM, hh:ss A"),
    },
  },
  {
    name: "order_id",
    label: "Order Id",
    customHeadLabelRender: () => (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography align="center">Order Id</Typography>
      </div>
    ),
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => {
        return value == "Joining Bonus" ? (
          <Chip label={value} style={{ background: "#8FD1AD" }} />
        ) : (
          <Chip label={value} style={{ background: "#F5F0BB" }} />
        );
      },
    },
  },
  {
    name: "payment_for",
    label: "Payment For",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "payment_status",
    label: "Payment Status",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "receipt",
    label: "Receipt",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "sumPriceToPay",
    label: "Price Paid",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => {
        return value / 100;
      },
    },
  },
  {
    name: "ticket_obj_id",
    label: "ticket_obj_id",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => {
        return (
          <span style={{ display: "flex", justifyContent: "center" }}>
            {value?.ticket_id || "-"}
          </span>
        );
      },
    },
  },
];

// Custom filter style
const options = {
  selectableRows: "none",
  responsive: "standard",
  filterType: "checkbox", // or 'dropdown'
  filter: true,
  textLabels: {
    filter: "Filter",
    reset: "Reset",
    close: "Close",
  },
  customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
    <div style={{ padding: "20px" }}>
      <Button variant="contained" onClick={() => applyNewFilters()}>
        Apply
      </Button>
    </div>
  ),
  customFilterDialogFooterStyle: {
    borderTop: "1px solid #ccc",
  },
  customFilterDialogFooterPosition: "right", // or 'right'
  customFilterDialogWidth: "500px", // Set the desired width here
};

const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "2px",
  },
};

export default function ConsumerPaymentTable() {
  const globalState = useSelector((state) => state.AdminReducer);

  let accessToken = globalState?.userTokens?.accessToken;
  console.log(globalState, "global state");

  const [value, setValue] = React.useState(0);

  const refreshData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}admin/payment-transactions-consumer`, {
        headers: {
          Authorization: accessToken,
        },
      });
      setRows(response.data?.data);
    } catch (error) {
      console.log("Error fetching data:", error);
      return [];
    }
  };

  //  Date filter code start
  const onClickFilter = async (value) => {
    let payload = {
      start_date: value[0],
      end_date: value[1],
    };

    try {
      const response = await axios.get(
        `${BASE_URL}admin/payment-transactions-consumer`,
        {
          headers: {
            Authorization: accessToken,
          },
          params: payload,
        }
      );
      setRows(response.data?.data);
    } catch (error) {
      console.log("Error fetching data:", error);
      return [];
    }
  };

  //  Date filter code End

  // Table

  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    // Fetch data from the API and update the rows state
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}admin/payment-transactions-consumer`,
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      setRows(response.data?.data);
    } catch (error) {
      console.log("Error fetching data:", error);
      return [];
    }
  };

  console.log("rows", rows);

  return (
    <>
      <Box style={stylesCss.paddingInnerGrids}>
        <BasicDateRangePicker onClickFilter={onClickFilter} refreshData={refreshData} />
      </Box>
      {/* END here */}
      <MUIDataTable data={rows} columns={columns} options={options} />
    </>
  );
}
