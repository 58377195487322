import React, { Component } from "react";
import MUIDataTable, {
  TableHead,
  TableHeadCell,
  TableHeadRow,
} from "mui-datatables";
import DownloadIcon from "@mui/icons-material/Download";
import { Chip, TableCell } from "@material-ui/core";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "../../Styles/style.css";
import "./Ticket.css";
import { makeStyles } from "@material-ui/core/styles";
import AdminApiModule from "../../components/AdminApi/Api/api";
import { Box, Button, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import BasicDateRangePicker from "../../components/common/datepicker";
import { withRouter } from "react-router";
import moment from "moment";
import { connect } from "react-redux";
import RemarksPopup from "../../components/AdminApi/Api/RemarksPopup";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import FileUploadErrors from "../../components/FileUploadErrors";
import axios from "axios";
import { BASE_URL } from "../../components/config/config";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles"
import { useSelector } from "react-redux";
const CustomFilter = withStyles({
  filter: {
    background: "red",
    // Add other CSS styles if needed
  },
})(InputBase);

const Input = styled("input")({
  display: "none",
});

const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "10px",
  },
};

const options = {
  // Other options...
  filterType: "dropdown",
  filter: true,
  download: true,
  downloadOptions: {
    filterOptions: {
      useDisplayedRowsOnly: true, // Download only the currently displayed (filtered) rows
      useDisplayedColumnsOnly: true, // Download only the currently displayed (filtered) columns
    },
  },
  customFilterMenuRender: (filterValue, onFilterUpdate, column) => {
    return (
      <CustomFilter
        value={filterValue || ""}
        onChange={(event) => onFilterUpdate(event.target.value)}
        placeholder={`Filter ${column.label}`}
      />
    );
  },
  downloadProps: {
    filterOptions: {
      useDisplayedRowsOnly: true,
      useDisplayedColumnsOnly: true,
    },
  },
};

class b2btickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticketsList: [],
      ticketObj: [],
      centerlist: [],
      columns: [
        {
          name: "Created_at",
          label: "Created on",
          options: {
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Created On
              </TableCell>
            ),
            filter: false,
            sort: true,
          },
        },
        {
          name: "daysDifference",
          label: <b>Aging</b>,
          options: {
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Aging
              </TableCell>
            ),
            filter: false,
            sort: true,
          },
        },
        {
          name: "Image",
          label: "On-Site Image",
          options: {
            filter: false,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                On-Site Image
              </TableCell>
            ),
            customBodyRender: (value, id) => {
              return (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto auto auto",
                    gridGap: "-10px !important",
                  }}
                >
                  {value && value.length > 0 && (
                    <Button
                      className="text-transform-none"
                      variant="contained"
                      onClick={() => this.handleViewwTicket(value, value?._id)}
                    >
                      Views
                    </Button>
                  )}
                </div>
              );
            },
          },
        },
        {
          name: "ticket_id",
          label: "Ticket Id",
          options: {
            filter: false,
            sort: true,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Ticket Id
              </TableCell>
            ),
          },
        },
        {
          name: "tstatus",
          label: "Ticket status",
          options: {
            filter: true,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Ticket status
              </TableCell>
            ),
            customBodyRender: (value) => {
              //console.log("Technician Assigned", value);
              return value == "Technician Assigned" ? (
                <Chip label={value} style={{ background: "#8FD1AD" }} />
              ) : (
                <Chip
                  label={value}
                  style={{
                    background:
                      value == "PENDING"
                        ? "#FF9933"
                        : value == "Technician Not Assigned"
                        ? "#E76161"
                        : value == "VERIFIED"
                        ? "#00CC00"
                        : value == "CLOSED"
                        ? "#FF9999"
                        : "red",
                  }}
                />
              );
            },
          },
        },
        {
          name: "center_id",
          label: "Assigned Center",
          options: {
            filter: false,
            sort: true,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Assigned Center
              </TableCell>
            ),
          },
        },

        {
          name: "technician_id",
          label: "Assigned technician",
          options: {
            filter: false,
            sort: true,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Assigned technician
              </TableCell>
            ),
          },
        },

        {
          name: "name",
          label: "Name",
          options: {
            filter: false,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Name
              </TableCell>
            ),
          },
        },
        {
          name: "phone",
          label: "Phone",
          options: {
            filter: false,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                Phone
              </TableCell>
            ),
          },
        },

        {
          name: "timeprefrence",
          label: "Ticket Created By",
          options: {
            filter: false,
            sort: false,
            customHeadRender: () => (
              <TableCell
                style={{ fontSize: "17px", fontWeight: "600" }}
                align="center"
              >
                timeprefrence
              </TableCell>
            ),
          },
        },
        {
          name: "action",
          label: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              // console.log("value", value?.service_available);

              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "100px",
                    }}
                  >
                    <Button
                      sx={{
                        fontSize: "12px !important",
                        marginLeft: "10px !important",
                        marginRight: "15px !important",
                        backgroundColor: value?.remarks?.onboarding_team_remarks
                          ? "green !important"
                          : "FCE9F1 !important",
                      }}
                      onClick={() =>
                        this.handleRemarks(
                          value?._id,
                          this.props.reduxState.adminDetails.user_name,
                          value?.remarks?.onboarding_team_remarks,
                          value?.remarks?.onboarding_team_name
                        )
                      }
                      variant="contained"
                    >
                      Remarks
                    </Button>

                    <RemarksPopup
                      open={this.state.isRemarksPopupOpen}
                      handleClose={this.handleCloseRemarksPopup}
                      onSaveRemarks={this.handleSaveRemarks} // Updated prop name
                      selectedTicketId={this.state.selectedTicketId}
                      username={this.state.username}
                      initialRemarks={this.state.initialRemarks} // Pass initial remarks
                      processedname={this.state.processedname}
                    />
                  </div>
                </>
              );
            },
          },
        },
      ],
      isEditOpenAddressPincode: false,
      isEditOpenTicketDetails: {},
      isPincodeUpdating: false,
      getSingleTicketData: [],
      isLoading: false,
      selectedTicketIds: [],
      isAllRowsSelected: false,
      doesFileUploadErrorExists: false,
      errorsList: {},

      selectedRowsClosingPrice: {}, // Object to store the closing prices of selected rows
      totalClosingPrice: 0,

      selectedTicketIds: [], // Array to store selected ticket IDs
      isApiButtonVisible: false,
      isAssignTechnicianPopupOpen: false,
      isRemarksPopupOpen: false,
      isDeleteAlertPopupOpen: false, // delete alert
      deleteHeading: "Ticket",
      ticketDetailsForAssignTechnician: {},
      isTechnicianAssigning: false,
      technicianList: [],
      isCloseTicketRemarksModalOpen: false,
      closingTicketId: "",
      deleteTicketId: "",
      rebookTicketId: "",
      centerAssignId: "",
      isForChange: false,
      openRebook: false,
      // Pagination
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
    };
  }

  handleRemarks = (ticketId, username, initialRemarks, processedname) => {
    console.log("=========,", ticketId);
    this.setState({
      isRemarksPopupOpen: true,
      selectedTicketId: ticketId,
      username: username,
      initialRemarks: initialRemarks,
      processedname: processedname,
    });
  };

  handleCloseRemarksPopup = () => {
    this.setState({
      isRemarksPopupOpen: false,
      selectedTicketId: null,
    });
  };

  handleSaveRemarks = (ticketId, remarks, username) => {
    // Handle saving remarks here using ticketId, remarks, and username data
    console.log("Ticket ID:", ticketId);
    console.log("Remarks:", remarks);
    console.log("Username:", username);
    // Close the popup
    this.handleCloseRemarksPopup();
  };

  handleViewwTicket = (images, id) => {
    this.props.history.push({
      pathname: "/ticketDocument",
      query: { ticketId: id?.rowData && id?.rowData[20] },
      state: { imageUrls: images, ticketId: id },
    });
  };

  fetchMultipleImages = (data) => {
    const allImages = data.reduce((images, item) => {
      return images.concat(item.onsite_pictures || []);
    }, []);

    this.props.history.push({
      pathname: "/ticketDocument",
      state: { imageUrls: allImages },
    });
  };
  onClickFilter = (value) => {
    this.startLoading();

    let payload = {
      start_date: value[0],
      end_date: value[1],
    };
    new AdminApiModule()
      .getAllClientTicket(payload)
      .then((res) => {
        let parsedData = [];
        if (res?.data?.length) {
          parsedData = this.getParsedData(res.data);
        }
        this.setState({
          ticketsList: parsedData,
        });

        this.stopLoading();
      })
      .catch((err) => {
        this.stopLoading();

        console.log(err);
      });
  };
  refreshData = () => {
    this.startLoading();

    new AdminApiModule()
      .getAllClientTicket()
      .then((res) => {
        let parsedData = [];
        if (res?.data?.length) {
          parsedData = this.getParsedData(res.data);
        }
        this.setState({
          ticketsList: parsedData,
        });

        this.stopLoading();
      })
      .catch((err) => {
        this.stopLoading();

        console.log(err);
      });
  };

  startLoading = () => {
    this.setState({ isLoading: true });
  };

  stopLoading = () => {
    this.setState({ isLoading: false });
  };

  getAllTechnician = () => {
    new AdminApiModule()
      .getAllTechnician()
      .then((res) => {
        this.setState({
          technicianList: res?.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount = () => {
    this.getAllClientTicket();
    this.getAllTechnician();
  };

  getFormattedAddress = (addressObject) => {
    let addressLine =
      addressObject?.house_number +
      ", " +
      addressObject?.locality +
      ", " +
      addressObject?.city +
      ", " +
      addressObject?.state;
    return addressLine;
  };

  getParsedData = (data) => {
    let parsedData = data?.map((item) => {
      //console.log("item?.onsite_pictures", item?.onsite_pictures);
      let closingPrice = item?.closing_ticket_price;
      if (closingPrice === "-" || closingPrice === undefined) {
        closingPrice = 0;
      } else {
        closingPrice = parseFloat(closingPrice);
      }

      return {
        Created_at: new Date(item?.createdAt).toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",

          hour12: true,
        }),
        daysDifference: moment().diff(moment(item?.createdAt), "days"),
        Image: item?.onsite_pictures,
        ticket_id: item?.ticket_id || "-",
        Qr_Id: item?.assigned_ids?.assigned_center_id?.qr_details?.qr_id || "-",
        offer_code: item?.offers_applied?.offer_code || "-",
        center_id: item?.assigned_ids?.assigned_center_id?.center_name,
        technician_id:
          item?.assigned_ids?.assigned_technician_id?.personal_details?.name,
        service_required: `${item?.service_provided_for?.service_name.slice(
          0,
          30
        )}...`,
        name: item.personal_details.name,
        phone: item.personal_details.primary_phone.mobile_number,
        address: `${this.getFormattedAddress(item?.address_details).slice(
          0,
          30
        )}...`,
        pincode: item.address_details.pincode,
        createdAt: new Date(item?.createdAt).toLocaleDateString("en-us", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
        paidbypublic: item?.is_paid_by_public ? "YES" : "NO",
        client_id: item?.client_id || "-",
        client_name: item?.client_name || "-",
        specific_requirements: item?.specific_requirement
          ? item?.specific_requirement
          : "-",
        bstatus:
          item?.service_available == false
            ? "No Service Available In This Area "
            : item?.broadcast_status == "broadcasted" &&
              item?.ticket_created_by == "CENTER"
            ? "NEW NOT PAID"
            : item?.broadcast_status == "broadcasted" &&
              item?.ticket_created_by == "PUBLIC_WITH_DELAR_QR"
            ? "DEALER"
            : item?.ticket_created_by == "SUPER_ADMIN" &&
              item?.broadcast_status == "accepted after broadcast"
            ? "Admin Created-Accepted"
            : item?.broadcast_status == "accepted after broadcast"
            ? "Public Created-Accepted"
            : item?.broadcast_status == undefined
            ? "Not Paid (Payment Failed)"
            : item?.broadcast_status == "broadcasted"
            ? `NEW ${
                (item?.is_paid_by_public == true && "99") ||
                (item?.is_paid_by_public == false &&
                  item?.ticket_created_by === "PUBLIC_WITH_CENTER_QR" &&
                  "NOT PAID")
              }`
            : `${item?.broadcast_status} FREE` ||
              (item?.broadcast_status === undefined && "-"),
        tstatus:
          item?.ticket_status == "PENDING" &&
          item?.broadcast_status == "matched_in_same_center" &&
          item?.assigned_ids?.assigned_technician_id?.personal_details?.name
            ? "Technician Assigned"
            : item?.ticket_status == "PENDING" &&
              item?.broadcast_status == "matched_in_same_center" &&
              !item?.assigned_ids?.assigned_technician_id?.personal_details
                ?.name
            ? "Technician Not Assigned"
            : item?.ticket_status == "ACCEPTED" &&
              item?.assigned_ids?.assigned_technician_id?.personal_details?.name
            ? "Technician Assigned"
            : item?.ticket_status == "ACCEPTED" &&
              !item?.assigned_ids?.assigned_technician_id?.personal_details
                ?.name
            ? "Technician Not Assigned"
            : item?.ticket_status,
        tcreatedby: item?.ticket_created_by,
        Rating: item?.remarks?.over_all_rating,
        action: item,
        closingPrice: closingPrice,
        timeprefrence: item?.time_preference?.specific_date_time
          ? new Date(item.time_preference.specific_date_time).toLocaleString(
              "en-IN",
              {
                timeZone: "Asia/Kolkata",
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: true,
              }
            )
          : item?.time_preference?.time_preference_type,
        //specific: item?.time_preference?.specific_date_time,
        paidbytechnician: item?.is_paid_by_technician ? "YES" : "NO",
        Center_QR: item?.created_by?.qr_details?.qr_id,
      };
    });

    return parsedData;
  };

  getAllClientTicket() {
    const { globalState } = this.props;
    this.startLoading();
    const payload = {
      mobile_number: globalState?.adminDetails?.phone?.mobile_number,
    };
    console.log("++++++=========",globalState)
    new AdminApiModule()
      .getAllClientTicket(payload)
      .then((res) => {
        let parsedData = [];
        if (res?.data?.length) {
          parsedData = this.getParsedData(res.data);
        }
        this.setState({
          ticketsList: parsedData,
        });
        this.stopLoading();
      })
      .catch((err) => {
        this.stopLoading();
        console.log(err);
      });
  }
  

  onUploadCsvFile = (event, stateValue) => {
    let files = event.target.files;
    let aadharSideName = event.target.name;
    let fileOriginalName = files[0]?.name?.split(".")[0];
    console.log(
      "files",
      files,
      aadharSideName,
      fileOriginalName,
      typeof fileOriginalName
    );

    const formData = new FormData();

    formData.append("ticketsCsvFile", files[0], fileOriginalName);

    new AdminApiModule()
      .uploadTicketsCsv(formData)
      .then((res) => {
        console.log("res is", res);
        if (res && res.data && res.data.doesErrorExists) {
          this.setState({
            doesFileUploadErrorExists: true,
            errorsList: res.data.errorRowWise,
          });
        } else {
          this.getAllClientTicket();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleCloseFileUploadErrors = () => {
    this.setState({ doesFileUploadErrorExists: false, errorsList: {} });
  };

  handleCloseCloseTicketRemarksModal = () => {
    this.setState({
      isCloseTicketRemarksModalOpen: false,
    });
  };

  // Delete row code

  handlePageChange = async (event, page) => {
    this.setState({ currentPage: page });
    let showNo = this.state.pageSize;
    let currentsPage = this.state.currentPage;
    let total = 5 + this.state.currentPage;
    await this.getAllClientTicket(total, currentsPage);
  };

  downloadformat = () => {
    this.props.history.push({
      pathname: "/download-format",
    });
  };

  render() {
    const { globalState } = this.props;
    const { selectedTicketIds } = this.state;
    return (
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.isLoading}
          onClick={this.stopLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {this.props.reduxState?.adminDetails?.role_id?.permissions?.view_ticket
          ?.full_access ? (
          <>
            <Grid container>
              <Grid item style={stylesCss.paddingInnerGrids} md={6}>
                <BasicDateRangePicker
                  onClickFilter={this.onClickFilter}
                  refreshData={this.refreshData}
                />
              </Grid>

              <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                <div className="aadhar-upload-button">
                  <div>
                    <label htmlFor="contained-button-file">
                      <Input
                        name="ticketsCsvFile"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => this.onUploadCsvFile(e)}
                      />
                      <Button variant="contained" component="span">
                        Upload Tickets File
                      </Button>
                    </label>

                    <div>
                      <div>Upload csv file for tickets</div>
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    component="span"
                    style={{ height: "37px" }}
                    onClick={this.downloadformat}
                  >
                    <DownloadIcon /> Format
                  </Button>
                </div>
              </Grid>
            </Grid>
            <MUIDataTable
              title={"Tickets"}
              data={this.state.ticketsList}
              columns={this.state.columns}
              options={{
                ...options,
                selectableRows: "multiple",
                onRowSelectionChange: (
                  currentRowsSelected,
                  allRowsSelected
                ) => {
                  const selectedIds = allRowsSelected.map(
                    (row) => this.state.ticketsList[row.dataIndex].ticket_id
                  );
                  this.setState({ selectedTicketIds: selectedIds }, () => {
                    this.updateSelectedRowsClosingPrice();
                  });
                },
              }}
            />

            {this.state.doesFileUploadErrorExists && (
              <FileUploadErrors
                doesFileUploadErrorExists={this.state.doesFileUploadErrorExists}
                errorsList={this.state.errorsList}
                handleCloseFileUploadErrors={this.handleCloseFileUploadErrors}
              />
            )}
          </>
        ) : (
          "No permission , contact admin"
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  reduxState: state.userReducer,
  globalState: state.AdminReducer,
});

export default connect(mapStateToProps, null)(withRouter(b2btickets));
