import React, { useState,useEffect } from "react";
import { withRouter,useHistory } from "react-router-dom";
import AdminApiModule from "./Api/api";
import { connect, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { BASE_URL } from "../config/config";
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';



const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    transition: "transform 1s ease-in-out",
    "&:hover": { transform: "scale3d(2, 2, 1)" },
  },
  cardHovered: {
    transform: "scale3d(1.05, 1.05, 1)",
  },
});

const TechnicianDoc = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [url, setUrl] = React.useState([]);
  const [urlF, setUrlF] = React.useState([]);
  const [urlP, setUrlP] = React.useState([]);
  const [urlI, setUrlI] = React.useState([]);
  const [urlC, setUrlC] = React.useState([]);
  const [doesTechnicianExists, setDoesTechnicianExists] = React.useState(false);
  const [isSwitchPan, setSwitchPan] = useState(false);
  const globalState = useSelector((state) => state.centerOnboardReducer);
  const [backDrop,setBackDrop] = useState(false)
  const [isTechnicianDetailsExists, setIsTechnicianDetailsExists] =
    React.useState(false);

  const technicianId = new URLSearchParams(props.location.search).get(
    "technicianId"
  );
  const handleBackToTechnicians = () => {
    history.push("/admin-technician"); // Navigate to technicians page
  };
  const adminId = globalState?.adminDetails?._id;

  const [isSwitch, setSwitch] = useState({});
  const [alert,setAlert] = useState(false)
  React.useEffect(() => {
    const technicianId = new URLSearchParams(props.location.search).get(
      "technicianId"
    );
    setBackDrop(true)
    setTimeout(() => {
      setBackDrop(false)
    }, 2000);
    if (technicianId != null) {
      getTechnicianDetails(technicianId);
    }
  }, []);

  const getTechnicianDetails = (technicianId) => {
    let payload = {
      technician_obj_id: technicianId,
    };
    setIsTechnicianDetailsExists(true);
    new AdminApiModule()
      .getAllTechnician(payload)
      .then((res) => {
        if (res?.data?.length) {
          setDoesTechnicianExists(true);
          getURL(res?.data?.[0]);
          console.log(res?.data[0]);
          setSwitch({
            ...isSwitch,
            aadhar_front:
              res?.data[0]?.document_details?.aadhar_card_document
                ?.aadhar_front_verified,
            aadhar_back:
              res?.data[0]?.document_details?.aadhar_card_document
                ?.aadhar_back_verified,
            pan: res?.data[0]?.document_details?.pan_verified,
            profile : res?.data[0]?.personal_details?.profile_picture_verified,
            certificate: res?.data[0]?.personal_details?.company_worked_with_verified,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getURL = (technicianDetails) => {
    setUrl(
      technicianDetails?.document_details?.aadhar_card_document?.back_side
    );
    setUrlF(
      technicianDetails?.document_details?.aadhar_card_document?.front_side
    );
    setUrlP(technicianDetails?.document_details?.pan_card_document);
    setUrlI(technicianDetails?.personal_details?.profile_picture);
    setUrlC(technicianDetails?.personal_details?.company_worked_with);
  };
 
  const [state, setState] = React.useState({
    raised: false,
    shadow: 1,
  });

  const handleSwitchToggle = React.useCallback((switchType) => {
    const newSwitchValue = !isSwitch[switchType];
  
    // Create a copy of the isSwitch object
    const updatedSwitches = { ...isSwitch };
    // Update the specific switch value based on switchType
    updatedSwitches[switchType] = newSwitchValue;
  
    if (switchType === "aadhar_front") {
      sendPayloadToAPI(newSwitchValue, switchType)
        .then(() => {
          console.log("API call successful");
        })
        .catch((error) => {
          console.error("API error:", error);
          // Revert the state back to its previous value if the API call fails
          setSwitch({ ...isSwitch, [switchType]: !newSwitchValue });
        })
        .finally(() => {
          // Call getTechnicianDetails after API call completes, regardless of success or failure
          getTechnicianDetails(technicianId);
        });
    }else if(switchType === "aadhar_back"){
      sendPayloadToAPI(newSwitchValue, switchType)
      .then(() => {
        console.log("API call successful");
      })
      .catch((error) => {
        console.error("API error:", error);
        // Revert the state back to its previous value if the API call fails
        setSwitch({ ...isSwitch, [switchType]: !newSwitchValue });
      })
      .finally(() => {
        // Call getTechnicianDetails after API call completes, regardless of success or failure
        getTechnicianDetails(technicianId);
      });
    }else if(switchType === "pan"){
      sendPayloadToAPI(newSwitchValue, switchType)
      .then(() => {
        console.log("API call successful");
      })
      .catch((error) => {
        console.error("API error:", error);
        // Revert the state back to its previous value if the API call fails
        setSwitch({ ...isSwitch, [switchType]: !newSwitchValue });
      })
      .finally(() => {
        // Call getTechnicianDetails after API call completes, regardless of success or failure
        getTechnicianDetails(technicianId);
      });
    }else if(switchType === "profile"){
      sendPayloadToAPI(newSwitchValue, switchType)
      .then(() => {
        console.log("API call successful");
      })
      .catch((error) => {
        console.error("API error:", error);
        // Revert the state back to its previous value if the API call fails
        setSwitch({ ...isSwitch, [switchType]: !newSwitchValue });
      })
      .finally(() => {
        // Call getTechnicianDetails after API call completes, regardless of success or failure
        getTechnicianDetails(technicianId);
      });
    }else if(switchType === "certificate"){
      sendPayloadToAPI(newSwitchValue, switchType)
      .then(() => {
        console.log("API call successful");
      })
      .catch((error) => {
        console.error("API error:", error);
        // Revert the state back to its previous value if the API call fails
        setSwitch({ ...isSwitch, [switchType]: !newSwitchValue });
      })
      .finally(() => {
        // Call getTechnicianDetails after API call completes, regardless of success or failure
        getTechnicianDetails(technicianId);
      });
    }
  
    console.log("newSwitchValue", switchType);
  
    // Call sendPayloadToAPI after updating the state
  
  }, [getTechnicianDetails]);

  const sendPayloadToAPI = (status, switchType) => {
    return new Promise((resolve, reject) => {
      const checkStatus = status.toString();
      const payload = {
        Admin_id: adminId,
        status: checkStatus,
        technician_id: technicianId,
        value: switchType,
      };

      axios
        .post(
          `${BASE_URL}admin/Set-Verification-Status-Of-center-And-Technician`,
          payload
        )
        .then((response) => {
          resolve(response.data); // Resolve the promise with the response data
          getTechnicianDetails(technicianId);
          setAlert(true)
          setTimeout(() => {
            setTimeout(() => {
              setAlert(true)
            }, 2000);
            setAlert(false)
          }, 4000);
        })
        .catch((error) => {
          console.error("API error:", error);
          reject(error); // Reject the promise with the error
        });
    });
  };

  console.log(isSwitch)

  return (
    <React.Fragment>
      {
        backDrop && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
         
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        )
      }
       
      {
        alert && (
        <Alert
          sx={{ width: 349, position: "fixed", top: 40, right: 14 }}
          onClose={() => {setAlert(false)}}
        >
          This is a success alert — check it out!
        </Alert>
        )
      }
      
      <Typography
        style={{
          marginTop: "20px",
          color: "black",
          textAlign: "center",
          fontSize: "30px",
        }}
      >
        Document Verification
      </Typography>
      <Box
        sx={{
          boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
          width: "70%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "20px auto",
          height: "200vh",
          backgroundColor: "#BAD7E9",
          borderRadius: "8px",
          border: "0.1px solid white",
        }}
      >
        <Button
      color="primary"
      fontWeight="bold"
      onClick={handleBackToTechnicians}
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
        padding: "10px",
        background: "#ADD8E6",
        display: "flex",
        alignItems: "center", // Align items vertically in the center
        gap: "8px", // Set gap between icon and text
      }}
    >
      <ArrowBackIcon /> Go Back
    </Button>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            margin: "0 auto",
          }}
        >
          <Card
            sx={{ maxWidth: 345 }}
            style={{ marginTop: "50px" }}
            className={classes.root}
            onMouseOver={() => setState({ raised: true, shadow: 3 })}
            onMouseOut={() => setState({ raised: false, shadow: 1 })}
            raised={state.raised}
            zDepth={state.shadow}
          >
            <CardMedia
              component="img"
              height="140"
              image={urlF}
              alt="IMAGE NOT AVAILABLE"
              style={{ imageRendering: "smooth" }}
            />
          </Card>
          <Typography
            style={{
              marginTop: "10px",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Aadhar Card Front Side
            {/* Switch code start */}
            <label class="switch">
              <input
                type="checkbox"
                checked={isSwitch.aadhar_front}
                onChange={() => handleSwitchToggle("aadhar_front")}
              />
              <div class="slider"></div>
              <div class="slider-card">
                <div class="slider-card-face slider-card-front"></div>
                <div class="slider-card-face slider-card-back"></div>
              </div>
            </label>
            {/* Switch code end */}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            margin: "0 auto",
          }}
        >
          <Card
            sx={{ maxWidth: 345 }}
            style={{ marginTop: "50px" }}
            className={classes.root}
            onMouseOver={() => setState({ raised: true, shadow: 3 })}
            onMouseOut={() => setState({ raised: false, shadow: 1 })}
            raised={state.raised}
            zDepth={state.shadow}
          >
            <CardMedia
              component="img"
              height="140"
              image={url}
              alt="IMAGE NOT AVAILABLE"
            />
          </Card>
          <Typography
            style={{
              marginTop: "10px",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Aadhar Card Back Side
            {/* Switch code start */}
            <label class="switch">
              <input
                type="checkbox"
                checked={isSwitch.aadhar_back}
                onChange={() => handleSwitchToggle("aadhar_back")}
              />
              <div class="slider"></div>
              <div class="slider-card">
                <div class="slider-card-face slider-card-front"></div>
                <div class="slider-card-face slider-card-back"></div>
              </div>
            </label>
            {/* Switch code end */}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            margin: "0 auto",
          }}
        >
          <Card
            sx={{ maxWidth: 345 }}
            style={{ marginTop: "50px" }}
            className={classes.root}
            onMouseOver={() => setState({ raised: true, shadow: 3 })}
            onMouseOut={() => setState({ raised: false, shadow: 1 })}
            raised={state.raised}
            zDepth={state.shadow}
          >
            <CardMedia
              component="img"
              height="140"
              image={urlP}
              alt="IMAGE NOT AVAILABLE"
            />
          </Card>
          <Typography
            style={{
              marginTop: "10px",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Pan Card
            {/* Switch code start */}
            <label class="switch">
              <input
                type="checkbox"
                checked={isSwitch.pan}
                onChange={() => handleSwitchToggle("pan")}
              />
              <div class="slider"></div>
              <div class="slider-card">
                <div class="slider-card-face slider-card-front"></div>
                <div class="slider-card-face slider-card-back"></div>
              </div>
            </label>
            {/* Switch code end */}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            margin: "0 auto",
          }}
        >
          <Card
            sx={{ maxWidth: 345 }}
            style={{ marginTop: "50px" }}
            className={classes.root}
            onMouseOver={() => setState({ raised: true, shadow: 3 })}
            onMouseOut={() => setState({ raised: false, shadow: 1 })}
            raised={state.raised}
            zDepth={state.shadow}
          >
            <CardMedia
              component="img"
              height="140"
              image={urlI}
              alt="IMAGE NOT AVAILABLE"
            />
          </Card>
          <Typography
            style={{
              marginTop: "10px",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Profile Picture
            {/* Switch code start */}
            <label class="switch">
              <input
                type="checkbox"
                checked={isSwitch.profile}
                onChange={() => handleSwitchToggle("profile")}
              />
              <div class="slider"></div>
              <div class="slider-card">
                <div class="slider-card-face slider-card-front"></div>
                <div class="slider-card-face slider-card-back"></div>
              </div>
            </label>
            {/* Switch code end */}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            margin: "0 auto",
          }}
        >
          <Card
            sx={{ maxWidth: 345 }}
            style={{ marginTop: "50px" }}
            className={classes.root}
            onMouseOver={() => setState({ raised: true, shadow: 3 })}
            onMouseOut={() => setState({ raised: false, shadow: 1 })}
            raised={state.raised}
            zDepth={state.shadow}
          >
            <CardMedia
              component="img"
              height="140"
              image={urlC}
              alt="IMAGE NOT AVAILABLE"
            />
          </Card>
          <Typography
            style={{
              marginTop: "10px",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Certificate
            {/* Switch code start */}
            <label class="switch">
              <input
                type="checkbox"
                checked={isSwitch.certificate}
                 onChange={() => handleSwitchToggle("certificate")}
              />
              <div class="slider"></div>
              <div class="slider-card">
                <div class="slider-card-face slider-card-front"></div>
                <div class="slider-card-face slider-card-back"></div>
              </div>
            </label>
            {/* Switch code end */}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  reduxState: state.userReducer,
});
export default connect(mapStateToProps, null)(withRouter(TechnicianDoc));
