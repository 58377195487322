import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Chip } from "@material-ui/core";
import { useSelector } from "react-redux";
import axios from "axios";
import Typography from "@mui/material/Typography";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { Button, Grid } from "@mui/material";
import BasicDateRangePicker from "../../../../components/common/datepicker";
import { BASE_URL } from "../../../../components/config/config";
const columns = [
  {
    name: "createdAt",
    label: "Transaction Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => moment(value).format("Do MMM, hh:ss A"),
    },
  },
  {
    name: "userId",
    label: "User ID",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "consumer_name",
    label: "Customer Name",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "totalRechargeAmount",
    label: "Total Recharge Amount",
    options: {
      filter: false,
      sort: false,
    },
  },

  {
    name: "totalTransactions",
    label: "Total Transactions",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name:"wallet_remain",
    label:"Balance",
    
  }
];
const consumerTransactions=[
  {
    name: "createdAt",
    label: "Transaction Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => moment(value).format("Do MMM, hh:ss A"),
    },
  },
  {
    name: "userId",
    label: "User ID",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "transactionId",
    label: "Transaction ID",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "amount",
    label: "Recharge Amount",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: false,
      sort: false,
    },
  },

]

// Custom filter style
const options = {
  selectableRows: "none",
  responsive: "standard",
  filterType: "checkbox", // or 'dropdown'
  filter: true,
  textLabels: {
    filter: "Filter",
    reset: "Reset",
    close: "Close",
  },
  customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
    <div style={{ padding: "20px" }}>
      <Button variant="contained" onClick={() => applyNewFilters()}>
        Apply
      </Button>
    </div>
  ),
  customFilterDialogFooterStyle: {
    borderTop: "1px solid #ccc",
  },
  customFilterDialogFooterPosition: "right", // or 'right'
  customFilterDialogWidth: "500px", // Set the desired width here
};

const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "2px",
  },
};

export default function ConsumerWalletTable() {
  const globalState = useSelector((state) => state.AdminReducer);
  const [loader,setLoader]= React.useState(false)
  const [selectedUserId, setSelectedUserId] = React.useState(null);
  const [userTransactions, setUserTransactions] = React.useState([]);
  const [accessToken, setAccessToken] = React.useState("");
  const [rows, setRows] = useState([]);

  

  const refreshData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}admin/wallet-transactions-consumer`, {
        headers: {
          Authorization: accessToken,
        },
      });
      setRows(response.data?.data);
    } catch (error) {
      console.log("Error fetching data:", error);
      return [];
    }
  };

  //  Date filter code start
  const onClickFilter = async (value) => {
    let payload = {
      start_date: value[0],
      end_date: value[1],
    };

    try {
      const response = await axios.get(
        `${BASE_URL}admin/wallet-transactions-consumer`,
        {
          headers: {
            Authorization: accessToken,
          },
          params: payload,
        }
      );
      
      setRows(response.data?.data);
    } catch (error) {
      console.log("Error fetching data:", error);
      return [];
    }
  };

  //  Date filter code End

  // Table
  React.useEffect(() => {
    setAccessToken(globalState?.userTokens?.accessToken);
    fetchData();
  }, [globalState]);

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await axios.get(`${BASE_URL}admin/wallet-transactions-consumer`, {
        headers: {
          Authorization: accessToken,
        },
      });
  
  
      // Sort the data by createdAt field in descending order
      const sortedData = response.data?.data.sort((a, b) => {
        return  new Date(b?.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }), -  new Date(a?.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
      });
  
  
      const groupedRows = response.data?.data.reduce((acc, transaction) => {
        const { userId } = transaction;
        if (!acc[userId]) {
          acc[userId] = [];
        }
        acc[userId].push(transaction);
        return acc;
      }, {});

      Object.values(groupedRows).forEach(transactions => {
        transactions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      });
  
      const finalRows = Object.values(groupedRows).map(transactions => {
        const totalRechargeAmount = transactions.reduce((total, transaction) => total + transaction.amount, 0);
        return {
          ...transactions[0], // Assuming other fields are the same for all transactions of a consumer
          totalRechargeAmount,
          totalTransactions: transactions.length,
        };
      });
  
      // Sort the final rows by the latest transaction date of each consumer
      finalRows.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  
      setRows(finalRows);
    } catch (error) {
      console.log("Error fetching data:", error);
      setRows([]);
    } finally {
      setLoader(false);
    }
  };

  const handleRowClick = (userId) => {
    setSelectedUserId(userId); // Assuming userId is in the second column
    fetchUserTransactions(userId);
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth"
    });
  };


  const fetchUserTransactions = async (userId) => {
    try {
      const response = await axios.get(`${BASE_URL}admin/wallet-transactions-consumer-byId`, {
        headers: {
          Authorization: accessToken,
        },
        params: {
          userId: userId,
        },
      }); 
  
      // Sort the consumer transactions by createdAt field in descending order
      const sortedTransactions = response.data?.data.sort((a, b) => {
        return  new Date(b?.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }), -  new Date(a?.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
      });
  
  
      setUserTransactions(sortedTransactions);
    } catch (error) {
      console.log("Error fetching user transactions:", error);
      setUserTransactions([]);
    }
  };
  
  const handleCloseDetailView = () => {
    setSelectedUserId(null);
    setUserTransactions([]);
  };

  

 
 
  console.log("rows", rows);

  return (
    <>
      <Box style={stylesCss.paddingInnerGrids}>
        <BasicDateRangePicker />
      </Box>
      <MUIDataTable
        data={rows}
        columns={columns}
        options={{ ...options, onRowClick: handleRowClick }}
      />
      {selectedUserId && (
     
        <div>
                  <div style={{display: "flex", justifyContent: "space-between", alignItems: "right" }}>

        <Typography variant="h6">{userTransactions.length > 0 ? userTransactions[0].consumer_name : ""}</Typography>
        <Button variant="contained" onClick={handleCloseDetailView}>Close</Button>
        {/* Display user transactions along with the user name */}
        </div>
        <MUIDataTable data={userTransactions} columns={consumerTransactions} options={options} />
      </div>
      )}
    </>
  );
}

