import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { Alert, InputAdornment, Snackbar } from '@mui/material'

import AdminApi from '../AdminApi/Api/api'
import MyDropzone from './SerivceImageUploader';
import ServiceCreate from './SerivceImageUploader';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../config/config';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const stylesCss = {
    inputBox: {
        width: '100%',
        marginBottom: "10px"
    },
    gridStyle: {
        width: '80%',
        margin: 'auto'
    },
    paddingInnerGrids: {
        paddingRight: "10px",
        paddingLeft: "10px",
        paddingTop: "10px"
    }
}

export default function AddServiceModal({ getAllServicesExists, isUpdate, serviceId, serviceNameProp }) {
    const [open, setOpen] = React.useState(false);
    const [serviceName, setServiceName] = React.useState('');

    const [snackBarValues, setSnackBarValues] = React.useState({
        open: false,
        msg: "",
        duration: 5000,
        type: ""
    })

    useEffect(() => {
        if (isUpdate) {
            setServiceName(serviceNameProp)
        }
    }, [])

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const showDialog = (open, type, msg) => {
        setSnackBarValues({
            open: open,
            msg: msg,
            duration: 5000,
            type: type
        })

    }

    const closeDialog = (open = false) => {
        setSnackBarValues({
            open: open,
            msg: "",
            duration: 3000,
            type: ""
        })


    }

    // const handleChange = (event) => {
    //     let value = event.target.value;
    //     let capitalText = value.toUpperCase();
    //     setServiceName(capitalText);
    // }

    // const onClickSave = () => {
    //     let payload = {
    //         service_name: serviceName
    //     }

    //     new AdminApi().addService(payload).then(res => {
    //         if (res) {
    //             handleClose();
    //             showDialog(true, "success", 'Service saved successfully')
    //         }
    //         getAllServicesExists()
    //     }).catch(err => {
    //         console.log(err)
    //         showDialog(true, "error", err?.response?.data?.message)
    //     })
    // }

    // const onClickUpdate = () => {
    //     let payload = {
    //         service_object_id: serviceId,
    //         service_name: serviceName
    //     }

    //     new AdminApi().updateService(payload).then(res => {
    //         if (res) {
    //             handleClose();
    //             showDialog(true, "success", 'Service updated successfully')
    //         }
    //         getAllServicesExists()
    //     }).catch(err => {
    //         console.log(err)
    //         showDialog(true, "error", err?.response?.data?.message)
    //     })
    // }

    // Add service with image

    const [selectedImage, setSelectedImage] = useState(null);
    // const [serviceName, setServiceName] = React.useState('');
    const globalState = useSelector((state) => state.centerOnboardReducer);
    const access_token = globalState?.userTokens?.accessToken
  
  
    const handleChange = (event) => {
      let value = event.target.value;
      let capitalText = value.toUpperCase();
      setServiceName(capitalText);
  }
  
    const handleImageChange = (e) => {
      setSelectedImage(e.target.files[0]);
    };

    const onClickSave = async (e) => {
        e.preventDefault();
    
        if (!selectedImage) {
          console.log('No image selected.');
         
          return;
        }
    
        const data = {
          service_name: serviceName,
          // "pin_code": "110044"
        };
        
        const formData = new FormData();
        formData.append('data', JSON.stringify(data)); // Convert data to JSON string
        formData.append('file', selectedImage);
    
        console.log(formData, '<------form data😊😊😊😊😊😊😊😊😊😊😊😊')
    
        try {
          const response = await axios.post( `${BASE_URL}admin/create-services`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'x-access-token': access_token,
            },
          });
    
          if (response.status === 200) {
            // Image uploaded successfully
            handleClose();
            console.log('Image uploaded!');
            showDialog(true, "success", 'Service Created successfully')
            // Do something with the response if needed
          } else {
            // Handle upload error
            console.error('Image upload failed.');
          }
        } catch (error) {
          console.error('Error occurred while uploading the image:', error);
        }
      };

    //   Upload service with image
    const onClickUpdate = async (e) => {
        e.preventDefault();
    
        if (!selectedImage) {
          console.log('No image selected.');
         
          return;
        }
        const formData = new FormData();
        formData.append('service_object_id',  serviceId);
        formData.append('service_name',  serviceName);
        // formData.append('pin_code',  serviceName);
        formData.append('file', selectedImage);
    
    
        try {
          const response = await axios.post( `${BASE_URL}admin/update-service`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'x-access-token': access_token,
            },
          });
    
          if (response.status === 200) {
            // Image uploaded successfully
            handleClose();
            console.log('Image uploaded!');
            showDialog(true, "success", 'Service Updated successfully')
          } else {
            // Handle upload error
            console.error('Image upload failed.');
          }
        } catch (error) {
          console.error('Error occurred while uploading the image:', error);
        }
      };

    return (
        <div>
            {
                snackBarValues.open && <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackBarValues.open} autoHideDuration={snackBarValues.duration} onClose={closeDialog}>
                    <Alert onClose={closeDialog} severity={snackBarValues.type} sx={{ width: '100%' }}>
                        {snackBarValues.msg}
                    </Alert>
                </Snackbar>
            }

            <div className='add-service-button'>
                <Button onClick={handleOpen} className="text-transform-none" variant="contained">{isUpdate ? "Edit Service" : "Add a Service"}</Button>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                    <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {isUpdate ? "Edit Service" : "Add a Service"}
                    </Typography>
                    <Grid style={stylesCss.paddingInnerGrids} item>
                       
                        {/* <ServiceCreate /> */}
                        <form onSubmit={isUpdate ? onClickUpdate :  onClickSave}>
       <TextField
                            helperText="Please provide service name"
                            id="demo-helper-text-misaligned"
                            label="Service Name"
                            style={stylesCss.inputBox}
                            name="serviceName"
                            value={serviceName}
                            onChange={(event) => handleChange(event)}
                        /> 
      <div class="file-input">
      <input
        type="file"
        name="file-input"
        id="file-input"
        class="file-input__input"
        onChange={handleImageChange}
      />
      <label class="file-input__label" for="file-input">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="upload"
          class="svg-inline--fa fa-upload fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
          ></path>
        </svg>
        
        <span>Upload file</span></label>
        <br />
        {/* <Button variant="contained">Contained</Button> */}
      <Button  type='submit' disabled={!serviceName} variant="contained" sx={{marginTop:'10px'}} >{isUpdate ? "Update" : "Save"}</Button >
    </div>
    </form>
                    </Grid>
                    {/* <Grid style={stylesCss.paddingInnerGrids} item>
                        <Button
                            variant="contained"
                            onClick={isUpdate ? () => onClickUpdate() : () => onClickSave()}
                            disabled={!serviceName}
                        >
                            {isUpdate ? "Update" : "Save"}
                        </Button>
                    </Grid> */}
                </Box>
            </Modal>
        </div>
    );
}