import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Alert, InputAdornment, Snackbar } from "@mui/material";

import AdminApi from "../../components/AdminApi/Api/api";
import { useHistory } from "react-router-dom";

import {
  setLoginDetails,
  setLogoutDetails,
} from "../../store/AdminStore/action";
import { setLoginDetails as setLoginUserDetails } from "../../store/UserStore/action";

import { useSelector, useDispatch } from "react-redux";

const styles = {
  container: {
    backgroundImage: `url("https://www.onit.services/static/media/MicrosoftTeams-image.63080be2.png")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  layout: {
    width: "40%",
    marginTop: "10%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom:"10%",
   
  },
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },

  logo: {
    width: "100px", 
    height: "100px", 
    marginBottom: "0px",
    padding: "20px", 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default function AdminLogin() {
  const [adminValues, setAdminValues] = useState({
    user_name: "",
    password: "",
  });

  const [snackBarValues, setSnackBarValues] = useState({
    open: false,
    msg: "",
    duration: 5000,
    type: "",
  });

  const globalState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const showDialog = (open, type, msg) => {
    setSnackBarValues({
      open: open,
      msg: msg,
      duration: 5000,
      type: type,
    });
  };

  const closeDialog = (open = false) => {
    setSnackBarValues({
      open: open,
      msg: "",
      duration: 5000,
      type: "",
    });
  };

  const handleChangeUserNamePassword = (name, value) => {
    setAdminValues({
      ...adminValues,
      [name]: value,
    });
  };

  const [isLogginIn, setIsLoggingIn] = useState(false);

 const forgot=()=>{
 const path="/forgot-password";
 history.push(path);
 
}
  const onClickLogin = () => {
    if (!adminValues.user_name) {
      showDialog(true, "error", "Please enter user name");
      return;
    }

    if (!adminValues.password) {
      showDialog(true, "error", "Please enter password");
      return;
    }

    let payload = {
      user_name: adminValues.user_name,
      password: adminValues.password,
    };

    setIsLoggingIn(true);
    new AdminApi()
      .login(payload)
      .then((res) => {
        setIsLoggingIn(false);
        showDialog(true, "success", "Admin login successful");

        console.log("Res", res);

        dispatch(
          setLoginDetails({
            adminDetails: res?.data?.adminDetails,
            clientDetails:res?.data?.clientData,
            isAdminLoggedIn: true,
            userTokens: {
              accessToken: res?.data?.token,
            },
          })
        );
        if(res?.data?.adminDetails?.admin_role=="CLIENT")
          {
            history.push("/client-dashboard");
          }
        else
        {
        history.push("/Home");
        }

        // dispatch(
        //     setLoginUserDetails({
        //         userDetails: {},
        //         isUserLoggedIn: false,
        //         userTokens: {
        //             accessToken: "",
        //         },
        //     }),
        // )
      })
      .catch((err) => {
        console.log(err);
        setIsLoggingIn(false);

        showDialog(true, "error", err?.response?.data?.message);
      });
  };

  return (
    <div style={styles.container}>
    <Card style={styles.layout} sx={{ minWidth: 275 }}>
    <CardMedia
        component="img"
        alt="Company Logo"
        height="240"
        image="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQEh3y_w_Be8T4Bautnrtja3Ddet3bZYNFwnPQUxu2DwaUxIlxs" // Replace with the path to your logo image
        style={styles.logo}
      />
      {snackBarValues.open && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackBarValues.open}
          autoHideDuration={snackBarValues.duration}
          onClose={closeDialog}
        >
          <Alert
            onClose={closeDialog}
            severity={snackBarValues.type}
            sx={{ width: "100%" }}
          >
            {snackBarValues.msg}
          </Alert>
        </Snackbar>
      )}

      <CardContent>
        <Typography sx={{ fontSize: 24 }} color="text.secondary" gutterBottom>
          Login to your Account
        </Typography>
        <TextField
          // helperText="Enter mobile"
          id="demo-helper-text-misaligned"
          label="User Name"
          style={styles.inputBox}
          type="text"
          disabled={isLogginIn}
          onChange={(e) =>
            handleChangeUserNamePassword("user_name", e.target.value)
          }
        />
        <TextField
          // helperText="Enter mobile"
          id="demo-helper-text-misaligned"
          label="Password"
          style={styles.inputBox}
          type="password"
          disabled={isLogginIn}
          onChange={(e) =>
            handleChangeUserNamePassword("password", e.target.value)
          }
        />
        <Button onClick={()=>forgot()} style={{color:"red"}}>*Forgot Password</Button>
      </CardContent>
      <CardActions>
        {
          <Button
            onClick={() => onClickLogin()}
            disabled={isLogginIn}
            variant="contained"
          >
            {isLogginIn ? "Logging In , please wait" : "Login"}
          </Button>
        }
      </CardActions>
    </Card>
    </div>
  );
}
