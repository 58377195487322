import React, { useState } from 'react';
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import { Alert, InputAdornment, Snackbar } from "@mui/material";
 import { BASE_URL } from "../../components/config/config";
function ForgotPassword() {
    const styles = {
        container: {
          backgroundImage: `url("https://www.onit.services/static/media/MicrosoftTeams-image.63080be2.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        layout: {
          width: "40%",
          marginTop: "10%",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom:"10%",
        },
        inputBox: {
          width: "100%",
          marginBottom: "10px",
        },
        logo: {
          width: "100px", 
          height: "100px", 
          marginBottom: "0px",
          padding: "20px", 
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
    };

    const history = useHistory();
    const [mobileNumber, setMobileNumber] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const loginPage=()=>{
        const path="/admin-login";
 history.push(path);
 
    }

    const changePassword = () => {
        // Validate if the passwords match
        if (!mobileNumber || !newPassword || !retypePassword) {
            setErrorMessage("All fields are required");
            return;
        } else {
            setErrorMessage("");
        }

        const mobileNumberRegex = /^[0-9]{10}$/;
        if (!mobileNumberRegex.test(mobileNumber)) {
            setErrorMessage("Mobile number must be 10 digits");
            return;
        } else {
            setErrorMessage("");
        }

        if (newPassword !== retypePassword) {
            setErrorMessage("Passwords do not match");
            return;
        }
        const password=newPassword;
        const phone_number=mobileNumber;
        // Make an API call to your backend to change the password
        fetch(`${BASE_URL}admin/forgot-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                phone_number,
                password,
            }),
        })
        .then(response => {
            if (response.ok) {
                console.log("Password changed successfully");
                // Handle success
            } else {
                console.error("Failed to change password");
                // Handle error
            }
        })
        .catch(error => {
            console.error("Error:", error);
        });
    };

    return (
        <div style={styles.container}>
        <Card style={styles.layout}>
            <CardMedia
                component="img"
                alt="Company Logo"
                height="240"
                image="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQEh3y_w_Be8T4Bautnrtja3Ddet3bZYNFwnPQUxu2DwaUxIlxs"
                style={styles.logo}
            />
            <CardContent>
                <Typography sx={{ fontSize: 24 }} color="text.secondary" gutterBottom>
                    Password Reset
                </Typography>
                <TextField
                    id="mobile-number"
                    label="Mobile Number"
                    style={styles.inputBox}
                    type="text"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                />
                <TextField
                    id="new-password"
                    label="New Password"
                    style={styles.inputBox}
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField
                    id="retype-password"
                    label="ReType Password"
                    style={styles.inputBox}
                    type="password"
                    value={retypePassword}
                    onChange={(e) => setRetypePassword(e.target.value)}
                />
              {errorMessage && (
                    <Typography variant="body2" color="error">
                        {errorMessage}
                    </Typography>
                )}
            </CardContent>
            <CardActions style={{ justifyContent: "space-between" }}>
              
                <Button onClick={changePassword} style={{ backgroundColor: "#1976D2", color: "white", width: "100px", marginLeft: "20px",marginBottom:"10px" }}>
                    Submit
                </Button>
                <Button onClick={loginPage} style={{ backgroundColor: "#1976D2", color: "white", width: "100px", marginRight:"20px", marginBottom:"10px" }}>
                     Login Page
                     </Button>
                 
            </CardActions>
        </Card>
        </div>
    );
}

export default ForgotPassword;
