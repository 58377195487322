import React, { useState, useEffect } from 'react';
import './Notifications.css'; // Import CSS for styling
import axios from 'axios'; // Import axios for HTTP requests
import { BASE_URL } from '../config/config';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  Snackbar,
  Alert
} from '@mui/material';
import SelectSearch from 'react-select';
import { IndianStates } from '../../assets/IndianStates';
import { useSelector } from "react-redux";
import CenterRegistrationApi from "../../components/centerRegistration/Api/api";
import { useHistory } from 'react-router-dom'; // Import useHistory


function Notifications() {
  const initialPayload = {
    "title": "",
    "discription": "",
    "userType": [],
    "is_Active": false 
  };
  const globalState = useSelector((state) => state.AdminReducer)
  const [payload, setPayload] = useState(initialPayload);
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);
  const [userType, setUserType] = useState('');
  const [state, setState] = useState('');
  const [category, setCategory] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [servicesList, setServicesList] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const history = useHistory();

  const getAllNotifications = async () => {
    try {
      const response = await axios.get(`${BASE_URL}admin/get-all-notification`);
      setNotifications(response.data.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    getAllNotifications();
    getAllServicesExists();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPayload(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const getAllServicesExists = () => {
    new CenterRegistrationApi()
      .getAllActiveServices()
      .then((res) => {
        setServicesList(res.data); // Update servicesList state here
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}admin/create-notification`, payload);
      console.log('Notification created:', response.data);
      getAllNotifications();
      setFormOpen(false); // Close the form after submission
    } catch (error) {
      console.error('Error creating notification:', error);
    }
  };

  const handleOpenModal = (notificationId) => {
    setSelectedNotificationId(notificationId);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setUserType('');
    setState('');
    setCategory('');
    setMobileNumber('');
    setPayload(initialPayload);
  };

  const handleSendNotification = async () => {
    const payloadToSend = userType === 'Individual'
      ? {
          userType:category,
          mobile_number: mobileNumber,
          notification_id: selectedNotificationId,
          sentBy: globalState?.adminDetails?.user_name
        }
      : {
          userType,
          skill: payload.selectedServiceId,
          state: state.value,
          notification_id: selectedNotificationId,
          sentBy: globalState?.adminDetails?.user_name
        };
  
    try {
      const response = await axios.post(`${BASE_URL}admin/send-notification-touser`, payloadToSend);
      console.log('Notification sent:', response.data);
      setSnackbarMessage('Notification sent successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error sending notification:', error);
      setSnackbarMessage('Error sending notification');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  
    handleCloseModal();
  };

  const toggleForm = () => {
    setFormOpen(!formOpen);
  };

  const NotificationLog=()=>{

    history.push("/notification-history");
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <div style={{display:"flex",justifyContent:"space-between"}}>
      <Button variant="contained" onClick={toggleForm} style={{ alignItems: "right" }}>
        {formOpen ? "Cancel" : "Add Notification"}
      </Button>
      <Button variant="contained" onClick={NotificationLog}>Notification Log</Button>
      </div>
      {formOpen && (
        <form onSubmit={handleSubmit}>
          <div className="notifications-container">
            <h1>Notifications</h1>
            <div className="input-group">
              <label htmlFor="title">Title:</label>
              <input
                type="text"
                id="title"
                name="title"
                value={payload.title}
                onChange={handleChange}
                required
                className="big-input"
              />
            </div>
            <div className="input-group">
              <label htmlFor="discription">Description:</label>
              <textarea
                id="discription"
                name="discription"
                value={payload.discription}
                onChange={handleChange}
                required
                className="big-input"
              />
            </div>
            <div className="input-group">
              <label>User Type:</label>
              <select
                id="userType"
                name="userType"
                value={payload.userType}
                onChange={handleChange}
                className="big-input"
              >
                <option value="Consumer">Consumer</option>
                <option value="Technician">Technician</option>
                <option value="Individual">Individual</option>
              </select>
            </div>
            <div className="input-group">
              <label htmlFor="isActive">
                <input
                  type="checkbox"
                  id="isActive"
                  name="is_Active"
                  checked={payload.is_Active}
                  onChange={handleChange}
                />
                Active
              </label>
            </div>
            <Button type="submit" variant="contained">Create Notification</Button>
          </div>
        </form>
      )}
      <div className="notifications-list" style={{ marginTop: "20px", marginBottom: "20px" }}>
        <h2>All Notifications</h2>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>User Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications.map(notification => (
                <TableRow key={notification._id}>
                  <TableCell>{notification.title}</TableCell>
                  <TableCell>{notification.discription}</TableCell>
                  <TableCell>{notification.userType.join(', ')}</TableCell>
                  <TableCell>{notification.is_active ? 'Active' : 'Inactive'}</TableCell>
                  <TableCell>
                    <Button variant="contained" onClick={() => handleOpenModal(notification?._id)}>Send</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog open={open} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>Send Notification</DialogTitle>
        <DialogContent>
          <div style={{ fontWeight: "bold" }}>Select User</div>
          <FormControl fullWidth margin="dense">
            <InputLabel>User Type</InputLabel>
            <Select
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
              label="User Type"
            >
              <MenuItem value="Consumer">Consumer</MenuItem>
              <MenuItem value="Technician">Technician</MenuItem>
              <MenuItem value="Individual">Individual</MenuItem>
            </Select>
          </FormControl>
          {userType === 'Individual' ? (
            <>
              <div style={{ fontWeight: "bold" }}>Category</div>
              <FormControl fullWidth margin="dense">
                <InputLabel>Category</InputLabel>
                <Select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  label="Category"
                >
                  <MenuItem value="Technician">Technician</MenuItem>
                  <MenuItem value="Consumer">Consumer</MenuItem>
                </Select>
              </FormControl>
              <div style={{ fontWeight: "bold" }}>Mobile Number</div>
              <TextField
                fullWidth
                margin="dense"
                label="Mobile Number"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
            </>
          ) : (
            <>
              <div style={{ fontWeight: "bold" }}>Select Skill</div>
              <FormControl fullWidth margin="dense">
                <InputLabel>Select Service</InputLabel>
                <Select
                  value={payload.selectedServiceId}
                  onChange={(e) => setPayload({ ...payload, selectedServiceId: e.target.value })}
                  label="Select Service"
                >
                  {servicesList.map((service) => (
                    <MenuItem key={service._id} value={service._id}>
                      {service.service_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={{ fontWeight: "bold" }}>Select State</div>
              <FormControl fullWidth margin="dense" style={{ marginBottom: "20px" }}>
                <SelectSearch
                  options={IndianStates}
                  value={state}
                  onChange={(selectedOption) => setState(selectedOption)}
                  placeholder=""
                  search
                  formatOptionLabel={(option) => (
                    <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                      {option.value}
                    </Box>
                  )}
                />
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSendNotification} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Notifications;

