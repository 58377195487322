import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Button, TextField } from "@mui/material";
import AdminApiModule from "../AdminApi/Api/api";
import { useHistory, Link } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
// Specific date and time
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const stylesCss = {
  inputBox: {
    width: "100%",
    marginTop: "10px",
  },
  gridStyle: {
    width: "50%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "5px",
    paddingLeft: "5px",
    paddingTop: "5px",
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AVAILABLE_TIME_PREFERENCES = [
  "IMMEDIATELY",
  "WITHIN_24_HOURS",
  "SPECIFIC_DATE_AND_TIME",
];

export default function RebookModal({
  isOpen,
  handleRebookOpen,
  handleRebookClose,
  Ticket_id,
}) {
  const [isRebook, setRebook] = useState(false);
  // const [timePreference, setTimePreference] = React.useState("");
  // const [specificRequirement, setSpecificRequirement] = React.useState("");
  console.log("Ticket_id", Ticket_id);
  const [newTicketId, setNewTicketID] = React.useState("");

  const [formValues, setFormValues] = React.useState({
    timePreference: "",
    specificRequirement: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevPro) => ({
      ...prevPro,
      [name]: value,
    }));
  };

  console.log("timePreference", formValues?.timePreference);
  console.log("specificRequirement", formValues?.specificRequirement);

  // Specific date and Time
  const [specific_date_time, setSpecificDateTime] = useState(new Date());
  const onChangeSpecificDateAndTime = (newValue) => {
    // this.setState({ specific_date_time: newValue });
    console.log("newValue", newValue);
    setSpecificDateTime(newValue);
  };

  const handleRebook = async () => {
    let payload = {
      ticketId: Ticket_id,
      time_preference: {
        time_preference_type: formValues?.timePreference,

        // Sending payload according to some condition
        ...(formValues?.timePreference === "SPECIFIC_DATE_AND_TIME" && {
          specific_date_time: specific_date_time,
        }),
      },
      ...(formValues?.specificRequirement && {
        specific_requirement: formValues.specificRequirement,
      }),
    };
    console.log("rebook payload", payload);

    await new AdminApiModule()
      .rebookYourTicket(payload,Ticket_id)
      .then((res) => {
        console.log("newTicketId", res?.data?._id);

        setNewTicketID(res?.data?._id);
        if (res?.status == 200) setRebook(true);

        // handleRebookClose()
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("isOpen", isOpen);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handleRebookClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            {isRebook == false ? (
              <Box>
                <Typography
                  style={{
                    color: "black",
                    background: "none",
                    textAlign: "center",
                  }}
                  id="transition-modal-title"
                  variant="h4"
                  component="h2"
                >
                  Rebook Your Ticket
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  {/* Time preference input */}
                  <FormControl
                    style={stylesCss.inputBox}
                    sx={{ minWidth: 70 }}
                    size="small"
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      Time Preference
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Time Preference"
                      name="timePreference"
                      onChange={handleInputChange}
                    >
                      {AVAILABLE_TIME_PREFERENCES?.map((ite) => {
                        return <MenuItem value={ite}>{ite}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>

                  {/* Specific date and Time */}
                  {formValues?.timePreference == "SPECIFIC_DATE_AND_TIME" && (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          renderInput={(params) => <TextField {...params} />}
                          label="Select date and time"
                          // value={this.state.specific_date_time}
                          value={specific_date_time}
                          onChange={(newValue) => {
                            onChangeSpecificDateAndTime(newValue);
                          }}
                          minDate={new Date()}
                          minTime={new Date(0, 0, 0, 8)}
                          maxTime={new Date(0, 0, 0, 20)}
                        />
                      </LocalizationProvider>
                    </>
                  )}

                  {/* Specific Requirement */}
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Specific Requirement - it helps"
                    style={stylesCss.inputBox}
                    name="specificRequirement"
                    value={formValues.specificRequirement}
                    onChange={handleInputChange}
                  />
                </Typography>
                <Box mt={3} display="flex" justifyContent="space-between">
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleRebookClose}
                  >
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleRebook}
                  >
                    Rebook Now
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="subtitle1"
                  component="h2"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "black",
                    fontWeight: 800,
                  }}
                >
                  Hurray! Your Request has been created
                  <VerifiedIcon
                    color="success"
                    fontSize="large"
                    style={{ marginLeft: "10px" }}
                  />
                </Typography>

                <Link
                  style={{ marginLeft: "17px", color: "red" }}
                  to={`/serviceStatus/?${newTicketId}`}
                >
                  Tracking Link
                </Link>
              </Box>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
