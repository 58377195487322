import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const stylesCss = {
  inputBox: {
    width: '100%',
    marginBottom: '10px',
  },
};

export default function AssignTechnician({
  open,
  handleClose,
  technicianList,
  isForChange,
  handleAssignTechnician,
  handleChangeTechnician,
  isTechnicianAssigning,
  ticketObjId,
  centerlist,
}) {
  const [technicianSelected, setTechnicianSelected] = useState(null);

  const handleChange = (_, newValue) => {
    setTechnicianSelected(newValue);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {isForChange ? 'Change Technician' : 'Assign Technician'}
        </Typography>
        <br />
        <br />
        <Box>
          <Autocomplete
            value={technicianSelected}
            onChange={handleChange}
            //options={technicianList}
            options={centerlist}
            // getOptionLabel={(option) => option?.personal_details?.name || ''}
            getOptionLabel={(option) => option?.qr_details?.qr_id}
            isOptionEqualToValue={(option, value) => option?._id === value?._id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose Technician"
                style={{ ...stylesCss.inputBox, display: 'block', width: '100%' }}
              />
            )}
          />
        </Box>
        <br />
        <br />
        <Button
          onClick={() => {
            if (isForChange) {
              handleChangeTechnician(technicianSelected._id);
            } else {
              handleAssignTechnician(technicianSelected._id);
            }
          }}
          variant="contained"
          disabled={isTechnicianAssigning}
        >
          {isTechnicianAssigning ? 'Processing ' : isForChange ? 'Change Technician' : 'Assign Technician'}
        </Button>
      </Box>
    </Modal>
  );
}


